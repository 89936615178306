import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import GamesManager from "Games/GamesManager";
import { addNewRoundGameSetting } from "actions/newRoundActions";
import { getField, FRONT_NINE_BET, BACK_NINE_BET, OVERALL_BET } from "Games/FieldTypes";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Alert } from "@material-ui/lab";

const AccordionDetails = withStyles({
    root: {
        display: 'block'
    }
})(MuiAccordionDetails);


function GameSettings({ gameId, gamesSettings, addNewRoundGameSetting }) {
    console.log("GameSettings is rendering");

    // state

    // side-effects

    // functions
    function handleGameSettingFieldChange(fieldKey, newValue) {
        if (fieldKey === FRONT_NINE_BET) {
            // if front bet is changed it will change the back and overall bet automatically
            addNewRoundGameSetting(gameId, BACK_NINE_BET, newValue);
            addNewRoundGameSetting(gameId, OVERALL_BET, newValue);
        }
        addNewRoundGameSetting(gameId, fieldKey, newValue);
    }

    // return template
    const gameObj = GamesManager.getGame(gameId);
    const infos = gameObj ? gameObj.info() : [];
    const gameSettingsFields = gameObj ? gameObj.getGameSettingsFields(gamesSettings[gameId]) : [];

    return <>
        <AccordionDetails>
             {/* <Typography variant="caption">
                            {Game.description && Game.description.substr(0, 65) + "..."}
                        </Typography> */}
            {infos.length > 0 &&
            <Alert icon={false} severity="info">
                {infos.shift()}
                {infos.map((info, key) => <React.Fragment key={key}><br/>{info}</React.Fragment>)}
            </Alert>
            }
            {gameSettingsFields.map((fieldKey) => {
                const field = getField(fieldKey);
                if (field) {
                    const Component = field.componentRender;
                    let value = null;
                    if (gamesSettings[gameId]) {
                        if (gamesSettings[gameId][fieldKey] != null) {
                            value = gamesSettings[gameId][fieldKey];
                        }
                    }
                    return (
                        <React.Fragment key={fieldKey}>
                            <Component
                                name={field.name}
                                value={value}
                                onValueChange={(newValue) => handleGameSettingFieldChange(fieldKey, newValue)}
                            />
                        </React.Fragment>
                    );
                }
                return null;
            })}
        </AccordionDetails>
    </>;
} 

// redux here
const mapStateToProps = state => {
    return {
        gamesSettings: state.newRound.gamesSettings,
    };
};

const mapDispatchToProps = {
    addNewRoundGameSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(GameSettings);