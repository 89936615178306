import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Typography, Checkbox, Box } from "@material-ui/core";
import GamesManager, { STROKE_PLAY } from "../../Games/GamesManager";
import { updateNewRoundSelectedGameIds, nextSetupView } from "actions/newRoundActions";
import { removeElementFromArray } from "../../utils/ArrayHelpers";
import RoundSetupHeader from "./RoundSetupHeader";
import Navigation from "./Navigation";
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import GameSettings from "./GameSettings";

// const AccordionSummary = withStyles({
//     content: {
//         alignItems: 'center'
//     }
// })(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
    golfCoursePaper: {
        padding: theme.spacing(2),
    },
    selectGamesContainer: {
        margin: theme.spacing(3, 2),
    },
    gamesList: {
        overflow: "scroll",
        maxHeight: "90%",
    },
    card: {
        marginTop: theme.spacing(1),
    },
    nextButton: {
        marginTop: theme.spacing(1),
        float: "right",
    },
}));

function SelectGames(props) {
    const styleClasses = useStyles();

    const [selectedGameIds, setSelectedGameIds] = useState([]);

    const { saveCurrentGameIds, currentUser, nextSetupView, currentGameIds, golfCourse } = props;

    const handleCheckbox = (gameId) => {
        if (selectedGameIds.indexOf(gameId) === -1) {
            setSelectedGameIds([...selectedGameIds, gameId]);
        } else {
            setSelectedGameIds(removeElementFromArray(selectedGameIds, gameId));
        }
    };

    const handleNextViewClick = () => {
        saveCurrentGameIds(currentUser, selectedGameIds);
        nextSetupView();
    };

    useEffect(() => {
        if (currentGameIds) {
            setSelectedGameIds(currentGameIds);
        }
    }, [currentGameIds]);

    const gamesSelect = () => {
        let nonCompatibleGames = [];
        // loop through currenlty selected games and get all the non compatible games list
        selectedGameIds.forEach(gameId => {
            const game = GamesManager.getGame(gameId);
            if (!game) return;
            nonCompatibleGames.push(...game.nonCompatibleGames());
        })
        return Object.entries(GamesManager.allGames).map(([key, Game]) => {
            const isDisabled = key === STROKE_PLAY || nonCompatibleGames.indexOf(key) !== -1;
            return (
                <Accordion expanded={selectedGameIds.indexOf(key) !== -1 ? true : false} key={key}>
                    <MuiAccordionSummary>
                        <Box display="flex" flexGrow={1} alignItems="center" onClick={!isDisabled ? () => handleCheckbox(key) : () => {}}>
                            <Checkbox disabled={isDisabled} checked={selectedGameIds.indexOf(key) !== -1 || key === STROKE_PLAY  ? true : false} disableRipple />
                            <Typography variant="body1">{Game.gameName && Game.gameName}</Typography>
                        </Box>
                    </MuiAccordionSummary>
                    <GameSettings gameId={key} />
                </Accordion>
            );
        });
    };
    if (golfCourse)
        return (
            <React.Fragment>
                <RoundSetupHeader disablePlayers />

                <div className={styleClasses.selectGamesContainer}>
                    <Typography variant="subtitle1">Select Games:</Typography>
                    <div className={styleClasses.gamesList}>{gamesSelect()}</div>
                </div>
                <Navigation previousDisabled
                // nextDisabled={selectedGameIds.length < 1} 
                onNextClick={handleNextViewClick} />
            </React.Fragment>
        );
    else return null;
}

// redux here
const mapStateToProps = (state) => {
    return {
        currentGameIds: state.newRound.selectedGameIds.payload,
        currentUser: state.auth.user,
        golfCourse: state.newRound.selectedGolfCourse.info.payload,
    };
};

const mapDispatchToProps = {
    nextSetupView,
    saveCurrentGameIds: (user, gameIds) => updateNewRoundSelectedGameIds(user, gameIds),
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectGames);