import React from "react";
import { connect } from "react-redux";
import GamesManager, { STROKE_PLAY } from "../../Games/GamesManager";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

// const useStyles = makeStyles(theme => ({

// }));

const RoundMatchView = ({ gameIds }) => {
    console.log("RoundMatchView rendering");

    // lets not mutate redux prop
    gameIds = [ ...gameIds ];
    if (!gameIds.includes(STROKE_PLAY)) {
        // always include strokeplay
        gameIds.unshift(STROKE_PLAY);
    }
    return (
        <Grid container spacing={2}>
            {gameIds.map((gameId) => {
                const ResultComponent = GamesManager.getGameResultComponent(gameId);
                if (ResultComponent == null) return null;
                return (
                    <Grid item key={gameId} xs={12} sm={6} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    {GamesManager.getGame(gameId).gameName
                                        ? GamesManager.getGame(gameId).gameName
                                        : "Missing Game Name"}
                                </Typography>
                                <ResultComponent />
                            </CardContent>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    gameIds: state.activeRound.payload.gameIds,
});

export default connect(mapStateToProps)(RoundMatchView);
