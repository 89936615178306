import {
    DELETE_ROUND_SUCCESS,
    ROUNDS_CHANGE_BEGIN,
    FETCH_ROUNDS_SUCCESS,
    FETCH_ROUNDS_BEGIN
} from "actions/actionTypes";

const initState = {
    payload: {},
    isLoading: false
};

const roundsReducer = (state = initState, action) => {
    switch (action.type) {
        case DELETE_ROUND_SUCCESS:
            return {
                ...state,
                payload: { ...removeAndGetRounds(state.payload, action.payload) },
                isLoading: false
            };
        case ROUNDS_CHANGE_BEGIN:
            return { ...state, isLoading: true };

        case FETCH_ROUNDS_SUCCESS:
            return { ...state, payload: { ...action.payload }, isLoading: false };

        case FETCH_ROUNDS_BEGIN:
            return { ...state, payload: initState.payload, isLoading: true };
        default:
        // do nothing
    }
    return state;
};

const removeAndGetRounds = (allRounds, roundIdToDelete) => {
    delete allRounds[roundIdToDelete];
    return allRounds;
};

export default roundsReducer;
