import {
    FETCH_ACTIVE_ROUND_BEGIN,
    FETCH_ACTIVE_ROUND_SUCCESS,
    FETCH_ACTIVE_ROUND_FAILURE,
    UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD,
    SET_ACTIVE_ROUND_CURRENT_HOLE,
    SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
    RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
    UPDATE_SCORES_FOR_HOLE,
    UPDATE_ACTIVE_ROUND_PLAYERS,
    UPDATE_ACTIVE_ROUND_HOLE_SETTINGS
} from "actions/actionTypes";
import { roundsCollectionRef } from 'firebase/collectionRefs';
import AppNotifier from "AppNotifier";

// TODO remove below
// export const updateCurrentGameInstances = gameInstances => dispatch => {
//     if (Array.isArray(gameInstances)) {
//         dispatch({
//             type: SET_ACTIVE_ROUND_GAME_INSTANCES,
//             payload: gameInstances
//         })
//     }
// }

export const fetchActiveRound = roundId => dispatch => {
    if (roundId) {
        dispatch({
            type: FETCH_ACTIVE_ROUND_BEGIN,
        });
        let payload = {};
        roundsCollectionRef
            .doc(roundId)
            .get()
            .then(doc => {
                if (doc.exists) {
                    payload = { id: roundId, ...doc.data() };
                    dispatch({
                        type: FETCH_ACTIVE_ROUND_SUCCESS,
                        payload: payload
                    });
                } else {
                    dispatch({
                        type: FETCH_ACTIVE_ROUND_SUCCESS,
                        payload: payload
                    });
                }
            })
            .catch(err => {
                console.log("failed to fetch current round. Error: ", err);
                dispatch({
                    type: FETCH_ACTIVE_ROUND_FAILURE,
                });
            });
    } else {
        console.log("No round id available to fetch data");
    }
};

export const updatePlayer = (playerIndex, newPlayerData, allPlayers, roundId) => async dispatch => {
    // update the player at index
    allPlayers[playerIndex] = newPlayerData;
    dispatch({
        type: UPDATE_ACTIVE_ROUND_PLAYERS,
        payload: allPlayers
    });
    try {
        await roundsCollectionRef.doc(roundId).update({
            players: allPlayers
        });
    }
    catch(err) {
        console.error('Error updating the player in current round: ', err);
        AppNotifier.notify("Failed to update the player data");
    }
};

export const resetActiveRoundPlayerBeingEditedIndex = () => dispatch => dispatch({
    type: RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
});

export const setActiveRoundPlayerBeingEditedIndex = playerIndex => dispatch => {
    dispatch({
        type: SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
        payload: playerIndex
    });
} 

export const updatePlayerScore = (roundId, holeNumber, playerIndex, scoreInputKey, score) => dispatch => {
    let payloadArray = [holeNumber, playerIndex, scoreInputKey, score, roundId];
    dispatch({
        type: UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD,
        payload: payloadArray
    });
};

export const updateHoleScores = (roundId, holeNumber, holeScores) => dispatch => {
    let payloadArray = [roundId, holeNumber, holeScores];
    dispatch({
        type: UPDATE_SCORES_FOR_HOLE,
        payload: payloadArray
    });
};

export const setActiveRoundCurrentHole = currentHoleNumber => (dispatch, getState) => {
    if (currentHoleNumber) {
        dispatch({
            type: SET_ACTIVE_ROUND_CURRENT_HOLE,
            payload: currentHoleNumber
        });

        const currentRoundId = getState().activeRound.payload.id;
        roundsCollectionRef
            .doc(currentRoundId)
            .update({
                currentHole: currentHoleNumber
            })
            .catch(err => {
                console.error("Unable to update current hole in the database. Error: ", err);
            });
    }
};

export const updateHoleSettings = (roundId, holeNumber, currentHoleSettings, fieldKey, value) => async dispatch => {

    if (!currentHoleSettings[holeNumber]) {
        currentHoleSettings[holeNumber] = {};
    }

    currentHoleSettings[holeNumber][fieldKey] = value;

    dispatch({
        type: UPDATE_ACTIVE_ROUND_HOLE_SETTINGS,
        payload: currentHoleSettings
    });

    try {
        await roundsCollectionRef.doc(roundId).update({
            holesSettings: currentHoleSettings
        });
    }
    catch(err) {
        console.error("Unable to update current hole settings in the database. Error: ", err);
        AppNotifier.notify("Failed to update hole settings");
    }
}