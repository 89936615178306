import React from "react";
import { Typography, Avatar, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import firebase from "firebase/app";
import StyledFirebaseAuth from "react-firebaseui/FirebaseAuth";
import { firebaseApp } from "../../firebase/FirebaseApp";
import "firebase/auth";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import "./index.css";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(3, 3),
        minHeight: "100%",
        minWidth: "100%"
    }
}));

function Login(props) {
    const classes = useStyles();
    let uiConfig = {
        signInFlow: "redirect",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false
        }
    };

    if (props.authenticated) {
        return <Redirect to="/game-modes" />;
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={2} alignItems="center" justify="center" direction="column">
                    <Grid item>
                        <Typography component="h2" variant="h4">
                            CaddieGames
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar>
                            <LockOutlinedIcon color="action" />
                        </Avatar>
                    </Grid>

                    {!props.authenticated && (
                        <Grid item>
                            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth()} />
                        </Grid>
                    )}
                    {props.authenticated && (
                        <Grid item>
                            <Typography component="h3" color="error">
                                You are already in the game!!
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return { authenticated: state.auth.user };
};

export default connect(mapStateToProps)(Login);
