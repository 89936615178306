import React from "react";
import { connect } from "react-redux";
import { withStyles, BottomNavigation, BottomNavigationAction, Box } from "@material-ui/core";
import ScoreIcon from "@material-ui/icons/Flag";
import ResultIcon from "@material-ui/icons/FormatListNumbered";
import EditIcon from "@material-ui/icons/Edit";
import { fetchActiveRound } from "actions/activeRoundActions";
import CenteredCircularProgress from "components/utils/CenteredCircularProgress";
import RoundPlayView from "./RoundPlayView";
import RoundScorecardView from "./RoundScorecardView";
import { changeAppBarTempTitle, removeAppBarTempTitle } from "actions/appActions";
import RoundMatchView from "./RoundMatchView";

const classes = (theme) => ({
    root: {
        height: `calc(100% - ${theme.spacing(8)}px)`,
        overflow: 'auto' 
    },
    footer: {
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    bottomNavRoot: {
        height: theme.spacing(8),
        paddingBottom: theme.spacing(1),
    },
});

class RoundPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedNavItem: 0,
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) this.props.fetchActiveRound(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.golfCourse !== this.props.golfCourse) {
            this.props.changeAppBarTempTitle(this.props.golfCourse.name);
        }
    }

    componentWillUnmount() {
        this.props.removeAppBarTempTitle();
    }
    // TODO remove below
    // componentDidUpdate(prevProps) {
    //     if (prevProps.round.gameIds !== this.props.round.gameIds) {
    //         // run through the game ids, instantiate games and push to state
    //         let gameInstances = null;
    //         if (this.props.round.gameIds && this.props.round.gameIds.length > 0)
    //             gameInstances = this.instantiateGames(this.props.round.gameIds, this.props.round.players, this.props.round.gamesSettings);
    //         if (gameInstances && gameInstances.length > 0) {
    //             this.props.updateCurrentGameInstances(gameInstances);
    //         }
    //     }
    // }
    //instantiateGames = (gameIds, players, gamesSettings) => gameIds.map(gameId => GamesManager.initGame(gameId, { players: players, gamesSettings: gamesSettings }));

    render() {
        console.log("RoundPage rendered");

        const classes = this.props.classes;

        let selectedView = null;

        switch (this.state.selectedNavItem) {
            case 1:
                selectedView = <RoundScorecardView />;
                break;
            case 2:
                selectedView = <RoundMatchView />;
                break;
            default:
                selectedView = null;
        }

        return (
            <>
                {!this.props.visible ? (
                    <CenteredCircularProgress />
                ) : (
                    <>
                        <div className={classes.root}>
                            {/* we are toggling hide on this as it is an expensive render */}
                            <Box height="100%" hidden={this.state.selectedNavItem !== 0}>
                                <RoundPlayView />
                            </Box>
                            {selectedView}
                        </div>
                        <BottomNavigation
                            className={classes.footer}
                            classes={{ root: classes.bottomNavRoot }}
                            value={this.state.selectedNavItem}
                            onChange={(event, newValue) => {
                                this.setState({ selectedNavItem: newValue });
                            }}
                            showLabels
                        >
                            <BottomNavigationAction label="Holes" icon={<ScoreIcon />} />
                            <BottomNavigationAction label="Scorecards" icon={<EditIcon />} />
                            <BottomNavigationAction label="Match" icon={<ResultIcon />} />
                        </BottomNavigation>
                    </>
                )}
            </>
        );
    }
}

// redux here
const mapStateToProps = (state) => {
    return {
        visible: state.activeRound.isLoading,
        golfCourse: state.activeRound.payload.golfCourse,
    };
};

const mapDispatchToProps = {
    fetchActiveRound,
    changeAppBarTempTitle,
    removeAppBarTempTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(RoundPage));
