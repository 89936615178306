import { roundsCollectionRef } from "../firebase/collectionRefs";
import firebase from "firebase/app";
import AppNotifier from "../AppNotifier";
import {
    FETCH_ROUNDS_BEGIN,
    FETCH_ROUNDS_SUCCESS,
    ROUNDS_CHANGE_BEGIN,
    DELETE_ROUND_SUCCESS,
    RESET_SETUP_VIEW_NUMBER,
} from "actions/actionTypes";
// import { getField } from "Games/FieldTypes";

export const fetchRounds = () => (dispatch, getState) => {
    const userId = getState().auth.user.uid;

    dispatch({
        type: FETCH_ROUNDS_BEGIN,
        payload: true,
    });

    // dispatch({
    //     type: "SET_CURRENT_ROUND_IS_LOADING",
    //     payload: true
    // });
    roundsCollectionRef
        .where("userId", "==", userId)
        .orderBy("createdTimestamp", "desc")
        .get()
        .then((querySnapshot) => {
            let allRounds = {};
            querySnapshot.forEach(function (doc) {
                allRounds[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_ROUNDS_SUCCESS,
                payload: allRounds,
            });
            // dispatch({
            //     type: "SET_CURRENT_ROUND",
            //     payload: { ...allRounds[Object.keys(allRounds)[0]], id: Object.keys(allRounds)[0] }
            // });
        })
        .catch((err) => {
            console.log("Error getting all the rounds: ", err);
            // dispatch({
            //     type: "SET_ALL_ROUNDS_IS_LOADING",
            //     payload: false
            // });

            // dispatch({
            //     type: "SET_CURRENT_ROUND_IS_LOADING",
            //     payload: false
            // });
        });
};

// function getRequiredRoundSettingFields (gameIds) {
//     let mergedBeforeStartRoundRequiredFields = [];
//     gameIds.forEach((gameId) => {
//             const gameInstance = GamesManager.initGame(gameId);
//             mergedBeforeStartRoundRequiredFields = union(
//                 mergedBeforeStartRoundRequiredFields,
//                 gameInstance.beforeStartRequiredFields
//             );
//         });
//         return mergedBeforeStartRoundRequiredFields;
// }

export const createNewround = (playersCourseHandicaps, playersSelectedTeeIndexes, playersSelectedTeams, history) => (
    dispatch,
    getState
) => {
    const state = getState();
    let userId = state.auth.user.uid;
    let players = state.newRound.selectedPlayers;
    let golfCourse = state.newRound.selectedGolfCourse.details.payload;
    let gameIds = state.newRound.selectedGameIds.payload;
    let gamesSettings = state.newRound.gamesSettings;

    // const requiredRoundSettingFields = getRequiredRoundSettingFields(gameIds);
    // // lets check if we are missing some settings, we will fill default values for those fields
    // requiredRoundSettingFields.forEach(fieldKey => {
    //     // comparing with null will check for undefined or null value
    //     if (gamesSettings[fieldKey] == null) {
    //         gamesSettings[fieldKey] = getField(fieldKey).defaultValue
    //     }
    // })

    players = players.map((player, index) => {
        player.selectedTeeSetIndex = playersSelectedTeeIndexes[index];
        player.courseHandicap = Number.parseInt(playersCourseHandicaps[index]);
        player.hiLoTeam = playersSelectedTeams[index];
        return { ...player };
    });

    if (userId && players.length > 0 && golfCourse && gameIds.length > 0) {
        let round = {
            userId: userId,
            players: players,
            golfCourse: golfCourse,
            gameIds: gameIds,
            gamesSettings: gamesSettings,
            scores: {},
            createdTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedTimeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        };
        dispatch({
            type: ROUNDS_CHANGE_BEGIN,
        });

        roundsCollectionRef
            .add(round)
            .then((newRoundRef) => {
                console.log("New round added");
                dispatch({
                    type: RESET_SETUP_VIEW_NUMBER,
                });
                history.push("/play-round/" + newRoundRef.id);
            })
            .catch((err) => {
                AppNotifier.notify("Failed to create new round", { variant: "error" });
                console.log("Failed to add a new round of golf. Reason: ", err);
            });
    }
};

export const deleteRound = (roundId) => (dispatch) => {
    if (roundId) {
        dispatch({
            type: ROUNDS_CHANGE_BEGIN,
            payload: true,
        });
        roundsCollectionRef
            .doc(roundId)
            .delete()
            .then(() => {
                AppNotifier.notify("Round deleted");
                dispatch({
                    type: DELETE_ROUND_SUCCESS,
                    payload: roundId,
                });
            })
            .catch((err) => {
                AppNotifier.notify("Failed to delete", { variant: "error" });
                console.error("Unable to delete the round. Error: ", err);
                // dispatch({
                //     type: "SET_ALL_ROUNDS_IS_LOADING",
                //     payload: false
                // });
            });
    }
};

export const addOrUpdateNewRound = (key, Value) => (dispatch) => {};
