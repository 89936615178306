import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, IconButton, makeStyles, Snackbar } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
}));


function SWNotifier({ serviceWorkerUpdated, serviceWorkerRegistration }) {

    // props
    const classes = useStyles();

    // state
    const [open, setOpen] = useState(false);

    // side-effects
    useEffect(() => {
        if (serviceWorkerUpdated) setOpen(true);
    }, [serviceWorkerUpdated])

    // functions
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const updateServiceWorkerAndReload = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
        else {
            window.location.reload();
        }
    }

    if (!serviceWorkerUpdated) return null;
    // return template
    return <Snackbar
        key={"swUpdateMessage"}
        open={open}
        onClose={handleClose}
        message={"New Version is available! Please save changes and update now"}
        action={
            <React.Fragment>
                <Button color="secondary" size="small" onClick={updateServiceWorkerAndReload}>
                    Update
        </Button>
                <IconButton
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </React.Fragment>
        }
    />;
}

// redux here
const mapStateToProps = state => {
    return {
        serviceWorkerUpdated: state.serviceWorker.serviceWorkerUpdated,
        serviceWorkerRegistration: state.serviceWorker.serviceWorkerRegistration,
    };
};

export default connect(mapStateToProps, null)(SWNotifier);
