import { Checkbox } from "@material-ui/core";
import React from "react";

function HasBunkerBonusPointCheckbox({ initialValue, onValueChange }) {

    const handleChange = (evt) => {
        console.log(evt.target.checked)
        onValueChange(evt.target.checked);
    };

    return (
        <Checkbox
        checked={initialValue ? true : false}
        onChange={handleChange}
      />
    );
}

export default HasBunkerBonusPointCheckbox;