import StrokePlay from './StrokePlay';
import Stableford from './Stableford';
import Skins from './Skins';
import { union } from 'lodash';
import Putting from './Putting';
import Nines from './Nines';
import HiLo from './HiLo';
import OoslerWoosler from './OoslerWoosler';

// all game id constants
export const STROKE_PLAY = "STROKE_PLAY";
export const STABLEFORD = "STABLEFORD";
export const SKINS = "SKINS";
export const PUTTING = "PUTTING";
export const NINES = "NINES";
export const HI_LO = "HI_LO";
export const OOSLER_WOOSLER = "OOSLER_WOOSLER";

export const GamesEnum = {
    [STROKE_PLAY]: StrokePlay,
    [STABLEFORD]: Stableford,
    [SKINS]: Skins,
    [PUTTING]: Putting,
    [NINES]: Nines,
    [HI_LO]: HiLo,
    [OOSLER_WOOSLER]: OoslerWoosler
}

function getGameResultComponent(gameId) {
    if (GamesEnum[gameId] && GamesEnum[gameId].resultComponent) {
        return GamesEnum[gameId].resultComponent;
    }
    return null;
}

const initGame = (gameId, withOptions) => {
    if (GamesEnum[gameId]) {
        return new GamesEnum[gameId](withOptions)
    }
    return null;
}
const getGame = gameId => {
    if (GamesEnum[gameId]) {
        return GamesEnum[gameId];
    }
    return null;
}

const checkIfGameInstanceOfType = (gameInstance, gameTypeId) => {
    return gameInstance instanceof GamesEnum[gameTypeId];
}

const getCommonInputFieldsFromGameIds = gameIds => {
    let commonInputFields = [];
    gameIds.forEach(gameId => {
        const game = getGame(gameId);
        if (game) {
            const requiredFields = game.requiredFields();
            if (requiredFields) {
                commonInputFields = union(commonInputFields, requiredFields);
            }
        }
    });
    return commonInputFields;
}

const GamesManager = {
    initGame,
    getGame,
    allGames: GamesEnum,
    checkIfGameInstanceOfType,
    getCommonInputFieldsFromGameIds,
    getGameResultComponent
};

export default GamesManager;
// const games = [
//     {
//         id : 1,
//         name: 'Stableford',
//         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
//     },
//     {
//         id: 2,
//         name: 'Vegas',
//         description: 'The core for each team on a hole is arrived at by combining the scores of the team members. If both players in a team score 4, the score for the team is 44. I fone scores 3 and the other 5, the team score is 35 and so on, the lower of the two scores always being placed first.'
//     },
//     {
//         id: 3,
//         name: 'Wolf',
//         description: 'yet to be provided'
//     },
//     {
//         id: 4,
//         name: 'Bounce Back',
//         description: 'same as above'
//     },
//     {
//         id: 5,
//         name: 'Nassau',
//         description: 'mmaking it to overflow'
//     },
//     {
//         id: 6,
//         name: 'Last one',
//         description: 'done with the addition'
//     }
// ];

// export default games;