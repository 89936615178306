import React, { useEffect } from "react";
import { connect } from "react-redux";
import ChoosePlayers from "./ChoosePlayers";
import StartRound from "./StartRound";
import { setNewRoundSelectedGolfCourseDetails, fetchNewRoundGolfCourseDetails } from "actions/newRoundActions";
import { changeAppBarTempTitle, removeAppBarTempTitle } from "../../actions/appActions";
import SelectGames from "./SelectGames";

// const useStyles = makeStyles(theme => ({

// }));

function SetupRound(props) {
    //props
    const {
        currentViewNumber,
        fetchNewRoundGolfCourseDetails,
        changeAppBarTempTitle,
        removeAppBarTempTitle,
        golfCourse,
    } = props;

    // state

    // side effects
    useEffect(() => {
        if (golfCourse) {
            fetchNewRoundGolfCourseDetails(golfCourse.id);
        }
    }, [golfCourse, fetchNewRoundGolfCourseDetails]);

    useEffect(() => {
        if (golfCourse && golfCourse.name) {
            changeAppBarTempTitle(golfCourse.name);
        }
    }, [golfCourse, changeAppBarTempTitle]);

    useEffect(() => {
        // remove the golf course name from the appbar on unmount
        return () => removeAppBarTempTitle();
    }, [removeAppBarTempTitle]);

    // functions
    const renderRespectiveView = () => {
        switch (currentViewNumber) {
            case 1:
                return <SelectGames />;
            case 2:
                return <ChoosePlayers />;
            case 3:
                return <StartRound />;
            default:
                return null;
        }
    };

    return <>{renderRespectiveView()}</>;

    // return <h4>No saved games can be found in the Database</h4>;
}

// props type checking
SetupRound.propTypes = {};

// redux here
const mapStateToProps = (state) => {
    return {
        golfCourse: state.newRound.selectedGolfCourse.info.payload,
        currentViewNumber: state.newRound.setupViewNumber,
    };
};

const mapDispatchToProps = {
    setNewRoundSelectedGolfCourseDetails: (golfCourseDetails) =>
        setNewRoundSelectedGolfCourseDetails(golfCourseDetails),
    changeAppBarTempTitle: (newTitle) => changeAppBarTempTitle(newTitle),
    removeAppBarTempTitle: () => removeAppBarTempTitle(),
    fetchNewRoundGolfCourseDetails: (golfCourseId) => fetchNewRoundGolfCourseDetails(golfCourseId),
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupRound);
