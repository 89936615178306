import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { PUTTING } from "Games/GamesManager";
import { BACK_NINE_BET, FRONT_NINE_BET, IS_NASSAU, OVERALL_BET } from "Games/FieldTypes";

function PuttingResult({ scores, players, gameSettings, golfCourse }) {

    const gameInst = GamesManager.initGame(PUTTING, {
        scores: scores, players: players,
        golfCourse: golfCourse,
        gamesSettings: gameSettings
    });
    gameInst.runGame();

    let leaderBoardArray = [];
    leaderBoardArray.push(["Name", "Front", "Back", "Overall Points"]);
    gameInst.players.forEach((player) => {
        leaderBoardArray.push([player.name, player.frontPoints, player.backPoints, player.overallPoints]);
    });

    const isNassau = gameInst.getGameSetting(IS_NASSAU);

    return (
        <>
            <Box my={1}>
                <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />
            </Box>
            <Box mt={2} mb={1}>
                <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                    Bets</Box>
                </Typography>
                <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                    Nassau: {isNassau ? 'On' : 'Off'}</Box>
                </Typography>
                <Box my={1}>
                    <PuttingBetTable puttingInstance={gameInst} isNassau={isNassau} />
                </Box>
            </Box>
        </>
    );
}

const PuttingBetTable = ({ puttingInstance, isNassau }) => {
    // nassau means all three bets front, back and overall
    puttingInstance.calculateBetResults();
    let headingRow = null;
    let tableArray = [];
    if (isNassau) {
        headingRow = ['Name', `F($${puttingInstance.getGameSetting(FRONT_NINE_BET)})`, `B($${puttingInstance.getGameSetting(BACK_NINE_BET)})`, `O($${puttingInstance.getGameSetting(OVERALL_BET)})`, 'Total'];
    }
    else {
        headingRow = ['Name', `O($${puttingInstance.getGameSetting(OVERALL_BET)}})`];
    }
    tableArray.push(headingRow);
    puttingInstance.players.forEach((player) => {
        let row = [];
        if (isNassau) {
            row = [player.name, player.frontBetWon, player.backBetWon, player.overallBetWon, player.totalBetWon];
        }
        else {
            row = [player.name, player.overallBetWon];
        }

        tableArray.push(row);
    });
    return <LeaderboardTable noPaper rowsWithColumns={tableArray} />
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        gameSettings: state.activeRound.payload.gamesSettings,
        golfCourse: state.activeRound.payload.golfCourse
    };
};
export default connect(mapStateToProps)(PuttingResult);