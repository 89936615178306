import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Box } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { NINES } from "Games/GamesManager";

const classes = (theme) => ({
    playerStatsBox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});

class NinesResult extends Component {
    runGameLogic() {
        const gameInst = GamesManager.initGame(NINES);
        gameInst.scores = this.props.scores;
        gameInst.players = this.props.players;
        gameInst.golfCourse = this.props.golfCourse;
        gameInst.runGame();
        return gameInst;
    }

    render() {
        console.log("NinesResult rendering");

        const skinGameInst = this.runGameLogic();

        let leaderBoardArray = [];
        leaderBoardArray.push(["Name", "Gross Points", "Net Points"]);

        skinGameInst.players.forEach((player) => {
                leaderBoardArray.push([player.name, player.grossPoints, player.netPoints]);
        });

        return (
            <>
                <Box my={1}>
                    <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
    };
};

export default connect(mapStateToProps)(withStyles(classes)(NinesResult));
