import React, { useState, useEffect } from "react";
import { TextField, FormControl, FormHelperText, Typography } from "@material-ui/core";
import { isNumberUptoTwoDecimalPoints } from "utils/numbers";


function BetInput({ name, value, onValueChange, label }) {
    console.log("BetInput component rendering");

    const [intrinsicValue, setIntrinsicValue] = useState(1);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (value) {
            setIntrinsicValue(value)
        }
    }, [value])

    const handleInputChange = evt => {
        setIntrinsicValue(evt.target.value);
        const newValue = Number(evt.target.value);
        if (isNumberUptoTwoDecimalPoints(newValue) && newValue > 0) {
            setError(null);
            onValueChange(newValue);
        }
        else {
            setError("Accepts upto two decimal places and values greater than zero");
        }
    };

    return (
        <React.Fragment>
            <Typography>{name}</Typography>
            <FormControl error={error ? true : false}>
                <TextField
                    label={label ? label : ""}
                    type="number"
                    inputProps={{
                        step: '0.01'
                    }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={intrinsicValue}
                    variant="standard"
                    onChange={handleInputChange}
                />
                {error &&
                    <FormHelperText>{error}</FormHelperText>
                }
            </FormControl>
        </React.Fragment>
    );
}

export default BetInput;
