import { CHANGE_APP_BAR_TEMP_TITLE, REMOVE_APP_BAR_TEMP_TITLE, SET_DARK_MODE } from "actions/actionTypes";

export const changeAppBarTempTitle = newTitle => dispatch => {
    if (typeof newTitle === "string") {
        dispatch({
            type: CHANGE_APP_BAR_TEMP_TITLE,
            payload: newTitle
        });
    }
};

export const removeAppBarTempTitle = () => dispatch => {
    console.log("removeAppBarTempTitle() called");
    dispatch({
        type: REMOVE_APP_BAR_TEMP_TITLE
    });
};

export const setDarkMode = trueOrFalse => dispatch => {
    if (typeof trueOrFalse === "boolean") {
        dispatch({
            type: SET_DARK_MODE,
            payload: trueOrFalse
        });
    }
};
