import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import withPaper from "./withPaper";

class StablefordInfo extends Component {
    
    render() {
        const gameInst = this.props.gameInstance;
        gameInst.holesData = this.props.holesData;

        return (
            <>
                <Typography variant="body1">{gameInst.name}</Typography>
                <Typography variant="body2">Adjusted Par: {gameInst.getAdjustedParForInfo()}</Typography>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        holesData: state.activeRound.payload.golfCourse.holes_data
            ? state.activeRound.payload.golfCourse.holes_data
            : null
    };
};

export default connect(mapStateToProps)(withPaper(StablefordInfo));
