import StablefordResult from "../containers/GameResult/StablefordResult";
import StablefordInfo from "../components/PlayRound/GameInfo/StablefordInfo";
import StrokePlay from "./StrokePlay";
import { STABLEFORD } from "./GamesManager";
import { STROKES } from "./FieldTypes";

const defaults = {
    holesData: [],
    golfCourse: null
};

class Stableford extends StrokePlay {
    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: Stableford.gameName,
            description: Stableford.description,
            resultComponent: StablefordResult,
            holePlayerInfoComponent: StablefordInfo,
        };
        super(superOptions);

        this.golfCourse = options.golfCourse || defaults.golfCourse;
        this.pointsToPlayers = this.buildPointsToPlayer();
    }

    buildPointsToPlayer() {
        return this.players.map((player, playerIndex) => ({
            playerIndex: playerIndex,
            frontPoints: 0,
            backPoints: 0,
            totalPoints: 0
        }));
    }

    getFinalStatus() {
        let finalStatus = "Winner is " + this.players[this.leaderboard[0].playerIndex].name;
        for (let i = 1; i < this.leaderboard.length; i++) {
            if (this.leaderboard[0].totalPoints === this.leaderboard[i].totalPoints) {
                finalStatus = "Game is Tied";
                break;
            }
        }
        return finalStatus;
    }

    calculateBetResults() {
        // there is no gross in stableford, the points are calculated handicap adjusted
        this.calculateNetBetResults();
    }

    getNassauNetResults(holesGroup) {
        if (['BACK_NINE', 'FRONT_NINE', 'OVERALL'].includes(holesGroup)) {
            let bestPoints = 0;
            if (holesGroup === 'FRONT_NINE') {
                bestPoints = Math.max(...this.pointsToPlayers.map(player => player.frontPoints));
            }
            else if (holesGroup === 'BACK_NINE') {
                bestPoints = Math.max(...this.pointsToPlayers.map(player => player.backPoints));
            }
            else {
                bestPoints = Math.max(...this.pointsToPlayers.map(player => player.totalPoints));
            }
            // now we have these sorted get all the top draw players
            let playersIndexesWithBestPoints = [];
            this.pointsToPlayers.forEach((player) => {
                if (holesGroup === 'FRONT_NINE') {
                    if (player.frontPoints === bestPoints) {
                        playersIndexesWithBestPoints.push(player.playerIndex);
                    }
                }
                else if (holesGroup === 'BACK_NINE') {
                    if (player.backPoints === bestPoints) {
                        playersIndexesWithBestPoints.push(player.playerIndex);
                    }
                }
                else {
                    if (player.totalPoints === bestPoints) {
                        playersIndexesWithBestPoints.push(player.playerIndex);
                    }
                }
            }
            );
            return playersIndexesWithBestPoints;
        }
        return [];
    }

    calculateLeaderboard() {
        this.calculatePointsForPlayers();
        let leaderboard = [...this.pointsToPlayers];
        leaderboard.sort((a, b) => b.totalPoints - a.totalPoints);
        
        this.leaderboard = leaderboard;
    }

    getAdjustedParForInfo = () => {
        const totalHoles = this.golfCourse.holes_data.length;
        if (!totalHoles || !this.infoAttrs.player || !this.infoAttrs.hole || !this.infoAttrs.hole.info.par)
            return "Unknown";
        return this.getAdjustedPar(this.infoAttrs.hole, this.infoAttrs.player);
    };

    calculatePointsForPlayers = () => {
        this.pointsToPlayers.forEach((pointsToPlayer) => {
            const playerIndex = pointsToPlayer.playerIndex;
            for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
                if (holeScores[playerIndex] && holeScores[playerIndex][STROKES]) {
                    const adjustedPar = this.getAdjustedPar(holeNumber, this.players[playerIndex]);
                    const strokesToPar = adjustedPar - parseInt(holeScores[playerIndex][STROKES]);
                    let points = strokesToPar + 2;
                    if (points < 1) {
                        points = 0;
                    }
                    if (holeNumber <= 9) {
                        pointsToPlayer.frontPoints += points;
                    }
                    else {
                        pointsToPlayer.backPoints += points;
                    }
                    pointsToPlayer.totalPoints += points;
                }
            }
            // finally calculate the net strokes also
            pointsToPlayer.netTotalStrokes = pointsToPlayer.grossTotalStrokes - parseInt(this.players[playerIndex].courseHandicap);
        });
    };

    static getId() {
        return STABLEFORD;
    }
}

Stableford.gameName = "Stableford";
Stableford.description =
    "Rather than counting the total number of strokes taken, involves scoring points based on the number of strokes taken at each hole";
Stableford.resultComponent = StablefordResult;

export default Stableford;
