import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Grid,
    makeStyles,
    Typography,
    Box,
} from "@material-ui/core";
import { Redirect } from 'react-router-dom';
import NewGameCard from "components/golfCourse/NewGameCard";
import CenteredCircularProgress from "components/utils/CenteredCircularProgress";
import GolfCourseSearchBox from "components/golfCourse/GolfCourseSearchBox";
import { addNewCourseToRecentCourses } from "actions/recentGolfCoursesActions";
import { updateCurrentGolfCourse } from 'actions/newRoundActions';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1, 0),
        // minWidth: 275,
        display: "flex",
        justifyContent: "space-around"
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    dashboardGridContainer: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%"
    }
}));

function DashboardPage(props) {
    const classes = useStyles();

    const [redirectTo, setRedirectTo] = useState(null);

    useEffect(() => {
        console.log("Dashboard component rendered");
    });

    // function SelectGolfCourseGridItem() {
    //     return (
    //         <React.Fragment>
    //             <Grid item>
    //                 <Button to="/search-courses" component={Link} variant="contained" size="large" color="primary">
    //                     Select your Golf Course
    //                 </Button>
    //             </Grid>
    //         </React.Fragment>
    //     );
    // }
    // function CurrentCourseAvailableGridItems(props) {
    //     const classes = useStyles();
    //     return (
    //         <React.Fragment>
    //             <Grid item>
    //                 <Paper className={classes.paper}>
    //                     <Box>
    //                         <Typography variant="h6">
    //                             {props.golfCourse.name ? props.golfCourse.name : "<No Golf Course>"}
    //                         </Typography>
    //                         <Typography variant="subtitle2" color="textSecondary">
    //                             {props.golfCourse.location}
    //                         </Typography>
    //                     </Box>
    //                     <Button size="medium" color="primary" to="setup-game/select-game" component={Link}>
    //                         New Game
    //                     </Button>
    //                 </Paper>
    //             </Grid>
    //         </React.Fragment>
    //     );
    // }

    // const [currentGolfCourseTemplate, setCurrentGolfCourseTemplate] = useState(
    //     <Box style={{ width: "100%", height: "100%" }} display="flex" alignContent="center" justifyContent="center">
    //         <CircularProgress></CircularProgress>
    //     </Box>
    // );

    // useEffect(() => {
    //     if (props.selectedGolfCourse) {
    //         if (!props.selectedGolfCourse.isLoading) {
    //             if (props.selectedGolfCourse.course) {
    //                 setCurrentGolfCourseTemplate(
    //                     <CurrentCourseAvailableGridItems golfCourse={props.selectedGolfCourse.course} />
    //                 );
    //             } else {
    //                 setCurrentGolfCourseTemplate(<SelectGolfCourseGridItem />);
    //             }
    //         }
    //     }
    // }, [props.selectedGolfCourse]);

    const handleNewGameClick = golfCourse => {
        props.updateCurrentGolfCourse(golfCourse);
        setRedirectTo('/setup-game');
    };

    const handleCourseSelect = golfCourse => {
        props.addNewCourseToRecentCourses(props.user, golfCourse, props.recentGolfCourses);
    };

    if (redirectTo) {
        return <Redirect to={redirectTo} />
    }

    return (
        <React.Fragment>
            <div className={classes.dashboardGridContainer}>
                <Box marginY={2}>
                    <GolfCourseSearchBox onCourseSelect={handleCourseSelect} />
                </Box>
                <Box marginY={2}>
                    <Grid container direction="column" spacing={1}>
                        {props.recentGolfCoursesIsLoading ? (
                            <CenteredCircularProgress />
                        ) : props.recentGolfCourses.length > 0 ? (
                            props.recentGolfCourses.map(golfCourse => {
                                return (
                                    <Grid key={golfCourse.id} item>
                                        <NewGameCard golfCourse={golfCourse} onNewGameClick={handleNewGameClick} />
                                    </Grid>
                                );
                            })
                        ) : (
                            <Typography align="center" variant="h6">
                                No Recent Courses
                            </Typography>
                        )}
                    </Grid>
                </Box>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        selectedGolfCourse: state.newRound.selectedGolfCourse.info.payload,
        recentGolfCourses: state.recentGolfCourses.payload,
        recentGolfCoursesIsLoading: state.recentGolfCourses.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addNewCourseToRecentCourses: (user, newCourse, recentCourses) => dispatch(addNewCourseToRecentCourses(user, newCourse, recentCourses)),
        updateCurrentGolfCourse: (selectedCourse) => dispatch(updateCurrentGolfCourse(selectedCourse))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
