import {
    FETCH_RECENT_GOLF_COURSES_BEGIN,
    FETCH_RECENT_GOLF_COURSES_SUCCESS,
    ADD_NEW_GOLF_COURSE_TO_RECENT_COURSES,
    UPDATE_RECENT_GOLF_COURSES_BEGIN,
    UPDATE_RECENT_GOLF_COURSES_SUCCESS
} from "actions/actionTypes";

// 5 golf courses selected by the user in past
const initState = {
    // below is the course which is selected by the user, when starting a new round etc.
    isLoading: true,
    payload: []
};
const recentGolfCoursesReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_RECENT_GOLF_COURSES_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_RECENT_GOLF_COURSES_SUCCESS:
            return {
                ...state,
                payload: action.payload ? action.payload : [],
                isLoading: false
            };
        case ADD_NEW_GOLF_COURSE_TO_RECENT_COURSES:
            return {
                ...state,
                payload: [...state.payload, action.payload],
                isLoading: false
            };
        case UPDATE_RECENT_GOLF_COURSES_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_RECENT_GOLF_COURSES_SUCCESS:
            return {
                ...state,
                payload: action.payload ? [...action.payload] : initState.payload,
                isLoading: false
            };
        default:
        // do nothing
    }
    return state;
};

export default recentGolfCoursesReducer;
