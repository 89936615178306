import React, { useState, useEffect } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { Input, CircularProgress, Paper, ClickAwayListener, List, ListItem, ListItemText } from "@material-ui/core";
import AppNotifier from "../../AppNotifier";

// JSS here
const useStyles = makeStyles((theme) => ({
    searchList: {
        flexGrow: 1,
        overflow: "scroll",
    },

    searchContainer: {
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },

    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    },

    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    inputRoot: {
        width: "100%",
        color: "inherit",
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
    },
}));

// cancel token setup for axios request
const CancelToken = axios.CancelToken;
let cancelToken;

// List item component
function CourseListItem(props) {
    return (
        <ListItem button>
            <ListItemText
                primary={
                    <Typography display="block" component="span" noWrap color="textPrimary">
                        {props.item.name}
                    </Typography>
                }
            />
        </ListItem>
    );
}

function GolfCourseSearchBox({ onCourseSelect }) {
    /**
     * Props
     * onCourseSelect - callback function to run when a course is selected
     */
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState("");

    const [coursesLoading, setCoursesLoading] = useState(true);

    const [searchListOpen, setSearchListOpen] = React.useState(false);

    const [fetchedCourses, setFetchedCourses] = React.useState([]);

    const handleCourseItemClick = (item) => () => {
        setSearchListOpen(false);
        onCourseSelect(item);
    };

    // we are using reduce so that we can omit golf courses which can be bad(e.g. doesn't have id or name)
    const searchCoursesRender = fetchedCourses.reduce((all, item) => {
        if (item.name && item.id) {
            all.push(
                <div key={item.id} onClick={handleCourseItemClick(item)}>
                    <CourseListItem item={item} />
                </div>
            );
        }
        return all;
    }, []);

    const searchInputOnChange = (evt) => {
        let term = evt.target.value;
        if (term) {
            setSearchTerm(term);
            setCoursesLoading(true);
        } else setSearchTerm("");
    };

    // useEffect(function getGLooperAuth() {
    //   let tokenInCookie = () => {
    //     return "";
    //   }

    //   axios({
    //     method: 'get',
    //     responseType: 'json',
    //     url: 'http://localhost:5000/caddygames-ef56e/us-central1/getGLooperLoginAuthenticityToken'
    //   })
    //     .then(function (response) {
    //       console.log(response);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // }, []);

    useEffect(() => {
        const onChangeSearch = () => {
            if (searchTerm) {
                cancelToken && cancelToken();
                axios
                    .get(process.env.REACT_APP_FUNCTIONS_SERVER_URL + "app/GLooperCoursesWithTerm?term=" + searchTerm, {
                        cancelToken: new CancelToken(function executor(c) {
                            cancelToken = c;
                        }),
                        headers: {
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.data.golfCourses && Array.isArray(response.data.golfCourses)) {
                            setFetchedCourses(response.data.golfCourses);
                            setCoursesLoading(false);
                        } else setFetchedCourses([]);
                    })
                    .catch((err) => {
                        AppNotifier.notify("Failed. Some problem has occured", { variant: "error" });
                        console.log(err);
                        setFetchedCourses([]);
                    });
            }
        };
        const typingTimeoutToken = setTimeout(onChangeSearch, 2000);
        return () => clearTimeout(typingTimeoutToken);
    }, [searchTerm]);

    return (
        <ClickAwayListener
            onClickAway={() => {
                setSearchListOpen(false);
            }}
        >
            <div className={classes.searchContainer}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <Input
                        type="search"
                        placeholder="Search Golf Course"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        onClick={() => {
                            setSearchListOpen(true);
                        }}
                        onChange={(evt) => {
                            searchInputOnChange(evt);
                        }}
                    />
                </div>
                {searchListOpen && searchTerm ? (
                    <Paper className={classes.searchList}>
                        <List>
                            {searchCoursesRender.length > 0 && !coursesLoading ? (
                                searchCoursesRender
                            ) : (
                                <ListItem>
                                    <CircularProgress className={classes.progress} />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}

export default GolfCourseSearchBox;
