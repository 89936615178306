import React from "react";
import Button from "@material-ui/core/Button";
import { DialogActions, TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Must be a valid email address"),
    // .required("Email is required"),
    handicapIndex: Yup.string()
        // .typeError("Must be a number")
        .required("Handicap Index is required")
        .matches(new RegExp("^[0-9]+(.[0-9])?$"), "Handicap Index can only be a number with one decimal place"),
});

const defaultPlayer = {
    name: "",
    email: "",
    handicapIndex: 1,
};

export default function AddEditFavouritePlayerForm({ editPlayer, onReset, onSubmit }) {
    console.log("AddEditFavouritePlayerForm component is rendering");
    let player = defaultPlayer;
    let addForm = true;
    if (editPlayer) {
        player = { ...player, ...editPlayer };
        addForm = false;
    }
    return (
        <Formik
            initialValues={player}
            onReset={onReset}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            render={({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, handleReset }) => (
                <form onSubmit={handleSubmit} onReset={handleReset}>
                    <TextField
                        label="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.name && touched.name && errors.name}
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.email && touched.email && errors.email}
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        type="number"
                        inputProps={{ step: "0.1" }}
                        label="Handicap Index"
                        name="handicapIndex"
                        value={values.handicapIndex}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.handicapIndex && touched.handicapIndex && errors.handicapIndex}
                        margin="dense"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <DialogActions>
                        <Button disabled={!isValid} type="submit" color="primary">
                            {addForm ? "Add" : "Save"}
                        </Button>
                        <Button type="reset" color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            )}
        />
    );
}
