const defaults = {
    golfCourse: null,
};

/**
 * a simple util class for extracting values from common Group Looper database objects
 */
class GLooperObjectsDataExtractor {
    constructor(objects = {}) {
        this.golfCourse = objects.golfCourse || defaults.golfCourse;
    }

    getTeeSetsFromGolfCourse = () => {
        return this.golfCourse.tee_sets ? this.golfCourse.tee_sets : [];
    };

    getYardageFromGolfCourse = (teeSetIndex, holeIndex) => {
        const teeSets = this.getTeeSetsFromGolfCourse();

        if (teeSets.length < 1) {
            return null;
        }

        const teeSet = teeSets[teeSetIndex];
        if (teeSet && teeSet.hole_tees) {
            const holeTeeInfo = teeSet.hole_tees[holeIndex];
            if (holeTeeInfo && holeTeeInfo.yardage) {
                return holeTeeInfo.yardage;
            }
        }
        return null;
    };

    getHoleHandicap(holeNumber) {
        const holesData = this.golfCourse.holes_data;
        if (holesData) {
            for (const holeData of holesData) {
                if (holeData.hole_number && holeData.hole_number == holeNumber && holeData.info && holeData.info.handicap) {
                    return holeData.info.handicap;
                }
            }
        }
        // otherwise we can assume the holenumber is same as hole handicap
        return holeNumber;
    }

    getTotalNumberOfHolesFromGolfCourse() {
        if (this.golfCourse.holes_data && this.golfCourse.holes_data.length) {
            return this.golfCourse.holes_data.length;
        }
        console.error("Golf course holes data is empty. This is not good");
        return null;
    }

    getParFromGolfCourse(holeNumber) {
        const holesData = this.golfCourse.holes_data;
        if (holesData) {
            for (const holeData of holesData) {
                if (holeData.hole_number && holeData.hole_number == holeNumber && holeData.info) {
                    return holeData.info.par;
                }
            }
        }
        return null;
    }

    // getParFromGolfCourse(teeSetIndex, holeNumber)
    // {
    //     const teeSets = this.getTeeSetsFromGolfCourse();

    //     if (teeSets.length < 1) {
    //         return null;
    //     }

    //     const teeSet = teeSets[teeSetIndex];
    //     if (teeSet && teeSet.hole_tees) {
    //         for (const teeHoleObject of teeSet.hole_tees) {
    //             if (teeHoleObject.hole_number && teeHoleObject.hole_number == holeNumber) {
    //                 return teeHoleObject.par
    //             }
    //         }
    //     }
    //     return null;
    // }; 
}

export default GLooperObjectsDataExtractor;
