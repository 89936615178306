import React from "react";
import { withStyles, Paper, Box, Button } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import RoundScorecardTable from "./RoundScorecardTable";
import axios from "axios";
import AppNotifier from "AppNotifier";
import {connect} from "react-redux";

const classes = (theme) => ({
    tableContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

class RoundScorcardView extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            emailPlayerButtonDisabled: false
        }

        // bind this to callback functions
        this.handleEmailPlayersClick = this.handleEmailPlayersClick.bind(this);
    }
    
    async handleEmailPlayersClick() {
        this.setState({
            emailPlayerButtonDisabled: true
        });
        // make the email send request
        try {
            const response = await axios
            .get(process.env.REACT_APP_FUNCTIONS_SERVER_URL + "app/sendScorecardEmailToPlayers?id=" + this.props.roundId, {
                headers: {
                    Accept: "application/json",
                },
            });
            console.log(response.data);
            AppNotifier.notify('Emails sent!');
        }
        catch (err) {
            console.error(err);
            if (err.response && err.response.data.message) {
                AppNotifier.notify(err.response.data.message, { variant: "error" });
            }
            else {
                AppNotifier.notify("Failed to send the emails", { variant: "error" });
            }
        }
        this.setState({
            emailPlayerButtonDisabled: false
        });
        // axios
        // .get(process.env.REACT_APP_FUNCTIONS_SERVER_URL + "sendScorecardEmailToPlayers?id=" + this.props.roundId, {
        //     headers: {
        //         Authorization: 'Bearer ' + firebaseApp.auth().currentUser.getIdToken(),
        //         Accept: "application/json",
        //     },
        // })
        // .then(response => {
        //     console.log(response.data);
        //     AppNotifier.notify('Emails sent!');
        // })
        // .catch(err => {
        //     console.log(err);
        //     AppNotifier.notify("Failed to send the emails", { variant: "error" });
        // })
        // .finally(() => {
        //     this.setState({
        //         emailPlayerButtonDisabled: false
        //     });
        // })
        // ;
    }

    render() {
        console.log("RoundScorcardView rendered");

        const { classes } = this.props;

        return (
            <>
                <Paper square className={classes.tableContainer}>
                    <RoundScorecardTable />
                </Paper>
                <Box m={2}>
                    <Button disabled={this.state.emailPlayerButtonDisabled} onClick={this.handleEmailPlayersClick} variant="contained" color="primary" endIcon={<SendIcon />}>
                        Email Players
                    </Button>
                </Box>
            </>
        );
    }
}

export default connect(state => ({
    roundId: state.activeRound.payload.id
}))(withStyles(classes)(RoundScorcardView));
