import Game from "./Game";
import StablefordResult from "containers/GameResult/StablefordResult";
import SkinsResult from "containers/GameResult/SkinsResult";
import { SKINS } from "./GamesManager";
import currency from 'currency.js';
import { STROKES, CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS, CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET, POT_PLAYER_BET_GROSS, POT_PLAYER_BET_NET } from "./FieldTypes";


class Skins extends Game {
    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: Skins.gameName,
            description: Skins.description,
            beforeStartRequiredFields: Skins.getGameSettingsFields(),
            resultComponent: StablefordResult,
            // holePlayerInfoComponent: StablefordInfo
        };
        super(superOptions);
    }

    getFormat() {
        if (this.gamesSettings && this.gamesSettings[SKINS] && this.gamesSettings[SKINS]['SKINS_FORMAT']
            &&
            this.gamesSettings[SKINS]['SKINS_FORMAT'] === 'CARRY_FORWARD') {
            return 'CARRY_FORWARD';
        }
        return 'POT';
    }

    runGame() {
        if (this.getFormat() === 'POT') {
            this.calculatePotPlayersMoneyWonGrossAndNet();
        }
        else {
            this.calculateCarryForwardPlayersMoneyWonGrossAndNet();
        }
    }

    //////// Carry forward methods /////////
    getPerHoleBetForGrossAndNet() {
        let returnArray = [0, 0];

        const betPerPlayerGross = currency(this.getGameSetting("CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS"));
        const betPerPlayerNet = currency(this.getGameSetting("CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET"));
        if (betPerPlayerGross) {
            returnArray[0] = betPerPlayerGross.multiply(this.players.length).toString();;
        }
        if (betPerPlayerNet) {
            returnArray[1] = betPerPlayerNet.multiply(this.players.length).toString();;
        }
        return returnArray;
    }

    calculateCarryForwardPlayersMoneyWonGrossAndNet() {
        let [perHoleBetGross, perHoleBetNet] = this.getPerHoleBetForGrossAndNet();
        perHoleBetGross = currency(perHoleBetGross);
        perHoleBetNet = currency(perHoleBetNet);

        this.players.forEach((player) => {
            player.cfMoneyWonNet = currency(0);
            player.cfMoneyWonGross = currency(0);
        });

        // We are going to assume they play the holes in order
        // calculate in hole order
        // we will stop calculating for further holes if scores for a hole is missing
        let currentHoleCarryBetForGross = perHoleBetGross;
        let currentHoleCarryBetForNet = perHoleBetNet;
        // current hole number
        let holeNumber = 1;
        while (this.scores[holeNumber]) {
            const holeScores = this.scores[holeNumber];
            const playerIndexWithLeastStrokes = this.getPlayerIndexWithLeastStrokes(holeNumber, holeScores, false);
            const playerIndexWithLeastNetStrokes = this.getPlayerIndexWithLeastStrokes(holeNumber, holeScores, true);
            // if there is anybody with outright win on a hole, lets add increment their win
            if (playerIndexWithLeastStrokes !== null) {
                this.players[playerIndexWithLeastStrokes].cfMoneyWonGross = this.players[playerIndexWithLeastStrokes].cfMoneyWonGross.add(currentHoleCarryBetForGross);
                // reset current hole bet
                currentHoleCarryBetForGross = perHoleBetGross;
            }
            else {
                // increase the bet for next hole if a draw
                currentHoleCarryBetForGross = currentHoleCarryBetForGross.add(perHoleBetGross);
            }

            if (playerIndexWithLeastNetStrokes !== null) {
                this.players[playerIndexWithLeastNetStrokes].cfMoneyWonNet = this.players[playerIndexWithLeastNetStrokes].cfMoneyWonNet.add(currentHoleCarryBetForNet);
                // reset current hole bet
                currentHoleCarryBetForNet = perHoleBetNet;
            }
            else {
                currentHoleCarryBetForNet = currentHoleCarryBetForNet.add(perHoleBetNet);
            }
            holeNumber++;
        }

        // lets go back from currency objects to strings which we dispaly in UI
        this.players.forEach((player) => {
            player.cfMoneyWonNet = player.cfMoneyWonNet.toString();
            player.cfMoneyWonGross = player.cfMoneyWonGross.toString();
        });
        // for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
        //     const playerIndexWithLeastStrokes = this.getPlayerIndexWithLeastStrokes(holeNumber, holeScores, false);
        //     const playerIndexWithLeastNetStrokes = this.getPlayerIndexWithLeastStrokes(holeNumber, holeScores, true);
        //     // if there is anybody with outright win on a hole, lets add increment their win
        //     if (playerIndexWithLeastStrokes !== null) {
        //         this.players[playerIndexWithLeastStrokes].cfMoneyWonGross += currentHoleCarryBetForGross;
        //         // reset current hole bet
        //         currentHoleCarryBetForGross = perHoleBet;
        //     }
        //     else {
        //         // increase the bet for next hole if a draw
        //         currentHoleCarryBetForGross += perHoleBet;
        //     }

        //     if (playerIndexWithLeastNetStrokes !== null) {
        //         this.players[playerIndexWithLeastNetStrokes].cfMoneyWonNet += currentHoleCarryBetForNet;
        //         // reset current hole bet
        //         currentHoleCarryBetForNet = perHoleBet;
        //     }
        //     else {
        //         currentHoleCarryBetForNet += perHoleBet;
        //     }
        // }
    }

    //////// POT methods //////////
    getPotTotalForGrossAndNet() {
        let returnArray = [false, false];

        const betPerPlayerGross = currency(this.getGameSetting("POT_PLAYER_BET_GROSS"));
        if (betPerPlayerGross) {
            returnArray[0] = betPerPlayerGross.multiply(this.players.length).toString();
        }
        const betPerPlayerNet = currency(this.getGameSetting("POT_PLAYER_BET_NET"));
        if (betPerPlayerNet) {
            returnArray[1] = betPerPlayerNet.multiply(this.players.length).toString();
        }
        return returnArray;
    }

    calculatePotPlayersMoneyWonGrossAndNet() {
        let [potTotalGross, potTotalNet] = this.getPotTotalForGrossAndNet();
        potTotalGross = currency(potTotalGross);
        potTotalNet = currency(potTotalNet);

        // get the holes won and lost outright in the round
        this.players.forEach((player) => {
            player.potMoneyWonNet = currency(0);
            player.potMoneyWonGross = currency(0);
        });

        if (potTotalGross) {
            this.calculatePlayersSkinsWon(false);
            // now we have the skins won, lets see how much money was in the pot and calculate the individual winnings
            // for gross
            let overallSkinsWonGross = 0;
            this.players.forEach(player => {
                overallSkinsWonGross = overallSkinsWonGross + player.grossSkinsWon
            });
            if (overallSkinsWonGross) {
                // rather than dividing which can cause some money disappearing with floating point division, we will distribute the money into an array
                let moneyDistributableToSkins = potTotalGross.distribute(overallSkinsWonGross);

                // const moneyForEverySkin = potTotal / overallSkinsWonGross;
                // finally alot the money
                this.players.forEach(player => {
                    if (player.grossSkinsWon) {
                        const moneyDistributableToPlayerSkins = moneyDistributableToSkins.splice(0, player.grossSkinsWon);
                        moneyDistributableToPlayerSkins.forEach(moneyToAdd => player.potMoneyWonGross = player.potMoneyWonGross.add(moneyToAdd));
                        // player.potMoneyWonGross = player.potMoneyWonGross + moneyForEverySkin * player.grossSkinsWon
                    }

                });
            }

        }

        if (potTotalNet) {
            this.calculatePlayersSkinsWon(true);
            // now we have the skins won, lets see how much money was in the pot and calculate the individual winnings
            let overallSkinsWonNet = 0;
            this.players.forEach(player => {
                overallSkinsWonNet = overallSkinsWonNet + player.netSkinsWon
            });
            if (overallSkinsWonNet) {
                let moneyDistributableToSkins = potTotalNet.distribute(overallSkinsWonNet);

                // finally alot the money
                this.players.forEach(player => {
                    if (player.netSkinsWon) {
                        const moneyDistributableToPlayerSkins = moneyDistributableToSkins.splice(0, player.netSkinsWon);
                        moneyDistributableToPlayerSkins.forEach(moneyToAdd => player.potMoneyWonNet = player.potMoneyWonNet.add(moneyToAdd));
                    }
                });
            }
        }

        // we are done with all the money calculations, lets convert back to strings

        this.players.forEach((player) => {
            player.potMoneyWonNet = player.potMoneyWonNet.toString();
            player.potMoneyWonGross = player.potMoneyWonGross.toString();
        });
    }

    calculatePlayersSkinsWon(byNet) {
        if (byNet) {
            this.players.forEach(player => player.netSkinsWon = 0);
        }
        else {
            this.players.forEach(player => player.grossSkinsWon = 0);
        }
        for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
            const playerIndexWithLeastStrokes = this.getPlayerIndexWithLeastStrokes(holeNumber, holeScores, byNet);
            // if there is anybody with outright win on a hole, lets add increment their win and loss for other players
            if (playerIndexWithLeastStrokes !== null) {
                if (byNet)
                    this.players[playerIndexWithLeastStrokes].netSkinsWon++;
                else
                    this.players[playerIndexWithLeastStrokes].grossSkinsWon++;
            }
        }
    }

    getPlayerIndexWithLeastStrokes(holeNumber, holeScores, byNet) {
        let leastStrokes = null;
        let playerIndexWithLeastStrokes = null;
        for (const [playerIndex, player] of Object.entries(holeScores)) {
            if (player[STROKES]) {
                const strokes = byNet ? this.getPlayerNetStrokes(holeNumber, player[STROKES], this.players[playerIndex].courseHandicap) : player[STROKES];
                if (leastStrokes === null || leastStrokes > strokes) {
                    leastStrokes = strokes;
                    playerIndexWithLeastStrokes = playerIndex;
                }
            }
        }

        // now lets check if the lowest score is scored by multiple players,
        // if so its a draw and nobody will win any money
        for (const [playerIndex, playerScores] of Object.entries(holeScores)) {
            if (
                playerIndex !== playerIndexWithLeastStrokes &&
                playerScores[STROKES]) {
                const strokes = byNet ? this.getPlayerNetStrokes(holeNumber, playerScores[STROKES], this.players[playerIndex].courseHandicap) : playerScores[STROKES];
                if (strokes === leastStrokes) {
                    playerIndexWithLeastStrokes = null;
                    break;
                }
            }
        }
        return playerIndexWithLeastStrokes;
    }

    // static methods
    static getGameSettingsFields(currentGameSettings) {
        if (currentGameSettings && currentGameSettings['SKINS_FORMAT'] === 'CARRY_FORWARD') {
            return ["SKINS_FORMAT", CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS, CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET];
        }
        return ["SKINS_FORMAT", POT_PLAYER_BET_GROSS, POT_PLAYER_BET_NET];
    }

    static getId() {
        return SKINS;
    }

    static requiredFields() {
        return [STROKES];
    }
}

Skins.gameName = "Skins";
Skins.resultComponent = SkinsResult;
Skins.description =
    "Each player contributes a set amount of points which is divided evenly per hole. Lowest outright score on each hole wins the points allocated. Only holes won outright are paid. Net score uses handicap adjusted scores.";

export default Skins;
