import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, withStyles, Box } from "@material-ui/core";
import CenteredCircularProgress from "../../components/utils/CenteredCircularProgress";
import WinnerIcon from "@material-ui/icons/EmojiEvents";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { STABLEFORD } from "Games/GamesManager";
import { IS_NASSAU } from "Games/FieldTypes";
import { hasChangedSpecificProps } from 'utils/reactHelpers';
import StrokeplayBetTable from "components/PlayRound/StrokeplayBetTable";

const classes = (theme) => ({
    playerStatsBox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});

class StablefordResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameInst: null,
        };
    }

    runGameLogicSetToState = () => {
        const gameInst = GamesManager.initGame(STABLEFORD, {
            scores: this.props.scores,
            players: this.props.players,
            golfCourse: this.props.golfCourse,
            gamesSettings: this.props.gamesSettings
        });
        gameInst.calculateLeaderboard();
        gameInst.calculateBetResults();
        this.setState({
            gameInst: gameInst,
        });
    };

    componentDidMount() {
        if (this.props.scores && this.props.players && this.props.golfCourse && this.props.gamesSettings) {
            this.runGameLogicSetToState();
        }
    }

    componentDidUpdate(prevProps) {
        if (hasChangedSpecificProps(this.props, prevProps, ['scores', 'players', 'golfCourse', 'gamesSettings'])) {
            this.runGameLogicSetToState();
        }
    }

    render() {
        console.log("StablefordResult rendering");
        if (!this.state.gameInst) {
            return <CenteredCircularProgress />;
        }
        const stableford = this.state.gameInst;
        let leaderBoardArray = [];
        leaderBoardArray.push(["Name", "Front Points", "Back Points", "Points"]);
        if (stableford.leaderboard) {
            stableford.leaderboard.forEach((playerIndexWithPoints) => {
                leaderBoardArray.push([stableford.players[playerIndexWithPoints.playerIndex].name, playerIndexWithPoints.frontPoints, playerIndexWithPoints.backPoints, playerIndexWithPoints.totalPoints]);
            });
        }
        const gameStatus = stableford.getFinalStatus();
        const isNassau = stableford.getGameSetting(IS_NASSAU);

        return (
            <>
                <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />{" "}
                <Typography color="textPrimary">
                    <WinnerIcon /> {gameStatus}
                </Typography>
                <Box mt={2} mb={1}>
                    <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                        Bets</Box>
                    </Typography>
                    <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                        Nassau: {isNassau ? 'On' : 'Off'}</Box>
                    </Typography>
                    <Box my={1}>
                        <StrokeplayBetTable strokeplayInstance={stableford} isNassau={isNassau} isNetTable />
                    </Box>
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
        gamesSettings: state.activeRound.payload.gamesSettings
    };
};

export default connect(mapStateToProps)(withStyles(classes)(StablefordResult));
