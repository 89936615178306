import firebase from "firebase/app";
// Required for side effects
import "firebase/firestore";

// Get the Firebase config from the auto generated file.
var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Instantiate a Firebase app
export const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
if (process.env.NODE_ENV === "development") {
    console.log("localhost detected!");
    db.settings({
        host: "localhost:8081",
        ssl: false,
    });
}
// db.enablePersistence()
//     .then(() => {})
//     .catch(err => {
//         if (err.code == "failed-precondition") {
//             console.error("Multiple tabs open, persistence can only be enabled in one tab at a a time");
//         } else if (err.code == "unimplemented") {
//             console.error("The current browser does not support all of the features required to enable persistence");
//         }
//     });

export { db };