import React from "react";
import { Grid, Fab } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

const Navigation = ({ onNextClick, onPreviousClick, nextDisabled, previousDisabled }) => {
    console.log("Navigation component rendered");
    return (
        <Grid container justify="space-around">
            <Fab color="primary" onClick={onPreviousClick} disabled={previousDisabled ? true : false}>
                <NavigateBeforeIcon />
            </Fab>
            <Fab disabled={nextDisabled ? true : false} color="primary" onClick={onNextClick}>
                <NavigateNextIcon />
            </Fab>
        </Grid>
    );
};

export default Navigation;