import Game from "./Game";
import { BACK_NINE_BET, FRONT_NINE_BET, HAS_BUNKER_BONUS_POINT, IS_NASSAU, OVERALL_BET, PUTTS, STROKES } from "./FieldTypes";
import PuttingResult from "containers/GameResult/PuttingResult";
import { PUTTING } from "./GamesManager";
import currency from 'currency.js';

class Putting extends Game {

    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: Putting.gameName,
            description: Putting.description,
            resultComponent: Putting.resultComponent,
            // holePlayerInfoComponent: StablefordInfo
        };
        super(superOptions);
    }

    runGame() {
        this.players.forEach(player => {
            player.overallPoints = 0;
            player.frontPoints = 0;
            player.backPoints = 0;
        });

        for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
            const isFrontHole = holeNumber <= 9;
            for (const [playerIndex, playerScores] of Object.entries(holeScores)) {
                if (playerScores[PUTTS] == null || !playerScores[STROKES]) {
                    continue;
                }
                const putts = parseInt(playerScores[PUTTS]);
                const didScoreInStableford = this.didScoreInStableford(holeNumber, playerIndex, playerScores[STROKES]);
                if (putts === 0 && didScoreInStableford) {
                    this.addPointsForPlayer(playerIndex, 2, isFrontHole);
                }
                else if (putts === 1 && didScoreInStableford) {
                    this.addPointsForPlayer(playerIndex, 1, isFrontHole);
                }
                else if (putts === 3) {
                    this.players.forEach((player, index) => {
                        if (index != playerIndex) {
                            this.addPointsForPlayer(index, 1, isFrontHole);
                        }
                    });
                }
                else if (putts > 3) {
                    const extraPutts = putts - 3;
                    this.players.forEach((player, index) => {
                        if (index != playerIndex) {
                            this.addPointsForPlayer(index, extraPutts, isFrontHole);
                        }
                    });
                }
                if (playerScores[HAS_BUNKER_BONUS_POINT]) {
                    this.addPointsForPlayer(playerIndex, 1, isFrontHole);
                }
            }
        }
    }

    addPointsForPlayer(playerIndex, pointsToAdd, isFrontHole) {
        // lets add to overall points and then depending on holeNumber will add to front and back points
        this.players[playerIndex].overallPoints += pointsToAdd;
        if (isFrontHole) {
            this.players[playerIndex].frontPoints += pointsToAdd;

        }
        else {
            this.players[playerIndex].backPoints += pointsToAdd;

        }
    }

    getNassauResults(holesGroup) {
        if (['BACK_NINE', 'FRONT_NINE', 'OVERALL'].includes(holesGroup)) {
            let maxPoints = 0;
            if (holesGroup === 'FRONT_NINE') {
                maxPoints = Math.max(...this.players.map(player => player.frontPoints));
            }
            else if (holesGroup === 'BACK_NINE') {
                maxPoints = Math.max(...this.players.map(player => player.backPoints));

            }
            else {
                maxPoints = Math.max(...this.players.map(player => player.overallPoints));
            }
            // now we have max points get all the players indexes who have max points
            let playersIndexesMaxPoints = [];
            this.players.forEach((player, playerIndex) => {
                if (holesGroup === 'FRONT_NINE') {
                    if (player.frontPoints === maxPoints) {
                        playersIndexesMaxPoints.push(playerIndex);
                    }
                }
                else if (holesGroup === 'BACK_NINE') {
                    if (player.backPoints === maxPoints) {
                        playersIndexesMaxPoints.push(playerIndex);
                    }

                }
                else {
                    if (player.overallPoints === maxPoints) {
                        playersIndexesMaxPoints.push(playerIndex);
                    }
                }
            });
            return playersIndexesMaxPoints;

        }
        return [];
    }

    calculateBetResults() {
        this.players.forEach(player => {
            player.overallBetWon = 0;
            player.frontBetWon = 0;
            player.backBetWon = 0;
            player.totalBetWon = 0;
        });
        // is nassau then calculate bet result for all three hole groups otherwise only overall
        if (this.getGameSetting(IS_NASSAU)) {

            const playersIndexesWithMaxPointsFrontNine = this.getNassauResults('FRONT_NINE');
            const playersIndexesWithMaxPointsBackNine = this.getNassauResults('BACK_NINE');
            let frontNineBet = this.getGameSetting(FRONT_NINE_BET);
            let backNineBet = this.getGameSetting(BACK_NINE_BET);

            if (frontNineBet) {
                if (playersIndexesWithMaxPointsFrontNine.length > 0) {
                    const moneyToDistribute = currency(frontNineBet).distribute(playersIndexesWithMaxPointsFrontNine.length);
                    playersIndexesWithMaxPointsFrontNine.forEach((playerIndex, index) => {
                        this.players[playerIndex].frontBetWon = moneyToDistribute[index].toString();
                        this.players[playerIndex].totalBetWon = moneyToDistribute[index].add(this.players[playerIndex].totalBetWon).toString();
                    });
                }

            }
            if (backNineBet) {
                if (playersIndexesWithMaxPointsBackNine.length > 0) {
                    const moneyToDistribute = currency(backNineBet).distribute(playersIndexesWithMaxPointsBackNine.length);
                    playersIndexesWithMaxPointsBackNine.forEach((playerIndex, index) => {
                        this.players[playerIndex].backBetWon = moneyToDistribute[index].toString();
                        this.players[playerIndex].totalBetWon = moneyToDistribute[index].add(this.players[playerIndex].totalBetWon).toString();
                    });
                }
            }

        }

        let overallBet = this.getGameSetting(OVERALL_BET);
        const playersIndexesWithMaxPointsOverall = this.getNassauResults('OVERALL');
        if (overallBet) {
            if (playersIndexesWithMaxPointsOverall.length > 0) {
                const moneyToDistribute = currency(overallBet).distribute(playersIndexesWithMaxPointsOverall.length);
                playersIndexesWithMaxPointsOverall.forEach((playerIndex, index) => {
                    this.players[playerIndex].overallBetWon = moneyToDistribute[index].toString();
                    if (this.getGameSetting(IS_NASSAU)) {
                        this.players[playerIndex].totalBetWon = moneyToDistribute[index].add(this.players[playerIndex].totalBetWon).toString();
                    }
                });
            }
        }

    }


    didScoreInStableford(holeNumber, playerIndex, strokes) {
        if (strokes && strokes <= (this.getAdjustedPar(holeNumber, this.players[playerIndex]) + 1)) {
            return true;
        }
        return false;
    }

    static getId() {
        return PUTTING;
    }

    static getGameSettingsFields(currentGameSettings) {
        if (currentGameSettings &&
            currentGameSettings[IS_NASSAU]) {
            return [IS_NASSAU, FRONT_NINE_BET, BACK_NINE_BET, OVERALL_BET];
        }
        return [IS_NASSAU, OVERALL_BET];
    }

    static requiredFields() {
        return [PUTTS, HAS_BUNKER_BONUS_POINT];
    }
}

Putting.gameName = "Putting";
Putting.resultComponent = PuttingResult;
Putting.description =
    "Players' points are based on the putts";

export default Putting;