import React from 'react';
import 
{
    makeStyles,
    Paper,
    Typography,
    Button
} from "@material-ui/core"
import { Link } from'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2)
    }
}));

export default function NewGameCard({ golfCourse, onNewGameClick }) {
    const classes = useStyles();
    return (
        <>
                <Paper className={classes.root}>
                    <div>
                        <Typography variant="h6">
                            {golfCourse.name ? golfCourse.name : "<No Golf Course>"}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            {golfCourse.location}
                        </Typography>
                    </div>
                    <Button size="medium" color="primary" onClick={() => onNewGameClick(golfCourse)} to="setup-game" component={Link}>
                        New Game
                    </Button>
                </Paper>
        </>
    );
}