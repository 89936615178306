import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Typography, Box } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { SKINS } from "Games/GamesManager";

const classes = (theme) => ({
    playerStatsBox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});

class SkinsResult extends Component {
    runGameLogic() {
        const gameInst = GamesManager.initGame(SKINS);
        gameInst.scores = this.props.scores;
        gameInst.players = this.props.players;
        gameInst.golfCourse = this.props.golfCourse;
        gameInst.gamesSettings = this.props.gamesSettings;
        gameInst.runGame();
        return gameInst;
    }

    render() {
        console.log("SkinsResult rendering");

        const skinGameInst = this.runGameLogic();

        let leaderBoardArray = [];
        leaderBoardArray.push(["Name", "Money Won"]);

        let netLeaderboard = [];
        netLeaderboard.push(["Name", "Money Won"]);

        skinGameInst.players.forEach((player) => {
            if (skinGameInst.getFormat() === "POT") {
                leaderBoardArray.push([player.name, player.potMoneyWonGross]);
                netLeaderboard.push([player.name, player.potMoneyWonNet]);
            }
            else {
                leaderBoardArray.push([player.name, player.cfMoneyWonGross]);
                netLeaderboard.push([player.name, player.cfMoneyWonNet]);
            }
        });

        const formatIsPot = skinGameInst.getFormat() === "POT";

        let potTotalGross, potTotalNet = 0;

        let perHoleBetGross, perHoleBetNet = 0;

        if (formatIsPot) {
            [potTotalGross, potTotalNet] = skinGameInst.getPotTotalForGrossAndNet();
        }
        else {
            [perHoleBetGross, perHoleBetNet] = skinGameInst.getPerHoleBetForGrossAndNet();
        }

        return (
            <>
                <Box my={1}>
                    <Typography variant="caption">
                        <b>
                            Format - {formatIsPot ? 'Pot' : 'Carry Forward'}
                            <br />
                            {formatIsPot ?
                                "Pot Total for Gross: " + potTotalGross + ' | ' + "Pot Total for Net: " + potTotalNet
                                : "Per Hole Bet for Gross: " + perHoleBetGross + ' | ' + "Per Hole Bet for Net: " + perHoleBetNet
                            }
                        </b>
                    </Typography>
                </Box>
                <Box my={1}>
                    <Box fontWeight="fontWeightBold"><Typography>Gross</Typography></Box>
                    <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />
                </Box>
                <Box my={1}>
                    <Box fontWeight="fontWeightBold"><Typography>Net</Typography></Box>
                    <LeaderboardTable noPaper rowsWithColumns={netLeaderboard} />
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
        gamesSettings: state.activeRound.payload.gamesSettings,
    };
};

export default connect(mapStateToProps)(withStyles(classes)(SkinsResult));
