import {
    FETCH_FAVOURITE_PLAYERS_SUCCESS,
    FETCH_FAVOURITE_PLAYERS_BEGIN,
    FETCH_FAVOURITE_PLAYERS_FAILURE,
    SET_FAVOURITE_PLAYERS
} from "actions/actionTypes";

const initState = {
    payload: [],
    isLoading: true
};

export default function favouritePlayerReducer(state = initState, action) {
    switch (action.type) {
        case FETCH_FAVOURITE_PLAYERS_SUCCESS:
            return { ...state, payload: action.payload ? action.payload : initState.payload, isLoading: false };
        case FETCH_FAVOURITE_PLAYERS_BEGIN:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_FAVOURITE_PLAYERS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case SET_FAVOURITE_PLAYERS:
            return {
                ...state,
                payload: action.payload
            }
        default:
        // do nothing
    }
    return state;
};