import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Box,
    Button,
    useTheme,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Radio,
    TextField,
} from "@material-ui/core";
import { createNewround } from "../../actions/roundActions";
import { previousSetupView } from "actions/newRoundActions";
import { withRouter } from "react-router-dom";
import Navigation from "./Navigation";
import CenteredCircularProgress from "components/utils/CenteredCircularProgress";
import RoundSetupHeader from "./RoundSetupHeader";
import { calculateCourseHandicap } from "golfFormulas";
import CGFixedTableCell from "components/FixedTableCell";
import HiLoTeams from "./HiLoTeams";
import { HI_LO } from "Games/GamesManager";

const stickyColumnWidthUnit = 16;

const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(3, 1),
    },
    selectTeesTablePaper: {
        overflowX: "auto",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        position: 'relative',
        whiteSpace: "nowrap",
    },
    tableRoot: {
        borderCollapse: 'separate'
    },
}));

const FixedTableCell = (props) => (<CGFixedTableCell cellWidth={stickyColumnWidthUnit} {...props}/>);

function StartRound({ golfCourseDetails, selectedPlayers, startNewRound, history, previousSetupView, currentGameIds, ...props }) {
    const theme = useTheme();

    console.log("Start Round component rendered");

    // ********props*************

    let teeSets = null;
    if (golfCourseDetails.payload && golfCourseDetails.payload.tee_sets) teeSets = golfCourseDetails.payload.tee_sets;

    const styleClasses = useStyles();

    // const [startingHole, setStartingHole] = useState(null);

    const [startingRound, setStartingRound] = useState(false);

    const [playersSelectedTeeIndex, setPlayersSelectedTeeIndex] = useState(() => {
        const iniState = [];
        for (let i = 0; i < selectedPlayers.length; i++) {
            iniState.push(0);
        }
        return iniState;
    });

    const [playersCourseHandicaps, setPlayersCourseHandicaps] = useState(() => {
        const iniState = [];
        for (let i = 0; i < selectedPlayers.length; i++) {
            iniState.push(0);
        }
        return iniState;
    });

    const [courseHandicapsErrors, setCourseHandicapsErrors] = useState([]);

    const [playersSelectedTeams, setPlayersSelectedTeams] = useState(() => {
        const initState = [];
        for (let i = 0; i < selectedPlayers.length; i++) {
            initState.push(null);
        }
        return initState;
    });

    useEffect(
        function setDefaultSelectedTeeIndexes() {
            if (teeSets) {
                const newArr = [];
                for (let i = 0; i < selectedPlayers.length; i++) {
                    // default is any first teeset
                    newArr.push(0);
                }
                setPlayersSelectedTeeIndex(newArr);
            }
        },
        [selectedPlayers, teeSets]
    );

    useEffect(
        function setDefaultPlayersCourseHandicaps() {
            if (teeSets) {
                const newCourseHandicaps = [];
                const getCoursehandicap = (player, selectedTeeSetIndex) => {
                    if (player.handicapIndex && teeSets[selectedTeeSetIndex]) {
                        const teeSet = teeSets[selectedTeeSetIndex];
                        if (teeSet.slope && teeSet.rating && teeSet.par) {
                            return calculateCourseHandicap(player.handicapIndex, teeSet.slope, teeSet.rating, teeSet.par);
                        }
                    }
                    // 0 handicap if failed to calculate
                    return 0;
                };
                selectedPlayers.forEach((player, playerIndex) => {
                    newCourseHandicaps[playerIndex] = getCoursehandicap(player, 0);
                });
                setPlayersCourseHandicaps(newCourseHandicaps);
            }
        },
        [teeSets, selectedPlayers]
    );

    // useEffect(() => {
    //     const golfCourseDetailsPayload = golfCourseDetails.payload;
    //     if (!golfCourseDetails.isLoading && golfCourseDetailsPayload) {
    //         if (golfCourseDetailsPayload.holes_data[0] && golfCourseDetailsPayload.holes_data[0].hole_number) {
    //             setStartingHole(golfCourseDetailsPayload.holes_data[0].hole_number);
    //         }
    //     }
    // }, [golfCourseDetails]);

    const handleStartRoundClick = () => {
        startNewRound(playersCourseHandicaps, playersSelectedTeeIndex, playersSelectedTeams, history);
        setStartingRound(true);
    };

    const handleRadioOnChange = (playerIndex, teeSetIndex) => {
        const getCoursehandicap = (player, selectedTeeSetIndex) => {
            if (player.handicapIndex && teeSets[selectedTeeSetIndex]) {
                const teeSet = teeSets[selectedTeeSetIndex];
                if (teeSet.slope && teeSet.rating && teeSet.par) {
                    return calculateCourseHandicap(player.handicapIndex, teeSet.slope, teeSet.rating, teeSet.par);
                }
            }
            // 0 handicap if failed to calculate
            return 0;
        };
        playersSelectedTeeIndex[playerIndex] = teeSetIndex;
        validateAndSetCourseHandicap(getCoursehandicap(selectedPlayers[playerIndex], teeSetIndex), playerIndex);
        setPlayersSelectedTeeIndex([...playersSelectedTeeIndex]);
        setPlayersCourseHandicaps([...playersCourseHandicaps]);
    };

    const validateAndSetCourseHandicap = (handicap, playerIndex) => {
        // convert to string, in case number
        handicap = handicap + "";
        // regex will match when an integer(-ve or +ve)
        if (handicap != null && handicap.match(/^-?[0-9]+$/)) {
            courseHandicapsErrors[playerIndex] = false;
            setCourseHandicapsErrors([...courseHandicapsErrors]);
        } else {
            courseHandicapsErrors[playerIndex] = true;
            setCourseHandicapsErrors([...courseHandicapsErrors]);
        }
        playersCourseHandicaps[playerIndex] = handicap;
        setPlayersCourseHandicaps([...playersCourseHandicaps]);
    };

    const handleCourseHandicapOnChange = (event, playerIndex) => {
        const handicap = event.target.value;
        validateAndSetCourseHandicap(handicap, playerIndex);
    };

    const handlePlayerTeamChange = (playerIndex, selectedTeam) => {
        const newPlayersSelectedTeams = [ ...playersSelectedTeams ];
        newPlayersSelectedTeams[playerIndex] = selectedTeam;
        setPlayersSelectedTeams(newPlayersSelectedTeams);
    }

    const checkIfErrors = () => {
        for (let bool of courseHandicapsErrors) {
            if (bool) {
                return true;
            }
        }
        if (currentGameIds.indexOf(HI_LO) !== -1) {
            for(let selectedTeam of playersSelectedTeams) {
                if (!selectedTeam) {
                    return true;
                }
            }
        }
        return false;
    };

    if (golfCourseDetails.isLoading) {
        return <CenteredCircularProgress />;
    }
    return (
        <>
            <RoundSetupHeader />
            <Box className={styleClasses.container} display="flex" flexDirection="column">
                <Typography align="center" variant="subtitle1">
                    Select Tees and confirm Course Handicap
                </Typography>
                <Paper className={styleClasses.selectTeesTablePaper}>
                    <Table size="small" classes={{ root: styleClasses.tableRoot }}>
                        <TableHead>
                            <TableRow>
                                <FixedTableCell positionFromLeft={1}></FixedTableCell>
                                <FixedTableCell positionFromLeft={2}>Course HCP</FixedTableCell>
                                {teeSets.map((item, index) => {
                                    return (
                                        <TableCell key={index} align="right">
                                            {item.name ? item.name : index}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedPlayers.map((player, playerIndex) => (
                                <TableRow key={playerIndex}>
                                    <FixedTableCell positionFromLeft={1} component="th" scope="row">
                                        {player.name}
                                    </FixedTableCell>
                                    <FixedTableCell positionFromLeft={2}>
                                        <TextField
                                            type="number"
                                            error={courseHandicapsErrors[playerIndex]}
                                            margin="dense"
                                            variant="outlined"
                                            value={playersCourseHandicaps[playerIndex]}
                                            onChange={(event) => handleCourseHandicapOnChange(event, playerIndex)}
                                        />
                                    </FixedTableCell>
                                    {teeSets.map((teeSet, teeSetIndex) => {
                                        return (
                                            <TableCell key={teeSetIndex} align="right">
                                                <Radio
                                                    checked={playersSelectedTeeIndex[playerIndex] === teeSetIndex}
                                                    onChange={() => {
                                                        handleRadioOnChange(playerIndex, teeSetIndex);
                                                    }}
                                                />
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>

                { currentGameIds.indexOf(HI_LO) !== -1 && <HiLoTeams selectedPlayers={selectedPlayers} playersSelectedTeams={playersSelectedTeams} onPlayerTeamChange={handlePlayerTeamChange} /> }
                {/* <StartingHoleSelect
                    golfCourseDetails={golfCourseDetails.payload}
                    startingHole={startingHole}
                    changeStartingHole={setStartingHole}
                ></StartingHoleSelect> */}
                <Button
                    disabled={checkIfErrors() || startingRound}
                    style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
                    color="primary"
                    variant="contained"
                    onClick={handleStartRoundClick}
                >
                    Start a Round
                </Button>
                {startingRound ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                        <Navigation nextDisabled onPreviousClick={previousSetupView} />
                    )}
            </Box>
        </>
    );
}

// redux here
const mapStateToProps = (state) => {
    return {
        currentGameIds: state.newRound.selectedGameIds.payload,
        golfCourseDetails: state.newRound.selectedGolfCourse.details,
        selectedPlayers: state.newRound.selectedPlayers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startNewRound: (playersCourseHandicaps, playersSelectedTeeIndexes, playersSelectedTeams, history) =>
            dispatch(createNewround(playersCourseHandicaps, playersSelectedTeeIndexes, playersSelectedTeams, history)),
        previousSetupView: () => dispatch(previousSetupView()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StartRound));
