import React from 'react';
import { FRONT_NINE_BET, BACK_NINE_BET, OVERALL_BET } from 'Games/FieldTypes';
import LeaderboardTable from './LeaderboardTable';

export default function StrokeplayBetTable({ strokeplayInstance, isNassau, isNetTable }) {
    // nassau means all three bets front, back and overall
    let headingRow = null;
    let tableArray = [];
    if (isNassau) {
         headingRow = ['Name', `F($${strokeplayInstance.getGameSetting(FRONT_NINE_BET)})`,`B($${strokeplayInstance.getGameSetting(BACK_NINE_BET)})`,`O($${strokeplayInstance.getGameSetting(OVERALL_BET)})`, 'Total'];
    }
    else {
         headingRow = ['Name', `O($${strokeplayInstance.getGameSetting(OVERALL_BET)}})`];
    }
    tableArray.push(headingRow);
    strokeplayInstance.betsWonToPlayers.forEach((betsWonWithPlayerIndex) => {
        let row = [];
        if (isNetTable) {
            if (isNassau) {
                row = [strokeplayInstance.players[betsWonWithPlayerIndex.playerIndex].name, '', '', '', ''];
                if(betsWonWithPlayerIndex.netFrontNineBetWon) {
                    row[1] = betsWonWithPlayerIndex.netFrontNineBetWon;
                }
                if (betsWonWithPlayerIndex.netBackNineBetWon) {
                    row[2] = betsWonWithPlayerIndex.netBackNineBetWon;
                }      
                if (betsWonWithPlayerIndex.netOverallBetWon) {
                    row[3] = betsWonWithPlayerIndex.netOverallBetWon;
                }      
                if (betsWonWithPlayerIndex.netTotalBetWon) {
                    row[4] = betsWonWithPlayerIndex.netTotalBetWon;
                }     
            }
            else {
                row = [strokeplayInstance.players[betsWonWithPlayerIndex.playerIndex].name, ''];
                if (betsWonWithPlayerIndex.netTotalBetWon) {
                    row[1] = betsWonWithPlayerIndex.netTotalBetWon;
                }     
            }
        }
        else {
            if (isNassau) {
                row = [strokeplayInstance.players[betsWonWithPlayerIndex.playerIndex].name, '', '', '', ''];
                if(betsWonWithPlayerIndex.grossFrontNineBetWon) {
                    row[1] = betsWonWithPlayerIndex.grossFrontNineBetWon;
                }
                if (betsWonWithPlayerIndex.grossBackNineBetWon) {
                    row[2] = betsWonWithPlayerIndex.grossBackNineBetWon;
                }      
                if (betsWonWithPlayerIndex.grossOverallBetWon) {
                    row[3] = betsWonWithPlayerIndex.grossOverallBetWon;
                }      
                if (betsWonWithPlayerIndex.grossTotalBetWon) {
                    row[4] = betsWonWithPlayerIndex.grossTotalBetWon;
                }     
            }
            else {
                row = [strokeplayInstance.players[betsWonWithPlayerIndex.playerIndex].name, ''];
                if (betsWonWithPlayerIndex.grossTotalBetWon) {
                    row[1] = betsWonWithPlayerIndex.grossTotalBetWon;
                }     
            }
        }
        tableArray.push(row);
    });
   return <LeaderboardTable noPaper rowsWithColumns={tableArray} />
}
