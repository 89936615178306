import { MenuItem, Select } from '@material-ui/core';
import { updateHoleSettings } from 'actions/activeRoundActions';
import { OOSLER_PLAYER_INDEX } from 'Games/FieldTypes';
import React from 'react';
import { connect } from "react-redux";

const OoslerPlayerSelect = ({ players, roundId, holeNumber, holesSettings, updateHoleSettings }) => {
    
    let value = "";
    if (holesSettings[holeNumber] && holesSettings[holeNumber][OOSLER_PLAYER_INDEX] != null) {
        value = holesSettings[holeNumber][OOSLER_PLAYER_INDEX];
    }

    const handleChange = event => {
        let value = event.target.value;
        if (value === "") {
            value = null;
        }
        updateHoleSettings(roundId, holeNumber, holesSettings, OOSLER_PLAYER_INDEX, parseInt(value));
    }

    return <Select value={value} onChange={handleChange} displayEmpty>
        <MenuItem value="">Select Player</MenuItem>
        {players.map((player, playerIndex) => <MenuItem key={playerIndex} value={playerIndex}>{player.name}</MenuItem>)}
    </Select>
}

// redux here
const mapStateToProps = state => {
    return {
        players: state.activeRound.payload.players,
        roundId: state.activeRound.payload.id,
        holesSettings: state.activeRound.payload.holesSettings
    };
};

const mapDispatchToProps = {
    updateHoleSettings: (roundId, holeNumber, currentHoleSettings, fieldKey, value) => updateHoleSettings(roundId, holeNumber, currentHoleSettings, fieldKey, value)
};

export default connect(mapStateToProps, mapDispatchToProps)(OoslerPlayerSelect);