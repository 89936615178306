import { firebaseApp } from "../firebase/FirebaseApp";
import "firebase/auth";
import { usersCollectionRef } from "../firebase/collectionRefs";
import AppNotifier from "../AppNotifier";
import {
    FETCH_USER,
    SET_SIGNING_OUT_USER_TRUE,
    SET_SIGNING_OUT_USER_FALSE,
    FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
    FETCH_RECENT_GOLF_COURSES_SUCCESS,
    SIGNOUT_USER
} from "actions/actionTypes";

export const fetchUser = () => dispatch => {
    firebaseApp.auth().onAuthStateChanged(user => {
        if (user) {
            dispatch({
                type: FETCH_USER,
                payload: user
            });
        } else {
            dispatch({
                type: FETCH_USER,
            });
        }
    });
};

export const signOutUser = browserHistory => dispatch => {
    dispatch({
        type: SET_SIGNING_OUT_USER_TRUE
    });
    firebaseApp
        .auth()
        .signOut()
        .then(() => {
            dispatch({
                type: SIGNOUT_USER
            });
            browserHistory.push("/");
        })
        .catch(err => {
            console.log("Failed to signout the user. Reason: ", err);
            //  we failed, we are not trying state is not signing out anymore
            dispatch({
                type: SET_SIGNING_OUT_USER_FALSE
            });
            browserHistory.push("/dashboard");
        });
};

export const fetchCustomUserData = user => dispatch => {
    if (user) {
        usersCollectionRef
            .doc(user.uid)
            .get()
            .then(user => {
                if (user.exists) {
                    // if (user.data().currentCourse)
                    //     dispatch({
                    //         type: SET_SELECTED_GOLF_COURSE",
                    //         payload: user.data().currentCourse
                    //     });
                    // else
                    //     dispatch({
                    //         type: SET_SELECTED_GOLF_COURSE_IS_LOADING",
                    //         payload: false
                    //     });
                    if (user.data().currentGameIds)
                        dispatch({
                            type: FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
                            payload: user.data().currentGameIds
                        });
                    else
                        dispatch({
                            type: FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
                        });
                    if (user.data().recentGolfCourses && user.data().recentGolfCourses.length > 0) {
                        // dispatch({
                        //     type: FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS,
                        //     payload: user.data().recentGolfCourses[0]
                        // });
                        dispatch({
                            type: FETCH_RECENT_GOLF_COURSES_SUCCESS,
                            payload: user.data().recentGolfCourses
                        });
                    } else
                        dispatch({
                            type: FETCH_RECENT_GOLF_COURSES_SUCCESS
                        });
                }
                // this means that user doc hasn't been put into firestore yet but we have successfully tried loading
                else {
                    dispatch({
                        type: FETCH_RECENT_GOLF_COURSES_SUCCESS
                    });
                    dispatch({
                        type: FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS
                    });
                }
            })
            .catch(err => {
                AppNotifier.notify("Failed to get user's profile", { variant: "error" });
                console.error("Failed to get the intial logged in user data. Error: ", err);
            });
    }
};
