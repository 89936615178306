import React from "react";
import { useTheme } from "@material-ui/core";

function PuttingInput({ initialValue, isThereStrokes, strokesValue, onValueChange }) {
    console.log("PuttingInput component rendering");

    const theme = useTheme();

    // if strokes value is null that means strokes are not there no validation
    // if strokes value is there putts need to be less than strokes value so validate

    // const [intrinsicValue, setIntrinsicValue] = useState("");

    // const [error, setError] = useState(null);

    // useEffect(() => {
    //     if (parseInt(value)) {
    //         setIntrinsicValue(value)
    //     }
    // }, [value])

    // const isValueLessThanStrokes = (value) => {
    //     return (newValue < strokesValue);
    // }

    // const handleSelectChange = (evt) => {
    //     setIntrinsicValue(evt.target.value);
    //     if (evt.target.value === "") {
    //         return;
    //     }
    //     const newValue = parseInt(evt.target.value);
    //     if (newValue && isValueLessThanStrokes(newValue)) {
    //         setError(null);
    //         onValueChange(newValue);
    //     }
    //     else {
    //         setError("Putts need to be less than Strokes");
    //     }
    //     onValueChange(evt.target.value);
    // };

    const handleSelectChange = (evt) => {
        onValueChange(evt.target.value);
    };

    const optionsTemplate = [];

    optionsTemplate.push(<option key="" value="">

    </option>);

    if (strokesValue) {
        for (let i = 0; i < strokesValue; i++) {
            optionsTemplate.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
    }

    if (initialValue == null || initialValue >= strokesValue) {
        initialValue = "";
    }


    return (
        <React.Fragment>
            <select style={{ minWidth: theme.spacing(6), minHeight: theme.spacing(4) }} value={initialValue} onChange={handleSelectChange}>
                {optionsTemplate}
            </select>
        </React.Fragment>
    );
}

export default PuttingInput;