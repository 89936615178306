import { UPDATE_RECENT_GOLF_COURSES_BEGIN, UPDATE_RECENT_GOLF_COURSES_SUCCESS } from "actions/actionTypes";
import { usersCollectionRef } from "../firebase/collectionRefs";
import AppNotifier from "../AppNotifier";

export const addNewCourseToRecentCourses = (user, newCourse, recentGolfCourses) => dispatch => {
    if (user && newCourse && recentGolfCourses) {
        for (let gc of recentGolfCourses) {
            if (gc.id === newCourse.id) {
                AppNotifier.notify("Golf Course already in recents", { variant: "info" });
                return;
            }
        }
        dispatch({
            type: UPDATE_RECENT_GOLF_COURSES_BEGIN
        });
        if (recentGolfCourses.length >= 5) {
            // we only keep 5 recent courses
            // remove the last element
            recentGolfCourses.pop();
        }

        // add element to front of array
        recentGolfCourses.unshift(newCourse);
        usersCollectionRef
            .doc(user.uid)
            .set({ recentGolfCourses: recentGolfCourses }, { merge: true })
            .then(() => {
                dispatch({
                    type: UPDATE_RECENT_GOLF_COURSES_SUCCESS,
                    payload: recentGolfCourses
                });
            })
            .catch(err => {
                AppNotifier.notify("Failed to update data", { variant: "error" });
                console.error("Failed to add new course to recent golf courses. Error: ", err);
            });
    }
};
