import React from "react";
import { useTheme } from "@material-ui/core";

function StrokesInput({ initialValue, onValueChange }) {
    console.log("StrokesInput component rendering");

    const theme = useTheme();

    // const [state, setState] = useState({
    //     value: 0,
    //     error: null
    // });

    // useEffect(() => {
    //     setState({ ...state, value: getDefaultValue() });
    // }, []);

    // const getDefaultValue = () => {
    //     if (initialValue && Number.isInteger(initialValue) && initialValue > 0) {
    //         return initialValue;
    //     }
    //     return 0;
    // }

    // const validateInput = value => {
    //     if (isNaN(parseFloat(value)))
    //         return false
    //     if (!Number.isInteger(parseFloat(value)) && value < 0) {
    //         return false;
    //     }
    //     return true;
    // }

    // const handleInputChange = evt => {
    //     const newValue = evt.target.value;
    //     let error = null;
    //     if (!validateInput(newValue)) {
    //         error = 'The input should be a postive whole number';
    //     }
    //     setState({...state, value: newValue, error: error});
    // }

    const handleSelectChange = (evt) => {
        onValueChange(evt.target.value);
    };

    const optionsTemplate = [];

    for (let i = 0; i < 16; i++) {
        optionsTemplate.push(
            <option key={i} value={i}>
                {i}
            </option>
        );
    }

    return (
        <React.Fragment>
            {/* <Input
            defaultValue={state.value}
          type="number"
          onChange={handleInputChange}
        /> */}

            <select style={{ minWidth: theme.spacing(6), minHeight: theme.spacing(4) }} value={initialValue ? initialValue : 0} onChange={handleSelectChange}>
                {optionsTemplate}
            </select>
        </React.Fragment>
    );
}

export default StrokesInput;
