import {
    FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
    FETCH_NEW_ROUND_SELECTED_GAME_IDS_BEGIN,
    FETCH_NEW_ROUND_SELECTED_GAME_IDS_FAILURE,
    SET_NEW_ROUND_SELECTED_PLAYERS,
    FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS,
    FETCH_SELECTED_GOLF_COURSE_INFO_BEGIN,
    FETCH_SELECTED_GOLF_COURSE_INFO_FAILURE,
    SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS,
    UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN,
    UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
    ADD_NEW_ROUND_GAME_SETTING,
    NEXT_SETUP_VIEW_NUMBER,
    PREVIOUS_SETUP_VIEW_NUMBER,
    FETCH_RECENT_GOLF_COURSES_SUCCESS,
    RESET_SETUP_VIEW_NUMBER,
    FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS,
    FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN,
} from "actions/actionTypes";

const initState = {
    selectedGameIds: {
        payload: [],
        isLoading: true,
    },
    setupViewNumber: 1,
    gamesSettings: {},
    selectedPlayers: [],
    selectedGolfCourse: {
        // 'info' will contain basic course info
        info: {
            payload: null,
            isLoading: true,
        },
        // 'details' will contain detailed course information
        details: {
            payload: null,
            isLoading: true,
        },
    },
};

const newRoundReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS:
            return {
                ...state,
                selectedGameIds: {
                    payload: action.payload ? action.payload : initState.selectedGameIds.payload,
                    isLoading: false,
                },
            };
        case FETCH_NEW_ROUND_SELECTED_GAME_IDS_BEGIN:
            return { ...state, selectedGameIds: { ...state.selectedGameIds, isLoading: true } };
        case FETCH_NEW_ROUND_SELECTED_GAME_IDS_FAILURE:
            return { ...state, selectedGameIds: { ...state.selectedGameIds, isLoading: false } };
        case UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN:
            return { ...state, selectedGameIds: { ...state.selectedGameIds, isLoading: true } };
        case UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS:
            return {
                ...state,
                selectedGameIds: {
                    payload: action.payload ? [...action.payload] : initState.selectedGameIds.payload,
                    isLoading: false,
                },
            };

        case SET_NEW_ROUND_SELECTED_PLAYERS:
            if (Array.isArray(action.payload)) {
                return { ...state, selectedPlayers: [...action.payload] };
            }
            break;
        case FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    info: {
                        ...state.selectedGolfCourse.info,
                        payload: action.payload ? action.payload : initState.selectedGolfCourse.info.payload,
                        isLoading: false,
                    },
                },
            };
        case FETCH_SELECTED_GOLF_COURSE_INFO_BEGIN:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    info: { ...state.selectedGolfCourse.info, isLoading: true },
                },
            };
        case FETCH_SELECTED_GOLF_COURSE_INFO_FAILURE:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    info: { ...state.selectedGolfCourse.info, isLoading: true },
                },
            };
        case FETCH_RECENT_GOLF_COURSES_SUCCESS:
            if (action.payload)
                return {
                    ...state,
                    selectedGolfCourse: {
                        ...state.selectedGolfCourse,
                        info: { payload: action.payload[0], isLoading: false },
                    },
                };
            break;
        case SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    details: {
                        ...state.selectedGolfCourse.details,
                        payload: action.payload ? { ...action.payload } : initState.selectedGolfCourse.details.payload,
                        isLoading: false,
                    },
                },
            };
        case FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    details: {
                        ...state.selectedGolfCourse.details,
                        isLoading: true,
                    },
                },
            };
        case FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS:
            return {
                ...state,
                selectedGolfCourse: {
                    ...state.selectedGolfCourse,
                    details: {
                        payload: action.payload,
                        isLoading: false,
                    },
                },
            };
        case ADD_NEW_ROUND_GAME_SETTING:
            let oldGameSettings = null;
            if (state.gamesSettings && state.gamesSettings[action.payload.gameId]) {
                oldGameSettings = state.gamesSettings[action.payload.gameId];
            }
            return {
                ...state,
                gamesSettings: {
                    ...state.gamesSettings,
                    [action.payload.gameId]: oldGameSettings ? { ...oldGameSettings, [action.payload.fieldKey]: action.payload.value }
                        : { [action.payload.fieldKey]: action.payload.value },
                },
            };
        case NEXT_SETUP_VIEW_NUMBER:
            if (state.setupViewNumber < 3) {
                return { ...state, setupViewNumber: state.setupViewNumber + 1 };
            }
            break;
        case PREVIOUS_SETUP_VIEW_NUMBER:
            if (state.setupViewNumber > 1) {
                return { ...state, setupViewNumber: state.setupViewNumber - 1 };
            }
            break;
        case RESET_SETUP_VIEW_NUMBER:
            return { ...state, setupViewNumber: initState.setupViewNumber };
        default:
        // do nothing
    }
    return state;
};

export default newRoundReducer;
