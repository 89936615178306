import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup";
import { Formik } from "formik";

import ProfileImageCover from "../../resources/images/golf-profile-cover.png";
import AuthProviderForm from "./AuthProviderForm";
import NonAuthProviderForm from "./NonAuthProviderForm";
import AppNotifier from "../../AppNotifier";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(2)
    },
    avatar: {
        position: "relative",
        // position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
        top: "-15px",
        margin: "0 auto auto auto",
        color: "#fff",
        backgroundColor: deepOrange[500]
    }
}));

// const shallowCompare = (obj1, obj2) =>
//   Object.keys(obj1).length === Object.keys(obj2).length &&
//   Object.keys(obj1).every(key =>
//     obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
//   );

const authProviderValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
});

const nonAuthProviderValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    password: Yup.string()
        // min 8 chars, atleast 1 number, atleast 1 uppercase, atleast 1 lowercase
        .matches(
            new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
            "Password must be min 8 characters, and have 1 Uppercase, 1 Number and 1 Lowercase"
        ),
    confirmPassword: Yup.string().oneOf([Yup.ref("password")], "Password doesn't match")
});

function Profile(props) {
    console.log("Profile component rendered");

    const classes = useStyles();

    let authProvider = null;
    props.user.providerData.forEach(function(profile, i) {
        if (i === 0) authProvider = profile.providerId;
        else authProvider = authProvider + ", " + profile.providerId;
    });

    const initialValuesAuthProvider = {
        name: props.user.displayName,
        email: props.user.email,
        authProvider: authProvider
    };

    const initialValuesNonAuthProvider = {
        name: props.user.displayName,
        email: props.user.email,
        password: "",
        confirmPassword: ""
    };

    const handleAuthProviderOnSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        props.user
            .updateProfile({ displayName: values.name })
            .then(() => {
                AppNotifier.notify("Successfully updated your profile", { variant: "success" });
                setSubmitting(false);
            })
            .catch(() => {
                AppNotifier.notify("Failed to update your profile", { variant: "error" });
                resetForm(); // it will invoke setSubmitting(false) also
            });
    };

    const handleNonAuthProviderOnSubmit = (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (values.password.length < 1) {
            props.user
            .updateProfile({ displayName: values.name })
            .then(() => {
                AppNotifier.notify("Successfully updated your profile", { variant: "success" });
                setSubmitting(false);
            })
            .catch(() => {
                AppNotifier.notify("Failed to update your profile", { variant: "error" });
                resetForm(); // it will invoke setSubmitting(false) also
            });
        }
        else {
            Promise.all([
                props.user.updateProfile({ displayName: values.name }),
                props.user.updatePassword(values.password)
            ])
                .then(() => {
                    AppNotifier.notify("Successfully updated your profile", { variant: "success" });
                    setSubmitting(false);
                })
                .catch(() => {
                    AppNotifier.notify("Failed to update your profile", { variant: "error" });
                    resetForm(); // it will invoke setSubmitting(false) also
                });
        }
        
    };

    return (
        <React.Fragment>
            {props.user && (
                <Paper className={classes.paper}>
                    <CardMedia
                        component="img"
                        alt="Cover Image"
                        height="140"
                        image={ProfileImageCover}
                        title="Cover Image"
                    />
                    <Avatar className={classes.avatar}>RS</Avatar>
                    {authProvider !== "password" ? (
                        <Formik
                            initialValues={initialValuesAuthProvider}
                            onSubmit={handleAuthProviderOnSubmit}
                            validationSchema={authProviderValidationSchema}
                            render={props => <AuthProviderForm {...props} />}
                        />
                    ) : (
                        <Formik
                            initialValues={initialValuesNonAuthProvider}
                            onSubmit={handleNonAuthProviderOnSubmit}
                            validationSchema={nonAuthProviderValidationSchema}
                            render={props => <NonAuthProviderForm {...props} />}
                        />
                    )}
                </Paper>
            )}
        </React.Fragment>
    );
}

// redux here
const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
