import React from "react";
import HoleInfoPaper from "components/HoleInfoPaper";
import { withStyles, Box, Button, Dialog, DialogTitle, Typography, DialogContent } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNextSharp";
import HoleInputTable from "./HoleInputTable";
import { hasChangedSpecificProps } from "utils/reactHelpers";
import GamesManager from "Games/GamesManager";
import { connect } from "react-redux";
import { getField } from "Games/FieldTypes";

const classes = (theme) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(0, 1),
    },
});

class HolePanel extends React.PureComponent {
    /**
     * Props -
     * onNextHole, callback for navigating to next hole
     * onPreviousHole, callback for navigating to previous hole
     * holeData, hole data object
     * holeIndex, tab panel index
     */

    constructor(props) {
        super(props);
        this.state = {
            isNextHoleButtonVisible: false,
            miscFieldsDialogOpen: false
        };
        this.updateNextHoleButtonState = this.updateNextHoleButtonState.bind(this);
        this.handlePreviousButtonClick = this.handlePreviousButtonClick.bind(this);
        this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
        this.handleNextHoleButtonVisible = this.handleNextHoleButtonVisible.bind(this);
        this.handleNextHoleButtonInvisible = this.handleNextHoleButtonInvisible.bind(this);
        this.showMiscGamesSettingsModal = this.showMiscGamesSettingsModal.bind(this);
        this.handleMiscDialogClose = this.handleMiscDialogClose.bind(this);
        this.handleMiscDialogOpen = this.handleMiscDialogOpen.bind(this);
    }

    componentDidMount() {
        this.updateNextHoleButtonState();
    };

    componentDidUpdate(prevProps) {
        if (hasChangedSpecificProps(this.props, prevProps, ["players", "holeScores", "gameIds"])) {
            this.updateNextHoleButtonState();
        }
    };

    updateNextHoleButtonState() {
        const holeScores = this.props.holeScores;
        const allInputFields = GamesManager.getCommonInputFieldsFromGameIds(this.props.gameIds);
        if (!holeScores) {
            this.handleNextHoleButtonInvisible();
            return;
        } else {
            const totalNumberOfPlayers = this.props.players.length;
            if (Object.keys(holeScores).length !== totalNumberOfPlayers) {
                this.handleNextHoleButtonInvisible();
                return;
            } else {
                for (let index = 0; index < totalNumberOfPlayers; index++) {
                    const playerScores = holeScores[index];
                    allInputFields.forEach((inputField) => {
                        if (!(inputField in playerScores)) {
                            this.handleNextHoleButtonInvisible();
                            return;
                        }
                    });
                }
            }
        }
        this.handleNextHoleButtonVisible();
    };

    handlePreviousButtonClick() {
        this.props.onPreviousHole();
    };

    handleNextButtonClick() {
        this.props.onNextHole();
    };

    handleNextHoleButtonVisible() {
        this.setState({
            isNextHoleButtonVisible: true,
        });
    };
    handleNextHoleButtonInvisible() {
        this.setState({
            isNextHoleButtonVisible: false,
        });
    };

    handleMiscDialogClose() {
        this.setState({
            miscFieldsDialogOpen: false
        })
    }

    handleMiscDialogOpen() {
        this.setState({
            miscFieldsDialogOpen: true
        }) 
    }

    showMiscGamesSettingsModal() {
        let miscFields = [];
        this.props.gameIds.forEach(gameId => {
            const gameInstance = GamesManager.initGame(gameId, { players: this.props.players, golfCourse: this.props.golfCourse, holesSettings: this.props.holesSettings });
            if(gameInstance) {
                const gameMiscFields = gameInstance.miscFieldsOnHole(this.props.holeData.hole_number, this.props.holeScores);
                if (gameMiscFields) {
                    miscFields = [...new Set([...miscFields, ...gameMiscFields])];
                }
            }
        });

        if (miscFields.length < 1) {
            return null;
        }

        return <Box marginTop={2} display="flex" justifyContent="center">

            <Button variant="contained" color="primary" size="small" endIcon={<NavigateNextIcon />} onClick={this.handleMiscDialogOpen}>
                Please enter additional info
            </Button>       
            <Dialog open={this.state.miscFieldsDialogOpen} onClose={this.handleMiscDialogClose}>
                <DialogTitle>Enter Additional Info</DialogTitle>
                <DialogContent>
                    {miscFields.map(fieldKey => {
                        const fieldObject = getField(fieldKey);
                        return <Box m={1} key={fieldKey}>
                            <Typography>{fieldObject.name}</Typography>
                            <fieldObject.componentRender holeNumber={this.props.holeData.hole_number} holeScores={this.props.holeScores} />
                        </Box>
                    })}
                </DialogContent>
            </Dialog>
        </Box>
    }

    render() {
        console.log("HolePanel rendering");
        const { holeData, classes, holeIndex } = this.props;
        return (
            <>
                <div className={classes.root}>
                    <Box
                        display="flex"
                        justifyContent="space-around"
                        flexGrow={1}
                        minHeight="100px"
                        maxHeight="240px"
                        paddingBottom={3}
                    >
                        {/* <IconButton color="secondary" size="small" onClick={this.handlePreviousButtonClick}>
                            <NavigateBeforeIcon fontSize="large" />
                        </IconButton> */}
                        <Box flexGrow="1">
                            <HoleInfoPaper hole={holeData} />
                        </Box>
                        {/* <IconButton color="secondary" size="small" onClick={this.handleNextButtonClick}>
                            <NavigateNextIcon fontSize="large" />
                        </IconButton> */}
                    </Box>
                    {this.state.isNextHoleButtonVisible && (
                        <Button color="primary" variant="contained" onClick={this.handleNextButtonClick}>
                            Next Hole
                            <NavigateNextIcon />
                        </Button>
                    )}
                    {/* Let's make a dialog which will ask any miscllaneous quesitons from the games */}
                    {this.showMiscGamesSettingsModal()}
                    <HoleInputTable holeIndex={holeIndex} holeData={holeData} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let holeScores = null;
    if (ownProps.holeData.hole_number && state.activeRound.payload.scores[ownProps.holeData.hole_number]) {
        holeScores = state.activeRound.payload.scores[ownProps.holeData.hole_number];
    }
    return {
        players: state.activeRound.payload.players,
        holeScores: holeScores,
        gameIds: state.activeRound.payload.gameIds,
        golfCourse: state.activeRound.payload.golfCourse,
        holesSettings: state.activeRound.payload.holesSettings
    };
};

export default connect(mapStateToProps)(withStyles(classes)(HolePanel));
