import React from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "firebase/auth";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(3, 3),
        minHeight: "100%",
        minWidth: "100%",
        flexDirection: "column"
    }
}));

function GameModes() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Box marginY={2}>
                    <img src="/images/icons/iOS/Icon-256.png" alt="Logo" height="128" width="128" />
                </Box>
                <Box marginY={2}>
                    <Button component={Link} to="/dashboard" variant="contained" color="primary">
                        Local Games
                    </Button>
                </Box>
                <Box marginY={2}>
                    <Button variant="contained" color="primary">
                        Tournaments
                    </Button>
                </Box>
            </div>
        </React.Fragment>
    );
}

export default GameModes;
