import React from 'react';
import { Box, Typography } from '@material-ui/core';

const NotFound = () => {
    return (
        <Box display="flex" justifyContent="space-around" alignItems="center" minHeight="100%">
            <Typography variant="h2">404</Typography>
            <Typography variant="h3">Not Found</Typography>
        </Box>
    );
}

export default NotFound;