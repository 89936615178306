import { SET_FAVOURITE_PLAYERS, FETCH_FAVOURITE_PLAYERS_BEGIN, FETCH_FAVOURITE_PLAYERS_SUCCESS } from "actions/actionTypes";
import { usersCollectionRef } from 'firebase/collectionRefs';
import AppNotifier from 'AppNotifier';

export const updateFavouritePlayers = updatedFavouritePlayers => {
    return (dispatch, getState) => {
        let user = getState().auth.user;
        if (user) {
            usersCollectionRef
                .doc(user.uid)
                .set({ favouritePlayers: updatedFavouritePlayers }, { merge: true })
                .then(() => {
                    dispatch({
                        type: SET_FAVOURITE_PLAYERS,
                        payload: updatedFavouritePlayers
                    });
                })
                .catch(err => {
                    AppNotifier.notify("Failed to update data", { variant: "error" });
                    console.error("Failed to update the favourite players. Error: ", err);
                });
        }
    };
};

export const fetchFavouritePlayers = user => dispatch => {
    if (user) {
        dispatch({
            type: FETCH_FAVOURITE_PLAYERS_BEGIN
        });
        usersCollectionRef
            .doc(user.uid)
            .get()
            .then(user => {
                if (user.exists) {
                    if (user.data().favouritePlayers && user.data().favouritePlayers.length > 0) {
                        dispatch({
                            type: FETCH_FAVOURITE_PLAYERS_SUCCESS,
                            payload: user.data().favouritePlayers
                        });
                    } 
                    else
                        dispatch({
                            type: FETCH_FAVOURITE_PLAYERS_SUCCESS
                        });
                }
                // this means that user doc hasn't been put into firestore yet but we have successfully tried loading
                else {
                    dispatch({
                        type: FETCH_FAVOURITE_PLAYERS_SUCCESS
                    });
                }
            })
            .catch(err => {
                AppNotifier.notify("Failed to get Favourite Players", { variant: "error" });
                console.error("Failed to get the custom user data. Error: ", err);
            });
    }
}