import React, { useState } from "react";
import { connect } from "react-redux";
import CenteredCircularProgress from "../../components/utils/CenteredCircularProgress";
import {
    List,
    ListItem,
    ListItemText,
    Paper,
    ListSubheader,
    ListItemSecondaryAction,
    IconButton,
    makeStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteRound } from "../../actions/roundActions";

const useStyles = makeStyles(theme => ({
    roundListPaper: {
        marginTop: theme.spacing(2),
        overflow: 'auto',
        minHeight: '120px'
    },
    listSubheader: {
        backgroundColor: theme.palette.background.paper
    }
}));

const PreviousRoundsList = props => {
    const classes = useStyles();
    console.log("PreviousRoundsList component rendered");

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [toBeDeletedRoundId, setToBeDeletedRoundId] = useState("");

    const handleRoundDeleteClick = key => {
        setToBeDeletedRoundId(key);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogConfirm = () => {
        setDeleteDialogOpen(false);
        props.deleteRound(toBeDeletedRoundId);
        setToBeDeletedRoundId("");
    };

    const handleDeleteDialogCancel = () => {
        setDeleteDialogOpen(false);
        setToBeDeletedRoundId("");
    };

    return (
        <Paper className={classes.roundListPaper}>
            {props.allRoundsLoading ? (
                <CenteredCircularProgress />
            ) : (
                <>
                    <List subheader={<ListSubheader className={classes.listSubheader}>Previous Rounds</ListSubheader>}>
                        {Object.keys(props.allRounds).map(key => {
                            let courseName = "Unknown";
                            let dateStarted = "Unknown";
                            if (props.allRounds[key].golfCourse && props.allRounds[key].golfCourse.name)
                                courseName = props.allRounds[key].golfCourse.name;
                            if (props.allRounds[key].createdTimestamp)
                                dateStarted = new Date(
                                    props.allRounds[key].createdTimestamp.seconds * 1000
                                ).toLocaleString();
                            return (
                                <ListItem button key={key} component={Link} to={"/play-round/" + key}>
                                    <ListItemText primary={courseName} secondary={dateStarted} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={() => handleRoundDeleteClick(key)}>
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                        {Object.keys(props.allRounds).length < 1 && (
                            <ListItem>
                                <ListItemText>No Rounds Available</ListItemText>
                            </ListItem>
                        )}
                    </List>
                </>
            )}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogCancel}>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this item?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogCancel} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDeleteDialogConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        allRounds: state.rounds.payload,
        allRoundsLoading: state.rounds.isLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteRound: roundId => dispatch(deleteRound(roundId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreviousRoundsList);
