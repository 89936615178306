import HiLoResult from "containers/GameResult/HiLoResult";
import { STROKES } from "./FieldTypes";
import Game from "./Game";
import GamesManager, { HI_LO, NINES } from "./GamesManager";

class HiLo extends Game {

    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: HiLo.gameName,
            description: HiLo.description,
            beforeStartRequiredFields: HiLo.getGameSettingsFields(),
            resultComponent: HiLo.resultComponent,
            // holePlayerInfoComponent: StablefordInfo
        };
        super(superOptions);
        this.team1GrossPoints = 0;
        this.team2GrossPoints = 0;
    }

    runGame() {
        this.calculatePointsForGross();
        this.calculatePointsForNet();
    }

    calculatePointsForGross() {
        let team1Points = 0;
        let team2Points = 0;
        holeScoresLoop:
        for (const holeScores of Object.values(this.scores)) {
            const playersWithIndexesAndScores = Object.entries(holeScores);
            let team1LowPlayerIndexWithScore = null;
            let team1HighPlayerIndexWithScore = null;
            let team2LowPlayerIndexWithScore = null;
            let team2HighPlayerIndexWithScore = null;
            for (const [playerIndex, playerScores] of playersWithIndexesAndScores) {
                if (!playerScores || !playerScores[STROKES] || playerScores[STROKES] === 0 || playerScores[STROKES] === "0") {
                    continue holeScoresLoop;
                }
                // if player from team 1
                if (this.players[playerIndex].hiLoTeam === 1) {
                    if (team1LowPlayerIndexWithScore === null) {
                        team1LowPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }
                    else if (playerScores[STROKES] < team1LowPlayerIndexWithScore[1]) {
                        team1HighPlayerIndexWithScore = team1LowPlayerIndexWithScore;
                        team1LowPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }
                    else {
                        team1HighPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }
                }
                // if player from team 2
                if (this.players[playerIndex].hiLoTeam === 2) {
                    if (team2LowPlayerIndexWithScore === null) {
                        team2LowPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }
                    else if (playerScores[STROKES] < team2LowPlayerIndexWithScore[1]) {
                        team2HighPlayerIndexWithScore = team2LowPlayerIndexWithScore;
                        team2LowPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }
                    else {
                        team2HighPlayerIndexWithScore = [playerIndex, playerScores[STROKES]];
                    }

                }
            }
            if (team1LowPlayerIndexWithScore && team2LowPlayerIndexWithScore) {
                if (team1LowPlayerIndexWithScore[1] !== team2LowPlayerIndexWithScore[1]) {
                    if (team1LowPlayerIndexWithScore[1] < team2LowPlayerIndexWithScore[1]) {
                        team1Points++;
                    }
                    else {
                        team2Points++;
                    }
                }
            }

            if (team1HighPlayerIndexWithScore && team2HighPlayerIndexWithScore) {

                if (team1HighPlayerIndexWithScore[1] !== team2HighPlayerIndexWithScore[1]) {
                    if (team1HighPlayerIndexWithScore[1] < team2HighPlayerIndexWithScore[1]) {
                        team1Points++;
                    }
                    else {
                        team2Points++;
                    }
                }
            }
        }
        this.team1GrossPoints = team1Points;
        this.team2GrossPoints = team2Points;
    }

    calculatePointsForNet() {
        let team1Points = 0;
        let team2Points = 0;
        holeScoresLoop:
        for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
            const playersWithIndexesAndScores = Object.entries(holeScores);
            let team1LowPlayerIndexWithScore = null;
            let team1HighPlayerIndexWithScore = null;
            let team2LowPlayerIndexWithScore = null;
            let team2HighPlayerIndexWithScore = null;
            for (const [playerIndex, playerScores] of playersWithIndexesAndScores) {
                if (!playerScores || !playerScores[STROKES] || playerScores[STROKES] === 0 || playerScores[STROKES] === "0") {
                    continue holeScoresLoop;
                }
                // if player from team 1
                let playerNetStrokes = this.getPlayerNetStrokes(holeNumber, playerIndex, playerScores[STROKES]);
                if (this.players[playerIndex].hiLoTeam === 1) {
                    if (team1LowPlayerIndexWithScore === null) {
                        team1LowPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }
                    else if (playerNetStrokes < team1LowPlayerIndexWithScore[1]) {
                        team1HighPlayerIndexWithScore = team1LowPlayerIndexWithScore;
                        team1LowPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }
                    else {
                        team1HighPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }
                }
                // if player from team 2
                if (this.players[playerIndex].hiLoTeam === 2) {
                    if (team2LowPlayerIndexWithScore === null) {
                        team2LowPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }
                    else if (playerNetStrokes < team2LowPlayerIndexWithScore[1]) {
                        team2HighPlayerIndexWithScore = team2LowPlayerIndexWithScore;
                        team2LowPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }
                    else {
                        team2HighPlayerIndexWithScore = [playerIndex, playerNetStrokes];
                    }

                }
            }
            if (team1LowPlayerIndexWithScore && team2LowPlayerIndexWithScore) {
                if (team1LowPlayerIndexWithScore[1] !== team2LowPlayerIndexWithScore[1]) {
                    if (team1LowPlayerIndexWithScore[1] < team2LowPlayerIndexWithScore[1]) {
                        team1Points++;
                    }
                    else {
                        team2Points++;
                    }
                }
            }

            if (team1HighPlayerIndexWithScore && team2HighPlayerIndexWithScore) {

                if (team1HighPlayerIndexWithScore[1] !== team2HighPlayerIndexWithScore[1]) {
                    if (team1HighPlayerIndexWithScore[1] < team2HighPlayerIndexWithScore[1]) {
                        team1Points++;
                    }
                    else {
                        team2Points++;
                    }
                }
            }
        }

        this.team1NetPoints = team1Points;
        this.team2NetPoints = team2Points;
    }

    getPlayerNetStrokes(holeNumber, playerIndex, playerStrokes) {
        return playerStrokes - this.getExtraStrokesAvailableOnHole(this.players[playerIndex].courseHandicap, holeNumber);
    }

    // static methods
    static getId() {
        return HI_LO;
    }

    static info() {
        let namesOfNonCompatibleGames = [];
        this.nonCompatibleGames().forEach(gameId => {
            const game = GamesManager.getGame(gameId);
            if (game) {
                namesOfNonCompatibleGames.push(game.gameName);
            }
        });
        const nonCompatibleGamesString = namesOfNonCompatibleGames.join(", ");
        return ["You would only be able to select 4 players to play this game", "Not compatible with these games: " + nonCompatibleGamesString];
    }

    static nonCompatibleGames() {
        return [NINES];
    }

    static numberOfPlayersRequired() {
        return 4;
    }

    static requiredFields() {
        return [STROKES];
    }
}
HiLo.gameName = "Hi-Lo";
HiLo.description = "This is HiLo game";
HiLo.resultComponent = HiLoResult
export default HiLo;