import React from "react";
import { makeStyles, Chip } from "@material-ui/core";
import flagGifs from "resources/images/holeFlags";

const useStyles = makeStyles(theme => ({
    root: props => ({
        backgroundImage: `url(${flagGifs[props.holeNumber]})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%'
    })
}));

const HoleInfoPaper = ({ hole }) => {
    const holeNumber = hole.hole_number;
    const classes = useStyles({ holeNumber: holeNumber });

    const buildHoleInfoLabel = () => {
        if (hole.info) {
            let label = "Par ";
            if (hole.info.par) label += hole.info.par;
            else label += "NA";

            label += " - Hole Handicap ";

            if (hole.info.handicap) label += hole.info.handicap;
            else label += "NA";

            return label;
        }
    };

    return (
        <div className={classes.root}>
            <Chip label={buildHoleInfoLabel()} />
        </div>
    );
};

export default HoleInfoPaper;
