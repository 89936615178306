import OoslerWooslerResult from "containers/GameResult/OoslerWooslerResult";
import GLooperObjectsDataExtractor from "GLooperObjectsDataExtractor";
import { OOSLER_PLAYER_INDEX, OOSLER_STEAL_PLAYER_INDEX, STROKES } from "./FieldTypes";
import Game from "./Game";
import { OOSLER_WOOSLER } from "./GamesManager";

class OoslerWoosler extends Game {

    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: OoslerWoosler.gameName,
            description: OoslerWoosler.description,
            beforeStartRequiredFields: OoslerWoosler.getGameSettingsFields(),
            resultComponent: OoslerWoosler.resultComponent,
            // holePlayerInfoComponent: StablefordInfo
        };
        super(superOptions);
    }

    runGame() {
        // this.calculatePointsForGross();
        // this.calculatePointsForNet();
        this.players.forEach(player => player.points = 0);

        // max points for an oosler are number of players
        const pointsOnEachHole = this.players.length - 1;

        // carry forward points from holes where there was no oosler
        let carryForwardPoints = 0;
        holeScoresLoop:
        for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
            let par = (new GLooperObjectsDataExtractor({ golfCourse: this.golfCourse })).getParFromGolfCourse(holeNumber);
            if (par !== 3) {
                continue;
            }
            const pointsForThisHole = pointsOnEachHole + carryForwardPoints;
            carryForwardPoints = 0;

            let ooslerPlayerIndex = null;
            if (this.holesSettings[holeNumber] && this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX] != null) {
                ooslerPlayerIndex = this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX];
            }

            if (ooslerPlayerIndex == null) {
                carryForwardPoints += pointsForThisHole;
                continue;
            }

            if (holeScores[ooslerPlayerIndex] && holeScores[ooslerPlayerIndex][STROKES] != null && holeScores[ooslerPlayerIndex][STROKES] > 3) {
                // oosler becomes woosler because strokes are more than 3, divide points with other players
                this.players.forEach((player, playerIndex) => {
                    if (playerIndex !== ooslerPlayerIndex) {
                        player.points += pointsForThisHole / pointsOnEachHole;
                    }
                });
                continue;
            }

            // if ooosler player scores are not entered or if he has score of 2 he wins, nobody can beat him
            if (!holeScores[ooslerPlayerIndex][STROKES] || parseInt(holeScores[ooslerPlayerIndex][STROKES]) < 3) {
                this.players[ooslerPlayerIndex].points += pointsForThisHole;
                continue;
            }
            // we will only reach here if oosler player scores are 3 to be exact

            // check if anybody is a stealing player
            if (this.holesSettings[holeNumber] && this.holesSettings[holeNumber][OOSLER_STEAL_PLAYER_INDEX] != null) {
                let stealPlayerIndex = this.holesSettings[holeNumber][OOSLER_STEAL_PLAYER_INDEX];
                if (holeScores[stealPlayerIndex] && holeScores[stealPlayerIndex][STROKES] && parseInt(holeScores[stealPlayerIndex][STROKES]) < 3) {
                    this.players[this.holesSettings[holeNumber][OOSLER_STEAL_PLAYER_INDEX]].points += pointsForThisHole;
                    continue;
                }
            }

            // check if anybody scored less than oosler
            for (const [playerIndex, playerScores] of Object.entries(holeScores)) {
                if (playerScores[STROKES] && parseInt(playerScores[STROKES]) < 3) {
                    this.players[playerIndex].points += pointsForThisHole;
                    continue holeScoresLoop;
                }
            }
            // otherwise let oosler get the points
            this.players[ooslerPlayerIndex].points += pointsForThisHole;
        }

    }

    getPossibleStealPlayersForHole(holeNumber, holeScores) {

        let ooslerPlayerIndex = null;

        if (this.holesSettings[holeNumber] && this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX] != null) {
            ooslerPlayerIndex = this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX]
        }
        if (ooslerPlayerIndex === null) {
            return [];
        }

        let possibleStealingPlayers = [];
        for (const [playerIndex, playerScores] of Object.entries(holeScores)) {
            if (playerIndex !== ooslerPlayerIndex) {
                if (playerScores[STROKES] && parseInt(playerScores[STROKES]) === 2) {
                    possibleStealingPlayers.push(playerIndex);
                }
            }
        }
        return possibleStealingPlayers;
    }

    miscFieldsOnHole(holeNumber, holeScores) {
        let fields = [];
        let par = (new GLooperObjectsDataExtractor({ golfCourse: this.golfCourse })).getParFromGolfCourse(holeNumber);
        if (par !== 3) {
            return fields;
        }

        fields.push(OOSLER_PLAYER_INDEX);
        if (!holeScores) return fields;


        let ooslerPlayerIndex = null;

        if (this.holesSettings[holeNumber] && this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX] != null) {
            ooslerPlayerIndex = this.holesSettings[holeNumber][OOSLER_PLAYER_INDEX]
        }
        if (ooslerPlayerIndex === null) {
            return fields;
        }

        if (!holeScores[ooslerPlayerIndex] || !holeScores[ooslerPlayerIndex][STROKES] || parseInt(holeScores[ooslerPlayerIndex][STROKES]) !== 3) {
            return fields;
        }

        let numberOfPossibleStealingPlayers = 0;
        for (const [playerIndex, playerScores] of Object.entries(holeScores)) {
            if (playerIndex !== ooslerPlayerIndex) {
                if (playerScores[STROKES] && parseInt(playerScores[STROKES]) === 2) {
                    numberOfPossibleStealingPlayers++;
                }
            }
        }

        if (numberOfPossibleStealingPlayers > 1) {
            fields.push(OOSLER_STEAL_PLAYER_INDEX);
        }
        return fields;
    }

    // static methods
    static getId() {
        return OOSLER_WOOSLER;
    }

    static isGameValidOnHole(golfCourse, holeNumber) {
        let par = (new GLooperObjectsDataExtractor({ golfCourse: golfCourse })).getParFromGolfCourse(holeNumber);
        if (par != 3) return false;
        return true;
    }
}
OoslerWoosler.gameName = "Oosler/Woosler";
OoslerWoosler.description = "This is OoslerWoosler game";
OoslerWoosler.resultComponent = OoslerWooslerResult
export default OoslerWoosler;