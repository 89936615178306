import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Box, Paper } from "@material-ui/core";
import RoundSetupHeader from "./RoundSetupHeader";
import {
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    Typography,
    Divider
} from "@material-ui/core";
import { removeElementFromArray } from "../../utils/ArrayHelpers";
import AddEditPlayerDialog from "../../components/FavouritePlayers/AddEditPlayerDialog";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import { updateFavouritePlayers, fetchFavouritePlayers } from "actions/favouritePlayersActions";
import { setNewRoundSelectedPlayers, nextSetupView, previousSetupView } from "actions/newRoundActions";
import Navigation from "./Navigation";
import AddEditPlayerForm from "components/FavouritePlayers/AddEditFavouritePlayerForm";
import GamesManager, { HI_LO, NINES } from "Games/GamesManager";

const useStyles = makeStyles(theme => ({
    selectPlayers: {
        padding: theme.spacing(3, 2),
        display: "flex",
        flexDirection: "column"
    }
}));

function ChoosePlayers(props) {
    console.log("ChoosePlayers rendered");

    const styleClasses = useStyles();

    const [selectedPlayersIndexes, setSelectedPlayersIndexes] = useState([]);

    const [addPlayerOpen, setAddPlayerOpen] = useState(false);

    const { fetchFavouritePlayers, user } = props
    useEffect(() => {
        fetchFavouritePlayers(user);
    }, [fetchFavouritePlayers, user]);

    const getSelectedPlayers = () => {
        let selectedPlayersArray = [];
        for (let selectedPlayersIndex of selectedPlayersIndexes) {
            if (typeof props.favouritePlayers[selectedPlayersIndex] !== "undefined")
                selectedPlayersArray.push(props.favouritePlayers[selectedPlayersIndex]);
        }
        return selectedPlayersArray;
    };

    const handlePlayerAdded = player => {
        if (player) {
            props.updateFavouritePlayers([...props.favouritePlayers, player]);
            setSelectedPlayersIndexes([...selectedPlayersIndexes, props.favouritePlayers.length]);
        }
        // select the player added
        setAddPlayerOpen(false);
    };

    const handlePlayerToggle = index => {
        if (selectedPlayersIndexes.indexOf(index) === -1) {
            setSelectedPlayersIndexes([...selectedPlayersIndexes, index]);
        } else {
            setSelectedPlayersIndexes(removeElementFromArray(selectedPlayersIndexes, index));
        }
    };

    const handleAddPlayerClick = () => {
        setAddPlayerOpen(true);
    };

    const handleNextViewClick = () => {
        props.setNewRoundSelectedPlayers(getSelectedPlayers());
        props.nextSetupView();
    }

    const isDisabled = playerIndex => {
        if (props.currentGameIds.indexOf(NINES) !== -1 && selectedPlayersIndexes.length >= 3 && selectedPlayersIndexes.indexOf(playerIndex) === -1) {
            return true;
        }
        if (props.currentGameIds.indexOf(HI_LO) !== -1 && selectedPlayersIndexes.length >= 4 && selectedPlayersIndexes.indexOf(playerIndex) === -1) {
            return true;
        }
        return false;
    }

    const isNextDisabled = () => {
        if (selectedPlayersIndexes.length < 1) return true;

        for (const gameId of props.currentGameIds) {
            const game = GamesManager.getGame(gameId);
            if (!game || !game.numberOfPlayersRequired()) continue;
            
            if(selectedPlayersIndexes.length !== game.numberOfPlayersRequired()) return true;

        }
        return false;
    }

    // if (!props.currentGameIds.isLoading)
    return (
        <React.Fragment>
            <RoundSetupHeader disablePlayers />

            <div className={styleClasses.selectPlayers}>
                {/* Material ui issue - https://github.com/mui-org/material-ui/issues/7466, need to wrap the grid in extra
                        div so that it doesn't overflow
                    */}
                <Box marginBottom={1}>
                    <Typography variant="overline">Select Players:</Typography>
                </Box>
                <Box marginBottom={1}>
                    <Paper>
                        {!props.playersIsLoading ? (
                            <React.Fragment>
                                <List>
                                    {props.favouritePlayers.map((player, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ListItem disabled={isDisabled(index)} dense button onClick={() => handlePlayerToggle(index)}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={
                                                                selectedPlayersIndexes.indexOf(index) !== -1
                                                                    ? true
                                                                    : false
                                                            }
                                                            disableRipple
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={player.name} />
                                                </ListItem>
                                                <Divider />
                                            </React.Fragment>
                                        );
                                    })}
                                    <ListItem dense button onClick={handleAddPlayerClick}>
                                        <ListItemIcon>
                                            <AddCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Player..." />
                                    </ListItem>
                                </List>
                                <AddEditPlayerDialog formRender={AddEditPlayerForm} open={addPlayerOpen} onClose={handlePlayerAdded} />
                            </React.Fragment>
                        ) : (
                            <List>
                                <ListItem>
                                    <CircularProgress color="primary"></CircularProgress>
                                </ListItem>
                            </List>
                        )}
                    </Paper>
                </Box>
                {/* <NewRoundGamesSettingForm /> */}
                <Box marginBottom={1}>
                    <Navigation nextDisabled={isNextDisabled()} onPreviousClick={props.previousSetupView} onNextClick={handleNextViewClick} />
                </Box>
            </div>
        </React.Fragment>
    );

    // return <h4>No saved games can be found in the Database</h4>;
}

// redux here
const mapStateToProps = state => {
    return {
        currentGameIds: state.newRound.selectedGameIds.payload,
        favouritePlayers: state.favouritePlayers.payload,
        playersIsLoading: state.favouritePlayers.isLoading,
        golfCourse: state.newRound.selectedGolfCourse.info.payload,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateFavouritePlayers: playersArray => dispatch(updateFavouritePlayers(playersArray)),
        setNewRoundSelectedPlayers: players => dispatch(setNewRoundSelectedPlayers(players)),
        nextSetupView: () => dispatch(nextSetupView()),
        previousSetupView: () => dispatch(previousSetupView()),
        fetchFavouritePlayers: (user) => dispatch(fetchFavouritePlayers(user))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChoosePlayers);