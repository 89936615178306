import { SET_SIGNING_OUT_USER_TRUE, SET_SIGNING_OUT_USER_FALSE, FETCH_USER } from "actions/actionTypes";

const initState = {
    user: null,
    signingOut: false,
    timesChecked: 0,
    error: null
};

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_SIGNING_OUT_USER_TRUE:
            return { ...state, signingOut: true };
        case SET_SIGNING_OUT_USER_FALSE:
            return { ...state, signingOut: false };
        case FETCH_USER:
            return { ...state, user: action.payload ? { ...action.payload } : initState.user, timesChecked: ++state.timesChecked, signingOut: false };
        default:
        // do nothing
    }
    return state;
};

export default authReducer;
