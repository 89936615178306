import React from "react";
import { TextField, Typography } from "@material-ui/core";


function BetAmountPerHoleInput({ name, value, onValueChange, label }) {
    console.log("BetAmountPerHoleInput component rendering");

    const handleInputChange = evt => {
        const newValue = Number(evt.target.value);
        if (newValue) {
            onValueChange(evt.target.value);
        }
    };

    return (
        <React.Fragment>
            <Typography>{name}</Typography>
            <TextField
                label={label ? label : ""}
                type="number"
                InputLabelProps={{
                    shrink: true
                }}
                defaultValue={value ? value : 1}
                variant="standard"
                onChange={handleInputChange}
            />
        </React.Fragment>
    );
}

export default BetAmountPerHoleInput;
