import React from "react";
import { Paper, withStyles } from "@material-ui/core";

const withPaper = WrappedComponent => {
    const WithPaper = ({ classes, ...props }) => (
        <Paper className={classes.root}>
            <WrappedComponent {...props} />
        </Paper>
    );

    const styles = theme => ({
        root: {
            margin: theme.spacing(1),
            padding: theme.spacing(1)
        }
    });

    return withStyles(styles)(WithPaper);
};

export default withPaper;
