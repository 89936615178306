import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Typography, Card, CardActionArea, CardContent, CardActions } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import {
    Input,
    CircularProgress,
    Paper,
    ClickAwayListener,
    List,
    ListItem,
    ListItemText,
    Button
} from "@material-ui/core";
import AppNotifier from "../../AppNotifier";

import { updateCurrentGolfCourse } from "../../actions/newRoundActions";

// JSS here
const useStyles = makeStyles(theme => ({
    searchList: {
        flexGrow: 1,
        overflow: "scroll"
    },

    searchContainer: {
        maxHeight: "100%",
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column"
    },

    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        }
    },

    searchIcon: {
        width: theme.spacing(7),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    inputRoot: {
        width: "100%",
        color: "inherit"
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 7)
    },

    confirmationCard: {
        overflow: "initial"
    },

    buttonContainer: {
        marginTop: theme.spacing(2),
        textAlign: "right"
    }
}));

// cancel token setup for axios request
const CancelToken = axios.CancelToken;
let cancelToken;

// List item component
function CourseListItem(props) {
    return (
        <ListItem button>
            <ListItemText
                primary={
                    <Typography display="block" component="span" noWrap color="textPrimary">
                        {props.item.name}
                    </Typography>
                }
            />
        </ListItem>
    );
}

function CourseSearch(props) {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState("");

    const [coursesLoading, setCoursesLoading] = useState(true);

    const [searchListOpen, setSearchListOpen] = React.useState(false);

    const [fetchedCourses, setFetchedCourses] = React.useState([]);

    const [searchCoursesRender, setSearchCoursesRender] = React.useState([]);

    const [selectedGolfCourse, setSelectedGolfCourse] = React.useState(props.currentCourse);

    useEffect(() => {
        setSearchCoursesRender(
            fetchedCourses.reduce((collectArray, item) => {
                if (item.name && item.id)
                    collectArray.push(
                        <div key={item.id} onClick={() => courseListOnClick(item)}>
                            <CourseListItem item={item} />
                        </div>
                    );
                return collectArray;
            }, [])
        );
    }, [fetchedCourses]);

    const courseListOnClick = course => {
        setSelectedGolfCourse(course);
        setSearchListOpen(false);
    };

    const searchInputOnChange = evt => {
        let term = evt.target.value;
        if (term) {
            setSearchTerm(term);
            setCoursesLoading(true);
        } else setSearchTerm("");
    };

    // useEffect(function getGLooperAuth() {
    //   let tokenInCookie = () => {
    //     return "";
    //   }

    //   axios({
    //     method: 'get',
    //     responseType: 'json',
    //     url: 'http://localhost:5000/caddygames-ef56e/us-central1/getGLooperLoginAuthenticityToken'
    //   })
    //     .then(function (response) {
    //       console.log(response);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // }, []);

    useEffect(() => {
        const onChangeSearch = () => {
            if (searchTerm) {
                cancelToken && cancelToken();
                axios
                    .get(
                        process.env.REACT_APP_FUNCTIONS_SERVER_URL + "GLooperCoursesWithTerm?term=" +
                            searchTerm,
                        {
                            cancelToken: new CancelToken(function executor(c) {
                                cancelToken = c;
                            }),
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    )
                    .then(response => {
                        if (response.data.golfCourses && Array.isArray(response.data.golfCourses)) {
                            setFetchedCourses(response.data.golfCourses);
                            setCoursesLoading(false);
                        } else setFetchedCourses([]);
                    })
                    .catch(err => {
                        AppNotifier.notify("Failed. Some problem has occured", { variant: "error" });
                        console.log(err);
                        setFetchedCourses([]);
                    });
            }
        };
        const typingTimeoutToken = setTimeout(onChangeSearch, 2000);
        return () => clearTimeout(typingTimeoutToken);
    }, [searchTerm]);

    return (
        <React.Fragment>
            <div className={classes.searchContainer}>
                <ClickAwayListener
                    onClickAway={() => {
                        setSearchListOpen(false);
                    }}
                >
                    <React.Fragment>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <Input
                                type="search"
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput
                                }}
                                inputProps={{ "aria-label": "search" }}
                                onClick={() => {
                                    setSearchListOpen(true);
                                }}
                                onChange={evt => {
                                    searchInputOnChange(evt);
                                }}
                            />
                        </div>
                        {searchListOpen && searchTerm ? (
                            <Paper className={classes.searchList}>
                                <List>
                                    {searchCoursesRender.length > 0 && !coursesLoading ? (
                                        searchCoursesRender
                                    ) : (
                                        <ListItem>
                                            <CircularProgress className={classes.progress} />
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                        ) : null}
                        <div className={classes.buttonContainer}></div>
                    </React.Fragment>
                </ClickAwayListener>

                {selectedGolfCourse && (
                    <Card className={classes.confirmationCard}>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="h6">Selected Golf Course:</Typography>
                                <Typography variant="body1" color="textPrimary" component="p">
                                    {selectedGolfCourse.name} -{" "}
                                    <Typography variant="body2" color="textSecondary" component="span">
                                        {selectedGolfCourse.location}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions style={{ justifyContent: "flex-end" }}>
                            <Button
                                color="primary"
                                onClick={() => props.updateCurrentGolfCourse(props.currentUser, selectedGolfCourse)}
                                to="/dashboard"
                                component={Link}
                            >
                                Save
                            </Button>
                        </CardActions>
                    </Card>
                )}
            </div>
        </React.Fragment>
    );
}

// redux here
const mapStateToProps = state => {
    return {
        currentCourse: state.newRound.selectedGolfCourse.info.payload,
        currentUser: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentGolfCourse: (currentUser, newGolfCourse) =>
            dispatch(updateCurrentGolfCourse(currentUser, newGolfCourse))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseSearch);
