import React from 'react';
import { makeStyles, Fab, CircularProgress, Box } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
      },
      fabProgress: {
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
      },
}));

// just a simple component showing lock animation
function SignOut() {

    const classes = useStyles();

    return (
        <React.Fragment>
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignContent="center" flexWrap="wrap">
        <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="secondary"
        >
          <LockIcon />
        </Fab>
        <CircularProgress size={68} className={classes.fabProgress} />
      </div>
      </Box>
        </React.Fragment>
    );
}

export default SignOut;