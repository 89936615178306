import React from "react";
import { Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

function HiLoTeams({ selectedPlayers, playersSelectedTeams, onPlayerTeamChange }) {
    // props

    // state

    // side-effects


    // functions
    const radioOnChange = (event, playerIndex, teamNumber) => {
        onPlayerTeamChange(playerIndex, event.target.checked ? teamNumber : null);
    }

    const isDisabledCheckbox = (playerIndex, teamNumber) => {
        // check if already two players selected for the team
        let playersCountForTeam = 0;
        for (const playerSelectedTeam of playersSelectedTeams) {
            if (playerSelectedTeam && playerSelectedTeam === teamNumber) playersCountForTeam++;
        }
        return ((!playersSelectedTeams[playerIndex] || playersSelectedTeams[playerIndex] !== teamNumber) && playersCountForTeam > 1);
    }

    // return template
    return <>
        <Typography align="center" variant="subtitle1">
            Hi-Lo Teams
        </Typography>
        <Paper>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Team A</TableCell>
                        <TableCell>Team B</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedPlayers.map((player, playerIndex) => {
                        return <TableRow key={playerIndex}>
                            <TableCell>{player.name}</TableCell>
                            <TableCell><Checkbox checked={playersSelectedTeams[playerIndex] && playersSelectedTeams[playerIndex] === 1 ? true : false} disabled={isDisabledCheckbox(playerIndex, 1)} onChange={(event) => radioOnChange(event, playerIndex, 1)} /></TableCell>
                            <TableCell><Checkbox checked={playersSelectedTeams[playerIndex] && playersSelectedTeams[playerIndex] === 2 ? true : false} disabled={isDisabledCheckbox(playerIndex, 2)} onChange={(event) => radioOnChange(event, playerIndex, 2)} /></TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </Paper>
    </>;
}

export default React.memo(HiLoTeams);
