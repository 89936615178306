import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    }
}));

function NameInitialsAvatar({ name, avatarOverrideStyleClass, ...props }) {

    /**
     * Props
     * name - full name from which we want the name initials avatar
     */

    const classes = useStyles();

    if (name == null) {
        return null;
    }

    const words = name.split(" ");

    // get first 3 or less words. As full name is usually split into first, middle and last name
    const nameParts = words.slice(0, 3);

    const nameInitials = nameParts.map(namePart => namePart.charAt(0));

    return (
            <Avatar { ...props } className={clsx(classes.root, avatarOverrideStyleClass)}>{nameInitials}</Avatar>
    );
}

export default NameInitialsAvatar;