import React from "react";
import Button from "@material-ui/core/Button";
import { DialogActions, TextField, MenuItem } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { calculateCourseHandicap } from "golfFormulas";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Must be a valid email address"),
    // .required("Email is required"),
    courseHandicap: Yup.number()
        .integer("Course Handicap must be an integer")
        .required("Course Handicap is required"),
    selectedTeeSetIndex: Yup.number().required(),
});

export default function EditPlayerFormOnHoleView({ editPlayer, onReset, onSubmit, teeSetOptions }) {
    console.log("EditPlayerFormOnHoleView component is rendering");

    if (!editPlayer)
        return null;

    return (
        <Formik
            initialValues={editPlayer}
            onReset={onReset}
            onSubmit={onSubmit}
            validationSchema={validationSchema}>
            {({ values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue }) => {

                const handleTeeSetChange = event => {
                    // let's change the value of handicap accordingly
                    const selectedTeeSetIndex = event.target.value;
                    if (Number.isInteger(selectedTeeSetIndex)) {

                        // 1 handicap if failed to calculate
                        let newCourseHandicap = 1;
                        if (values.handicapIndex && teeSetOptions[selectedTeeSetIndex]) {
                            const teeSet = teeSetOptions[selectedTeeSetIndex];
                            if (teeSet.slope && teeSet.rating && teeSet.par) {
                                newCourseHandicap = calculateCourseHandicap(values.handicapIndex, teeSet.slope, teeSet.rating, teeSet.par);
                            }
                        }
                        setFieldValue('courseHandicap', newCourseHandicap);
                    }
                    // fire the normal formik event for field change
                    handleChange(event);
                }

                return (
                    <form onSubmit={handleSubmit} onReset={handleReset}>
                        <TextField
                            label="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.name && touched.name && errors.name}
                            margin="dense"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            label="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.email && touched.email && errors.email}
                            margin="dense"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        <TextField
                            type="number"
                            inputProps={{ step: "1" }}
                            label="Course Handicap"
                            name="courseHandicap"
                            value={values.courseHandicap}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.courseHandicap && touched.courseHandicap && errors.courseHandicap}
                            margin="dense"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            id="selected-teeset-label"
                            select
                            label="Round Tee Set"
                            name="selectedTeeSetIndex"
                            value={values.selectedTeeSetIndex}
                            onChange={handleTeeSetChange}
                            fullWidth
                            margin="dense"
                            helperText={
                                errors.selectedTeeSetIndex && touched.selectedTeeSetIndex && errors.selectedTeeSetIndex
                            }
                        >
                            {teeSetOptions.map((teeSet, index) => (
                                <MenuItem value={index} key={index}>
                                    {teeSet.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DialogActions>
                            <Button disabled={!isValid} type="submit" color="primary">
                                Save
                            </Button>
                            <Button type="reset" color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                )
            }}
        </Formik>
    );
}