import Game from "./Game";
import StrokePlayResult from "../containers/GameResult/StrokePlayResult";
import { STROKE_PLAY } from "./GamesManager";
import { IS_NASSAU, OVERALL_BET, FRONT_NINE_BET, BACK_NINE_BET, STROKES } from "./FieldTypes";
import currency from 'currency.js';
import GLooperObjectsDataExtractor from "GLooperObjectsDataExtractor";

class StrokePlay extends Game {
    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: options.name || StrokePlay.gameName,
            description: options.description || StrokePlay.description,
            resultComponent: options.resultComponent || StrokePlayResult,
        };
        // const strokeplayRequiredFields = ["STROKES"];
        // if (options.requiredFields) {
        //     superOptions.requiredFields = union(options.requiredFields, strokeplayRequiredFields);
        // }
        // else {
        //     superOptions.requiredFields = strokeplayRequiredFields;
        // }
        super(superOptions);
        this.betResults = {
            gross: {
                frontNine: [],
                moneyToDistributeFrontNine: [],
                backNine: [],
                moneyToDistributeBackNine: [],
                overall: [],
                moneyToDistributeOverall: []
            },
            net: {
                frontNine: [],
                backNine: [],
                overall: []
            }
        };

        // strokesToPlayers
        // array of original playerIndexes with different strokes calculations
        /* e.g.
        {
            playerIndex: 0,
            grossFrontStrokes: 4,
            grossBackStrokes: 8,
            grossTotalStrokes: 12,
            netFrontStrokes: 4,
            netBackStrokes: 8,
            netTotalStrokes: 12
        }
         */
        // let build this from players passed to us
        this.strokesToPlayers = this.buildStrokesToPlayers();
        this.betsWonToPlayers = this.buildBetsWonToPlayers();
    }

    buildBetsWonToPlayers() {
        return this.players.map((player, playerIndex) => ({
            playerIndex: playerIndex,
            grossFrontNineBetWon: 0,
            grossBackNineBetWon: 0,
            grossOverallBetWon: 0,
            grossTotalBetWon: 0,
            netFrontNineBetWon: 0,
            netBackNineBetWon: 0,
            netOverallBetWon: 0,
            netTotalBetWon: 0,
        })
        );
    }

    buildStrokesToPlayers() {
        return this.players.map((player, playerIndex) => ({
            playerIndex: playerIndex,
            grossFrontStrokes: 0,
            grossBackStrokes: 0,
            grossTotalStrokes: 0,
            netFrontStrokes: 0,
            netBackStrokes: 0,
            netTotalStrokes: 0
        })
        );
    }

    calculateBetResults() {
        this.calculateGrossBetResults();
        this.calculateNetBetResults();
    }

    calculateNetBetResults() {
        // is nassau then calculate bet result for all three hole groups otherwise only overall
        if (this.getGameSetting(IS_NASSAU)) {
            const playersIndexesWithLeastStrokesFrontNine = this.getNassauNetResults('FRONT_NINE');
            const playersIndexesWithLeastStrokesBackNine = this.getNassauNetResults('BACK_NINE');
            const playersIndexesWithLeastStrokesOverall = this.getNassauNetResults('OVERALL');

            let frontNineBet = this.getGameSetting(FRONT_NINE_BET);
            let backNineBet = this.getGameSetting(BACK_NINE_BET);
            let overallBet = this.getGameSetting(OVERALL_BET);
            // this.players.forEach(player => player.grossTotalBetWon = 0);
            if (frontNineBet) {
                if (playersIndexesWithLeastStrokesFrontNine.length > 0) {
                    const moneyToDistribute = currency(frontNineBet).distribute(playersIndexesWithLeastStrokesFrontNine.length);
                    playersIndexesWithLeastStrokesFrontNine.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].netFrontNineBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].netTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].netTotalBetWon).toString();
                    });
                }

            }
            if (backNineBet) {
                if (playersIndexesWithLeastStrokesBackNine.length > 0) {
                    const moneyToDistribute = currency(backNineBet).distribute(playersIndexesWithLeastStrokesBackNine.length);
                    playersIndexesWithLeastStrokesBackNine.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].netBackNineBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].netTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].netTotalBetWon).toString();
                    });
                }
            }
            if (overallBet) {
                if (playersIndexesWithLeastStrokesOverall.length > 0) {
                    const moneyToDistribute = currency(overallBet).distribute(playersIndexesWithLeastStrokesOverall.length);
                    playersIndexesWithLeastStrokesOverall.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].netOverallBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].netTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].netTotalBetWon).toString();
                    });
                }

            }
        }
        else {
            let overallBet = this.getGameSetting(OVERALL_BET);
            const playersIndexesWithLeastStrokesOverall = this.getNassauNetResults('OVERALL');
            if (playersIndexesWithLeastStrokesOverall.length > 0) {
                const moneyToDistribute = currency(overallBet).distribute(playersIndexesWithLeastStrokesOverall.length);
                playersIndexesWithLeastStrokesOverall.forEach((playerIndex, index) => {
                    this.betsWonToPlayers[playerIndex].netOverallBetWon = moneyToDistribute[index].toString();
                });
            }
        }
    }

    calculateGrossBetResults() {
        // is nassau then calculate bet result for all three hole groups otherwise only overall
        if (this.getGameSetting(IS_NASSAU)) {
            const playersIndexesWithLeastStrokesFrontNine = this.getNassauGrossResults('FRONT_NINE');
            const playersIndexesWithLeastStrokesBackNine = this.getNassauGrossResults('BACK_NINE');
            const playersIndexesWithLeastStrokesOverall = this.getNassauGrossResults('OVERALL');

            let frontNineBet = this.getGameSetting(FRONT_NINE_BET);
            let backNineBet = this.getGameSetting(BACK_NINE_BET);
            let overallBet = this.getGameSetting(OVERALL_BET);
            // this.players.forEach(player => player.grossTotalBetWon = 0);
            if (frontNineBet) {
                if (playersIndexesWithLeastStrokesFrontNine.length > 0) {
                    const moneyToDistribute = currency(frontNineBet).distribute(playersIndexesWithLeastStrokesFrontNine.length);
                    playersIndexesWithLeastStrokesFrontNine.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].grossFrontNineBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].grossTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].grossTotalBetWon).toString();
                    });
                }

            }
            if (backNineBet) {
                if (playersIndexesWithLeastStrokesBackNine.length > 0) {
                    const moneyToDistribute = currency(backNineBet).distribute(playersIndexesWithLeastStrokesBackNine.length);
                    playersIndexesWithLeastStrokesBackNine.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].grossBackNineBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].grossTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].grossTotalBetWon).toString();
                    });
                }
            }
            if (overallBet) {
                if (playersIndexesWithLeastStrokesOverall.length > 0) {
                    const moneyToDistribute = currency(overallBet).distribute(playersIndexesWithLeastStrokesOverall.length);
                    playersIndexesWithLeastStrokesOverall.forEach((playerIndex, index) => {
                        this.betsWonToPlayers[playerIndex].grossOverallBetWon = moneyToDistribute[index].toString();
                        this.betsWonToPlayers[playerIndex].grossTotalBetWon = moneyToDistribute[index].add(this.betsWonToPlayers[playerIndex].grossTotalBetWon).toString();
                    });
                }

            }
        }
        else {
            let overallBet = this.getGameSetting(OVERALL_BET);
            const playersIndexesWithLeastStrokesOverall = this.getNassauGrossResults('OVERALL');
            if (playersIndexesWithLeastStrokesOverall.length > 0) {
                const moneyToDistribute = currency(overallBet).distribute(playersIndexesWithLeastStrokesOverall.length);
                playersIndexesWithLeastStrokesOverall.forEach((playerIndex, index) => {
                    this.betsWonToPlayers[playerIndex].grossOverallBetWon = moneyToDistribute[index].toString();
                });
            }
        }
    }

    getNassauGrossResults(holesGroup) {
        if (['BACK_NINE', 'FRONT_NINE', 'OVERALL'].includes(holesGroup)) {
            let leastStrokes = 0;
            if (holesGroup === 'FRONT_NINE') {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossFrontStrokes > b.grossFrontStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossFrontStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.grossFrontStrokes));
            }
            else if (holesGroup === 'BACK_NINE') {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossBackStrokes > b.grossBackStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossBackStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.grossBackStrokes));

            }
            else {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossTotalStrokes > b.grossTotalStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossTotalStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.grossTotalStrokes));

            }
            // now we have least strokes get all the players indexes who have least strokes
            let playersIndexesWithLeastStrokes = [];
            this.strokesToPlayers.forEach((player) => {
                if (holesGroup === 'FRONT_NINE') {
                    if (player.grossFrontStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }
                }
                else if (holesGroup === 'BACK_NINE') {
                    if (player.grossBackStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }

                }
                else {
                    if (player.grossTotalStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }

                }
            });
            return playersIndexesWithLeastStrokes;

        }
        return [];
    }

    getNassauNetResults(holesGroup) {
        if (['BACK_NINE', 'FRONT_NINE', 'OVERALL'].includes(holesGroup)) {
            let leastStrokes = 0;
            if (holesGroup === 'FRONT_NINE') {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossFrontStrokes > b.grossFrontStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossFrontStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.netFrontStrokes));
            }
            else if (holesGroup === 'BACK_NINE') {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossBackStrokes > b.grossBackStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossBackStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.netBackStrokes));

            }
            else {
                // playersWithLeastStrokesToHighest.sort((a, b) => (a.grossTotalStrokes > b.grossTotalStrokes ? 1 : -1));
                // leastStrokes = playersWithLeastStrokesToHighest[0].grossTotalStrokes;
                leastStrokes = Math.min(...this.strokesToPlayers.map(player => player.netTotalStrokes));

            }
            // now we have least strokes get all the players indexes who have least strokes
            let playersIndexesWithLeastStrokes = [];
            this.strokesToPlayers.forEach((player) => {
                if (holesGroup === 'FRONT_NINE') {
                    if (player.netFrontStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }
                }
                else if (holesGroup === 'BACK_NINE') {
                    if (player.netBackStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }

                }
                else {
                    if (player.netTotalStrokes === leastStrokes) {
                        playersIndexesWithLeastStrokes.push(player.playerIndex);
                    }

                }
            });
            return playersIndexesWithLeastStrokes;

        }
        return [];
    }

    calculateStrokesToPlayers() {
        // const holesValues = Object.values(this.scores);
        /*{
            playerIndex: 0,
            grossFrontStrokes: 4,
            grossBackStrokes: 8,
            grossTotalStrokes: 12,
            netFrontStrokes: 4,
            netBackStrokes: 8,
            netTotalStrokes: 12
        }*/
        const golfCourseParser = new GLooperObjectsDataExtractor({ golfCourse: this.golfCourse });
        const totalNumberOfHoles = golfCourseParser.getTotalNumberOfHolesFromGolfCourse();

        this.strokesToPlayers.forEach((strokesToPlayer) => {
            const playerIndex = strokesToPlayer.playerIndex;
            for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
                if (holeScores[playerIndex] && holeScores[playerIndex][STROKES]) {
                    const strokes = parseInt(holeScores[playerIndex][STROKES]);
                    if (strokes) {
                        const holeHandicap = golfCourseParser.getHoleHandicap(holeNumber);
                        const playerHandicap = this.players[playerIndex].courseHandicap;
                        const extraStrokesGiven = this.getExtraStrokesAvailableOnHole(playerHandicap, holeNumber);
                        const netStrokes = strokes - extraStrokesGiven;
                        strokesToPlayer.grossTotalStrokes += strokes;
                        strokesToPlayer.netTotalStrokes += netStrokes;
                        if (holeNumber <= 9) {
                            strokesToPlayer.grossFrontStrokes += strokes;
                            strokesToPlayer.netFrontStrokes += netStrokes;
                        }
                        else if (holeNumber <= 18) {
                            strokesToPlayer.grossBackStrokes += strokes;
                            strokesToPlayer.netBackStrokes += netStrokes;
                        }
                    }
                }
            }
            // finally calculate the net strokes also
            // strokesToPlayer.netTotalStrokes = strokesToPlayer.grossTotalStrokes - parseInt(this.players[playerIndex].courseHandicap);
        });
    }


    calculateLeaderboard() {
        this.calculateStrokesToPlayers();
        // we need two different leaderboards for gross strokes and net strokes
        // less strokes means up on the list
        let grossLeaderboard = [...this.strokesToPlayers];
        let netLeaderboard = [...this.strokesToPlayers];
        grossLeaderboard.sort((a, b) => (a.grossTotalStrokes > b.grossTotalStrokes ? 1 : -1));
        netLeaderboard.sort((a, b) => (a.netTotalStrokes > b.netTotalStrokes ? 1 : -1));

        this.grossLeaderboard = grossLeaderboard;
        this.netLeaderboard = netLeaderboard;
    }

    static getId() {
        return STROKE_PLAY;
    }

    static getGameSettingsFields(currentGameSettings) {
        if (currentGameSettings &&
            currentGameSettings[IS_NASSAU]) {
            return [IS_NASSAU, FRONT_NINE_BET, BACK_NINE_BET, OVERALL_BET];
        }
        return [IS_NASSAU, OVERALL_BET];
    }

    static requiredFields() {
        return [STROKES];
    }
}

StrokePlay.gameName = "StrokePlay";
StrokePlay.resultComponent = StrokePlayResult;
StrokePlay.description =
    "A simple game of Golf in which the total number of strokes is counted over one, or more rounds";

export default StrokePlay;
