import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PreviousRoundsList from "containers/rounds/PreviousRoundsList";
import { fetchRounds } from 'actions/roundActions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}));

function PreviousRoundsPage({ fetchRounds }) {
    const classes = useStyles();

    useEffect(() => {
        fetchRounds();
    }, [fetchRounds]);

    console.log("PreviousRoundsPage component rendered");

    return (
        <React.Fragment>
            <div className={classes.root}>
                <PreviousRoundsList />
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps = {
    fetchRounds
}

export default connect(null, mapDispatchToProps)(PreviousRoundsPage);
