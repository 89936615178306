import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Box } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { OOSLER_WOOSLER } from "Games/GamesManager";

const classes = (theme) => ({
    playerStatsBox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});

class OoslerWooslerResult extends Component {
    runGameLogic() {
        const gameInst = GamesManager.initGame(OOSLER_WOOSLER);
        gameInst.scores = this.props.scores;
        gameInst.players = this.props.players;
        gameInst.golfCourse = this.props.golfCourse;
        gameInst.holesSettings = this.props.holesSettings
        gameInst.runGame();
        return gameInst;
    }

    render() {

        const gameInst = this.runGameLogic();

        let leaderBoardArray = [];
        leaderBoardArray.push(["Name", "Points"]);
        gameInst.players.forEach((player) => {

            leaderBoardArray.push([player.name, player.points]);
        });

        return (
            <>
                <Box my={1}>
                    <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
        holesSettings: state.activeRound.payload.holesSettings
    };
};

export default connect(mapStateToProps)(withStyles(classes)(OoslerWooslerResult));
