import { CHANGE_APP_BAR_TEMP_TITLE, REMOVE_APP_BAR_TEMP_TITLE, SET_DARK_MODE } from "actions/actionTypes";

const initState = {
    appBar: {
        title: {
            default: "Caddie Games",
            temp: null,
        },
    },
    darkMode: false,
};

const appReducer = (state = initState, action) => {
    switch (action.type) {
        case CHANGE_APP_BAR_TEMP_TITLE:
            return { ...state, appBar: { ...state.appBar, title: { ...state.appBar.title, temp: action.payload } } };
        case REMOVE_APP_BAR_TEMP_TITLE:
            return { ...state, appBar: { ...state.appBar, title: { ...state.appBar.title, temp: null } } };
        case SET_DARK_MODE:
            return { ...state, darkMode: action.payload };
        default:
        // do nothing
    }
    return state;
};

export default appReducer;
