import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, Box } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { HI_LO } from "Games/GamesManager";

const classes = (theme) => ({
    playerStatsBox: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
});

class HiLo extends Component {
    runGameLogic() {
        const gameInst = GamesManager.initGame(HI_LO);
        gameInst.scores = this.props.scores;
        gameInst.players = this.props.players;
        gameInst.golfCourse = this.props.golfCourse;
        gameInst.runGame();
        return gameInst;
    }

    render() {
        console.log("HiLo rendering");

        const hiLoGameInst = this.runGameLogic();

        let leaderBoardArray = [];
        leaderBoardArray.push(["Team", "Gross Points", "Net Points"]);
        leaderBoardArray.push(["Team 1", hiLoGameInst.team1GrossPoints, hiLoGameInst.team1NetPoints]);
        leaderBoardArray.push(["Team 2", hiLoGameInst.team2GrossPoints, hiLoGameInst.team2NetPoints]);


        return (
            <>
                <Box my={1}>
                    <LeaderboardTable noPaper rowsWithColumns={leaderBoardArray} />
                </Box>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
    };
};

export default connect(mapStateToProps)(withStyles(classes)(HiLo));
