import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Layout from "components/layout/Layout";
import { useMediaQuery, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { blue, red, blueGrey, amber } from "@material-ui/core/colors";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { fetchUser, fetchCustomUserData } from "../../actions/authActions";
import { setDarkMode } from "../../actions/appActions";
import SWNotifier from "containers/SWNotifier";

function App(props) {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    const { dispatch, user, } = props;

    const theme = React.useMemo(
        () => {
            console.log(`Prefers Dark mode: ${prefersDarkMode}`);
            dispatch(setDarkMode(prefersDarkMode));
            return createMuiTheme({
                spacing: 8,
                palette: {
                    primary: blue,
                    background: {
                        default: prefersDarkMode ? blueGrey["900"] : blueGrey["50"]
                    },
                    secondary: amber,
                    error: red,
                    type: prefersDarkMode ? "dark" : "light",
                    // type: "light",
                    // type: "dark"
                }
            })
        },
        [prefersDarkMode, dispatch]
    );

    // componentdidmount fetch the things we need from our firestore database
    // we are using redux with 'redux-thunk' middleware
    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    // below will run once we have a auth user changed
    useEffect(() => {
        if (user) {
            dispatch(fetchCustomUserData(user));
        }
        // eslint-disable-next-line
    }, [user, dispatch]);

    const [openInstallOnIosPrompt, setOpenInstallOnIosPrompt] = useState(false);

    function promptInstallOnIos() {
        console.log("check if needs install on ios");
        let userAgent = window.navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(userAgent)) {
            if (!("standalone" in window.navigator && window.navigator.standalone)) {
                setOpenInstallOnIosPrompt(true);
            }
        }
    }

    function handleIosPromptClose() {
        setOpenInstallOnIosPrompt(false);
    }

    useEffect(() => {
        promptInstallOnIos();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Layout></Layout>

                <SWNotifier />

                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                    }}
                    open={openInstallOnIosPrompt}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        aria-describedby="ios-snackbar"
                        message={
                            <span id="ios-snackbar">
                                Install this app on your iOS device: tap <OpenInBrowserIcon /> and then Add to
                                homescreen
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleIosPromptClose}>
                                <CloseIcon />
                            </IconButton>
                        ]}
                    />
                </Snackbar>
            </ThemeProvider>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};

export default connect(
    mapStateToProps
)(App);
