import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

function AddEditPlayerDialog({ onClose, open, editPlayer, formRender: FormView }) {
    console.log("AddEditPlayerDialog is rendering");

    // ********* props **************
    // onClose(), takes a function which will be triggered dialog is attempting to close, function passed inside can take the final value of the edit or added player
    // editPlayer, player being edited if left empty dialog will behave as add player
    // open, if the dialog is open or close

    const handleCancel = () => {
        onClose(null);
    };

    const handleOnSubmit = (player) => {
        onClose(player);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleCancel}>
                {editPlayer ? <DialogTitle>Edit Player</DialogTitle> : <DialogTitle>Add Player</DialogTitle>}
                <DialogContent>
                    <FormView editPlayer={editPlayer} onReset={handleCancel} onSubmit={handleOnSubmit} />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default React.memo(AddEditPlayerDialog);
