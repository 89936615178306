import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

export default function AuthHOC(PassedComponent) {

    // this is a React HOC, react doesn't have the Pure Functional API for this so we will use 'class'
    class Auth extends React.PureComponent {

        // componentWillMount() {
        //     if (this.props.authenticated === null) {
        //       this.props.history.push("/");
        //     }
        //   }

        //   componentWillUpdate(nextProps) {
        //     if (!nextProps.authenticated) {
        //       this.props.history.push("/login");
        //     }
        //   }

        render() {
            if (this.props.user) {
                // we will send the props to the next component by appending the logged in user
                return <PassedComponent {...this.props} />
            }
            else {
                return <Redirect to="/" />;
            }
        }
    }

    // redux here
    const mapStateToProps = (state) => {
        return { user: state.auth.user };
    }

    return withRouter(connect(mapStateToProps)(Auth));
}

