import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { GamesEnum } from "../../Games/GamesManager";

const useStyles = makeStyles((theme) => ({
    golfCoursePaper: {
        padding: theme.spacing(2)
    },
    checkIcon: {
        color: theme.palette.primary.main
    },
    ticksFlexBox: {
        marginTop: theme.spacing(0.5),
        display:'flex', 
        flexFlow: 'row wrap', 
        justifyContent: 'flex-start', 
        alignItems: 'center'
    },
    ticksFlexBoxItemsDiv: {
        display: 'flex',
        marginRight: theme.spacing(1),
    }
}));

function RoundSetupHeader(props) {

    const styleClasses = useStyles();

    const selectedPlayersNames = () => {
        let playerNames = [];
        if (props.selectedPlayers && props.selectedPlayers.length > 0) {
            for (let player of props.selectedPlayers) {
                if (player.name) {
                    playerNames.push(player.name);
                }
            }
        }
        return playerNames;
    }

    const currentGamesNames = () => {
        let gameNames = [];
        if (props.currentGameIds) {
            for (let gameId of props.currentGameIds) {
                if (GamesEnum[gameId]) {
                    gameNames.push(GamesEnum[gameId].gameName);
                }
            }
        }
        return gameNames;
    }

    // http://stackoverflow.com/questions/45598854/passing-values-through-react-router-v4-link

    return (
        <React.Fragment>
            <Paper className={styleClasses.golfCoursePaper} square>
                    <React.Fragment>
                        {/* <Typography noWrap variant="h6">
                            {props.golfCourse.name ? props.golfCourse.name : "<No Golf Course>"}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {props.golfCourse.location}
                        </Typography> */}
                        <div className={styleClasses.ticksFlexBox}>
                            {
                                currentGamesNames().map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <div className={styleClasses.ticksFlexBoxItemsDiv}>
                                                <CheckIcon className={styleClasses.checkIcon} />
                                                <Typography noWrap variant="subtitle1">
                                                    {item}
                                                </Typography>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        { !props.disablePlayers &&
                        <div className={styleClasses.ticksFlexBox}>
                            {selectedPlayersNames().map((item, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <div className={styleClasses.ticksFlexBoxItemsDiv}>
                                                <CheckIcon className={styleClasses.checkIcon} />
                                                <Typography variant="subtitle1">{item}</Typography>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </div>
}
                    </React.Fragment>
            </Paper>
        </React.Fragment>
    );
}

// redux here
const mapStateToProps = state => {
    return {
        currentGameIds: state.newRound.selectedGameIds.payload,
        selectedPlayers: state.newRound.selectedPlayers
    };
};

export default connect(
    mapStateToProps)(RoundSetupHeader);