import React from "react";
import { connect } from "react-redux";
import { withStyles, Tab, AppBar, Tabs } from "@material-ui/core";
import HolePanel from "./HolePanel";
import {
    fetchActiveRound,
    setActiveRoundCurrentHole,
    resetActiveRoundPlayerBeingEditedIndex,
    updatePlayer,
} from "actions/activeRoundActions";
import { green } from "@material-ui/core/colors";
import SwipeableViews from "react-swipeable-views";
import AddEditPlayerDialog from "components/FavouritePlayers/AddEditPlayerDialog";
import EditPlayerFormOnHoleView from "components/EditPlayerFormOnHoleView";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const classes = (theme) => ({
    tabPanelContainer: {
        height: "100%",
        padding: theme.spacing(2, 0),
    },
    tabRoot: {
        minWidth: "0px",
        padding: "0",
        flexGrow: 1,
    },
    holeViewsContainer: {
        height: `calc(100% - ${theme.spacing(6)}px)`,
        backgroundImage: `linear-gradient(white, ${green["600"]})`,
    },
});

class RoundPlayView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tabsValue: 0,
        };
    }

    componentDidMount() {
        let tabsValue = 0;
        const { holesData, currentHole } = this.props;
        holesData.forEach((holeData, index) => {
            if (parseInt(holeData.hole_number) === parseInt(currentHole)) {
                tabsValue = index;
            }
        });
        this.setState({
            tabsValue: tabsValue,
        });
    }

    handleTabsOnChange = (event, newValue) => {
        this.setTabsValue(newValue);
    };

    setTabsValue = (newValue) => {
        this.setState({
            tabsValue: newValue,
        });
    };

    handleNextTabClick = () => {
        if (this.state.tabsValue < this.props.holesData.length - 1) {
            this.setTabsValue(this.state.tabsValue + 1);
        } else {
            this.setTabsValue(0);
        }
    };

    handlePreviousTabClick = () => {
        if (this.state.tabsValue > 0) {
            this.setTabsValue(this.state.tabsValue - 1);
        } else {
            this.setTabsValue(this.props.holesData.length - 1);
        }
    };

    renderTabsFromHolesData = (holesData) => {
        let rendered = holesData
            .map((hole, index) => {
                if (hole.hole_number) {
                    let label = hole.hole_number;
                    return (
                        <Tab
                            classes={{ root: this.props.classes.tabRoot }}
                            key={index}
                            label={label}
                            {...a11yProps(index)}
                        />
                    );
                }
                return null;
            })
            .filter((val) => val != null);
        return rendered;
    };

    renderTabsPanels = () => {
        const holesData = this.props.holesData;
        return holesData
            .map((hole, index) => {
                if (hole.hole_number) {
                    return (
                        <div
                            className={this.props.classes.tabPanelContainer}
                            key={index}
                            //hidden={this.state.tabsValue !== index}
                            role="tabpanel"
                            id={`scrollable-auto-tabpanel-${index}`}
                            aria-labelledby={`scrollable-auto-tab-${index}`}
                        >
                            {/* commenting this out, all the holepanels will be rendered on intial render for smooth swipes */}
                            {/* {this.state.tabsValue === index && ( */}
                            <HolePanel
                                holeIndex={index}
                                holeData={hole}
                                onPreviousHole={this.handlePreviousTabClick}
                                onNextHole={this.handleNextTabClick}
                            ></HolePanel>
                            {/* )} */}
                        </div>
                    );
                }
                return null;
            })
            .filter((val) => val != null);
    };

    handlePlayerEditDialogClose = (playerOrNull) => {
        // player is null means the dailog should be closed without changing anything
        const {
            players,
            playerBeingEditedIndex,
            roundId,
            resetActiveRoundPlayerBeingEditedIndex,
            updatePlayer,
        } = this.props;
        if (playerOrNull != null) {
            updatePlayer(playerBeingEditedIndex, playerOrNull, players, roundId);
        }
        resetActiveRoundPlayerBeingEditedIndex();
    };

    render() {
        console.log("RoundPlayView rendered");

        const { classes, holesData, players, playerBeingEditedIndex, teeSets } = this.props;

        const editPlayer = players[playerBeingEditedIndex];

        return (
            <>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.tabsValue}
                        onChange={this.handleTabsOnChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.renderTabsFromHolesData(holesData)}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    className={classes.holeViewsContainer}
                    containerStyle={{ height: "100%" }}
                    slideStyle={{ height: "100%" }}
                    index={this.state.tabsValue}
                    onChangeIndex={this.setTabsValue}
                >
                    {this.renderTabsPanels()}
                </SwipeableViews>

                <AddEditPlayerDialog
                    formRender={(props) => <EditPlayerFormOnHoleView {...props} teeSetOptions={teeSets} />}
                    open={playerBeingEditedIndex !== null ? true : false}
                    editPlayer={editPlayer}
                    onClose={this.handlePlayerEditDialogClose}
                />
            </>
        );
    }
}

// redux here
const mapStateToProps = (state) => {
    let holesData = [];
    if (state.activeRound.payload.golfCourse && state.activeRound.payload.golfCourse.holes_data) {
        holesData = state.activeRound.payload.golfCourse.holes_data;
    }
    let currentHole = 1;

    if (state.activeRound.payload.currentHole > 0 && state.state.activeRound.payload.currentHole < holesData.length + 1)
        currentHole = state.activeRound.payload.currentHole;

    let teeSets = [];
    if (state.activeRound.payload.golfCourse && state.activeRound.payload.golfCourse.tee_sets) {
        teeSets = state.activeRound.payload.golfCourse.tee_sets;
    }
    return {
        //holes_data: Array(18)
        // 0: {hole_name: "", hole_number: 1, pro_tip: ""}
        // 1: {hole_name: "", hole_number: 2, pro_tip: ""}
        holesData: holesData,
        currentHole: currentHole,
        playerBeingEditedIndex: state.activeRound.playerBeingEditedIndex,
        players: state.activeRound.payload.players,
        teeSets: teeSets,
        roundId: state.activeRound.payload.id,
    };
};

const mapDispatchToProps = {
    fetchActiveRound,
    setActiveRoundCurrentHole,
    resetActiveRoundPlayerBeingEditedIndex,
    updatePlayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(RoundPlayView));
