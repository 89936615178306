import NinesResult from "containers/GameResult/NinesResult";
import { STROKES } from "./FieldTypes";
import Game from "./Game";
import GamesManager, { HI_LO, NINES } from "./GamesManager";


class Nines extends Game {

    constructor(options = {}) {
        let superOptions = {
            ...options,
            name: Nines.gameName,
            description: Nines.description,
            beforeStartRequiredFields: Nines.getGameSettingsFields(),
            resultComponent: Nines.resultComponent,
            // holePlayerInfoComponent: StablefordInfo
        };
        super(superOptions);
    }

    runGame() {
        this.players.forEach(player => {
            player.grossPoints = 0;
            player.netPoints = 0;
        });
        holeScoresLoop:
        for (const [holeNumber, holeScores] of Object.entries(this.scores)) {
            const playersWithIndexesAndScores = Object.entries(holeScores);

            // lets only calculate when we have 3 players scores
            if (playersWithIndexesAndScores.length < 3) continue;

            for (const [playerIndex, playerScores] of playersWithIndexesAndScores) {
                if (!playerScores || !playerScores[STROKES] || playerScores[STROKES] === 0 || playerScores[STROKES] === "0") {
                    continue holeScoresLoop;
                }
            }

            this.calculatePointsForGross(playersWithIndexesAndScores);
            this.calculatePointsForNet(playersWithIndexesAndScores, holeNumber);
            
        }
    }

    calculatePointsForGross(playersWithIndexesAndScores) {
        // let sort these players with strokes vise
        playersWithIndexesAndScores.sort((player1Scores, player2Scores) => {
            return player1Scores[1][STROKES] - player2Scores[1][STROKES];
        });

        // all are equal strokes
        if ((playersWithIndexesAndScores[0][1][STROKES] === playersWithIndexesAndScores[1][1][STROKES]) && (playersWithIndexesAndScores[0][1][STROKES] === playersWithIndexesAndScores[2][1][STROKES])) {
            this.players.forEach(player => {
                player.grossPoints += 3
            });
            return;
        }

        // 1st and 2nd are tied
        if (playersWithIndexesAndScores[0][1][STROKES] === playersWithIndexesAndScores[1][1][STROKES]) {
            this.players[playersWithIndexesAndScores[0][0]].grossPoints += 4;
            this.players[playersWithIndexesAndScores[1][0]].grossPoints += 4;
            this.players[playersWithIndexesAndScores[2][0]].grossPoints += 1;
            return;
        }

        // 2nd and third are tied
        if (playersWithIndexesAndScores[1][1][STROKES] === playersWithIndexesAndScores[2][1][STROKES]) {
            this.players[playersWithIndexesAndScores[0][0]].grossPoints += 5;
            this.players[playersWithIndexesAndScores[1][0]].grossPoints += 2;
            this.players[playersWithIndexesAndScores[2][0]].grossPoints += 2;
            return;
        }

        this.players[playersWithIndexesAndScores[0][0]].grossPoints += 5;
        this.players[playersWithIndexesAndScores[1][0]].grossPoints += 3;
        this.players[playersWithIndexesAndScores[2][0]].grossPoints += 1;
    }

    calculatePointsForNet(playersWithIndexesAndScores, holeNumber) {

        const playersWithIndexesAndNetStrokes = playersWithIndexesAndScores.map(playerWithIndexAndScores => {
            return [playerWithIndexAndScores[0], this.getPlayerNetStrokes(holeNumber, playerWithIndexAndScores)];
        });
         // let sort these players with strokes vise
         playersWithIndexesAndNetStrokes.sort((player1Scores, player2Scores) => {
            return player1Scores[1] - player2Scores[1];
        });

        // all are equal strokes
        if ((playersWithIndexesAndNetStrokes[0][1] === playersWithIndexesAndNetStrokes[1][1]) && (playersWithIndexesAndNetStrokes[0][1] === playersWithIndexesAndNetStrokes[2][1])) {
            this.players.forEach(player => {
                player.netPoints += 3
            });
            return;
        }

        // 1st and 2nd are tied
        if (playersWithIndexesAndNetStrokes[0][1] === playersWithIndexesAndNetStrokes[1][1]) {
            this.players[playersWithIndexesAndNetStrokes[0][0]].netPoints += 4;
            this.players[playersWithIndexesAndNetStrokes[1][0]].netPoints += 4;
            this.players[playersWithIndexesAndNetStrokes[2][0]].netPoints += 1;
            return;
        }

        // 2nd and third are tied
        if (playersWithIndexesAndNetStrokes[1][1] === playersWithIndexesAndNetStrokes[2][1]) {
            this.players[playersWithIndexesAndNetStrokes[0][0]].netPoints += 5;
            this.players[playersWithIndexesAndNetStrokes[1][0]].netPoints += 2;
            this.players[playersWithIndexesAndNetStrokes[2][0]].netPoints += 2;
            return;
        }

        this.players[playersWithIndexesAndNetStrokes[0][0]].netPoints += 5;
        this.players[playersWithIndexesAndNetStrokes[1][0]].netPoints += 3;
        this.players[playersWithIndexesAndNetStrokes[2][0]].netPoints += 1;
    }

    getPlayerNetStrokes(holeNumber, playerWithIndexAndScores) {
        return playerWithIndexAndScores[1][STROKES] - this.getExtraStrokesAvailableOnHole(this.players[playerWithIndexAndScores[0]].courseHandicap, holeNumber);
    }

    // static methods
    static getId() {
        return NINES;
    }

    static info() {
        let namesOfNonCompatibleGames = [];
        this.nonCompatibleGames().forEach(gameId => {
            const game = GamesManager.getGame(gameId);
            if(game) {
                namesOfNonCompatibleGames.push(game.gameName);
            }
        });
        const nonCompatibleGamesString = namesOfNonCompatibleGames.join(", ");
        return ["You would only be able to select 3 players to play this game", "Not compatible with these games: " + nonCompatibleGamesString];
    }

    static nonCompatibleGames() {
        return [HI_LO];
    }

    static numberOfPlayersRequired() {
        return 3;
    }

    static requiredFields() {
        return [STROKES];
    }
}
Nines.gameName = "Nines";
Nines.description = "This is Nines game";
Nines.resultComponent = NinesResult
export default Nines;