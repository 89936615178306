import AppNotifier from "./AppNotifier"

class NetworkDetector {
    
    detectOffline = () => {
        window.addEventListener('offline', event => {
            AppNotifier.notify("Offline mode. Connection Lost", { variant: 'error' });
        });
    }

    detectOnline = () => {
        window.addEventListener('online', event => AppNotifier.notify("Back Online!", { variant: 'success' })
        );
    }

    enable = () => {
        this.detectOffline();
        this.detectOnline();
    }
}

export default new NetworkDetector();