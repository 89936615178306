import { combineReducers } from "redux";
import app from "./appReducer";
import auth from "./authReducer";
import activeRound from "./activeRoundReducer2";
import favouritePlayers from "./favouritePlayersReducer";
import newRound from "./newRoundReducer";
import recentGolfCourses from "./recentGolfCoursesReducer";
import rounds from "./roundsReducer";
import { SIGNOUT_USER, SW_UPDATE } from 'actions/actionTypes';
import { createReducer } from "utils/reduxHelpers";

const serviceWorkerReducer = createReducer({ serviceWorkerUpdated: false, serviceWorkerRegistration: null },
  {
    [SW_UPDATE]: (state, action) => ({ serviceWorkerUpdated: true, serviceWorkerRegistration: action.payload })
  }
)

const appReducer = combineReducers({
    app,
    auth,
    activeRound,
    favouritePlayers,
    newRound,
    recentGolfCourses,
    rounds,
    serviceWorker: serviceWorkerReducer
});



const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === SIGNOUT_USER) {
      // we keep a reference of the keys we want to maintain
      // other keys will be passed as undefined and this will call
      // reducers with an initial state
      const { app } = state;
  
      state = { app };
    }
  
    return appReducer(state, action);
  };

  export default rootReducer;