import { Fab, TableCell, styled, withStyles } from "@material-ui/core";
import React from 'react';

export const BottomRightFab = styled(Fab)(({ theme }) => ({
    margin: 0,
    top: "auto",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    left: "auto",
    position: "fixed"
}));


export const ExtraSmallTableCell = withStyles(theme => ({
    paddingSmall: {
        padding: theme.spacing(0.75)
    }
}))
    (
        ({ classes: { paddingSmall, ...passClasses }, ...props }) => {
            return <TableCell className={paddingSmall} classes={passClasses} {...props} />
        }
    );
