import React from "react";
import { connect } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import LeaderboardTable from "../../components/PlayRound/LeaderboardTable";
import GamesManager, { STROKE_PLAY } from "Games/GamesManager";
import WinnerIcon from "@material-ui/icons/EmojiEvents";
import { IS_NASSAU } from "Games/FieldTypes";
import StrokeplayBetTable from "components/PlayRound/StrokeplayBetTable";

function StrokePlayResult({ scores, players, gameSettings, golfCourse }) {

    console.log("StrokePlayResult rendering");

    function runGameLogic() {
        const gameInst = GamesManager.initGame(STROKE_PLAY, {
            scores: scores, players: players,
            gamesSettings: gameSettings,
            golfCourse: golfCourse,
        });
        gameInst.calculateLeaderboard();
        gameInst.calculateBetResults();
        return gameInst;
    }

    const strokePlay = runGameLogic();
    const isNassau = strokePlay.getGameSetting(IS_NASSAU);

    let grossLeaderboard = [];
    grossLeaderboard.push(["Name", "Front", "Back", "Gross Strokes"]);

    let netLeaderboard = [];
    netLeaderboard.push(["Name", "Front", "Back", "Net Strokes"]);

    let grossWinnerPlayerName = null;
    let netWinnerPlayerName = null;

    if (strokePlay.netLeaderboard) {
        if (strokePlay.netLeaderboard[0]) netWinnerPlayerName = players[strokePlay.netLeaderboard[0].playerIndex].name;
        strokePlay.netLeaderboard.forEach((playerIndexWithStrokes) => {
            netLeaderboard.push([players[playerIndexWithStrokes.playerIndex].name, playerIndexWithStrokes.netFrontStrokes, playerIndexWithStrokes.netBackStrokes, playerIndexWithStrokes.netTotalStrokes]);
        });
    }
    if (strokePlay.grossLeaderboard) {
        if (strokePlay.grossLeaderboard[0]) grossWinnerPlayerName = players[strokePlay.grossLeaderboard[0].playerIndex].name;
        strokePlay.grossLeaderboard.forEach((playerIndexWithStrokes) => {
            grossLeaderboard.push([players[playerIndexWithStrokes.playerIndex].name, playerIndexWithStrokes.grossFrontStrokes, playerIndexWithStrokes.grossBackStrokes, playerIndexWithStrokes.grossTotalStrokes]);
        });
    }
    return (
        <>
            <Box my={1}>
                <Box fontWeight="fontWeightBold"><Typography>Gross</Typography></Box>
                <LeaderboardTable noPaper rowsWithColumns={grossLeaderboard} />
                <Typography color="textPrimary">
                    <WinnerIcon /> Winner is {grossWinnerPlayerName}
                </Typography>
            </Box>
            <Box my={1}>
                <Box fontWeight="fontWeightBold"><Typography>Net</Typography></Box>
                <LeaderboardTable noPaper rowsWithColumns={netLeaderboard} />
                <Typography color="textPrimary">
                    <WinnerIcon /> Winner is {netWinnerPlayerName}
                </Typography>
            </Box>
            <Box mt={2} mb={1}>
                <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                    Bets</Box>
                </Typography>
                <Typography component="div" align="center"><Box fontWeight="fontWeightBold">
                    Nassau: {isNassau ? 'On' : 'Off'}</Box>
                </Typography>
                <Box my={1}>
                    <Typography>Gross</Typography>
                    <StrokeplayBetTable strokeplayInstance={strokePlay} isNassau={isNassau} />
                </Box>
                <Box my={1}>
                    <Typography>Net</Typography>
                    <StrokeplayBetTable strokeplayInstance={strokePlay} isNassau={isNassau} isNetTable />
                </Box>
            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        players: state.activeRound.payload.players,
        gameSettings: state.activeRound.payload.gamesSettings,
        golfCourse: state.activeRound.payload.golfCourse
    };
};

export default connect(mapStateToProps)(StrokePlayResult);