import { StrokesInput, PuttsInput } from '../components/NewGolfInputFields';
import BetAmountPerHoleInput from 'components/NewGolfInputFields/BetAmountPerHoleInput.js';
import SkinsFormatSwitch from 'components/NewGolfInputFields/SkinsFormatSwitch';
import BetInput from 'components/NewGolfInputFields/BetInput';
import IsNassau from 'components/NewGolfInputFields/IsNassau';
import OoslerStealPlayerSelect from 'containers/rounds/OoslerStealPlayerSelect';
import OoslerPlayerSelect from 'containers/rounds/OoslerPlayerSelect';
import HasBunkerBonusPointCheckbox from 'components/NewGolfInputFields/HasBunkerBonusPointCheckbox';
// import React from 'react';

//TODO: make this into a class which takes field type and makes instance

export const IS_NASSAU = "IS_NASSAU";
export const FRONT_NINE_BET = "FRONT_NINE_BET";
export const BACK_NINE_BET = "BACK_NINE_BET";
export const OVERALL_BET = "OVERALL_BET";
export const PUTTS = 'PUTTS';
export const OOSLER_STEAL_PLAYER_INDEX = "OOSLER_STEAL_PLAYER_INDEX";
export const OOSLER_PLAYER_INDEX = "OOSLER_PLAYER_INDEX";
export const HAS_BUNKER_BONUS_POINT = "HAS_BUNKER_BONUS_POINT";
export const CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS = "CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS";
export const CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET = "CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET";
export const POT_PLAYER_BET_GROSS = "POT_PLAYER_BET_GROSS";
export const POT_PLAYER_BET_NET = "POT_PLAYER_BET_NET";
export const STROKES = "STROKES";

export const inputTypes = {
    INTEGER: "integer",
    STRING: "string"
}

// const playerHandicap = {
//     name: "Player Handicap",
//     type: inputTypes.INTEGER,
// }

const strokes = {
    name: "Strokes",
    type: inputTypes.INTEGER,
    componentRender: StrokesInput,
    checkAndClean: input => {}
}

const hasBunkerBonusPoint = {
    name: "Bunker Bonus",
    componentRender: HasBunkerBonusPointCheckbox
}

const ooslerPlayerIndex = {
    name: 'Oosler',
    componentRender: OoslerPlayerSelect
};

const ooslerStealPlayerIndex = {
    name: "Oosler Stealing Player",
    componentRender: OoslerStealPlayerSelect
}

const betAmountPerHole = {
    name: "Bet Amount Per Hole",
    componentRender: BetAmountPerHoleInput,
    defaultValue: 1
}

const skinsFormat = {
    name: "Format",
    componentRender: SkinsFormatSwitch,
    defaultValue: "POT"
};

const carryForwardPlayerBetPerHole = {
    name: "Player Bet Each Hole",
    componentRender: BetInput,
    defaultValue: 1
}

const carryForwardPlayerBetPerHoleNet = {
    name: "Player Bet Each Hole(Net)",
    componentRender: BetInput,
    defaultValue: 1
}

const carryForwardPlayerBetPerHoleGross = {
    name: "Player Bet Each Hole(Gross)",
    componentRender: BetInput,
    defaultValue: 1
}

const potPlayerBet = {
    name: "Each Player Bet",
    componentRender: BetInput,
    defaultValue: 1
}

const potPlayerBetGross = {
    name: "Each Player Bet(Gross)",
    componentRender: BetInput,
    defaultValue: 1
}

const potPlayerBetNet = {
    name: "Each Player Bet(Net)",
    componentRender: BetInput,
    defaultValue: 1
}

const isNassau = {
    name: "Nassau",
    componentRender: IsNassau
}

const frontNineBet = {
    name: "Front Nine Bet",
    componentRender: BetInput,
    defaultValue: 1
}

const backNineBet = {
    name: "Back Nine Bet",
    componentRender: BetInput,
    defaultValue: 1
}

const overallBet = {
    name: "Overall Bet",
    componentRender: BetInput,
    defaultValue: 1
}

const putts = {
    name: "Putts",
    componentRender: PuttsInput,
    defaultValue: 0
}

const FieldsEnum = {
    [STROKES]: strokes,
    "BET_AMOUNT_PER_HOLE": betAmountPerHole,
    "SKINS_FORMAT": skinsFormat,
    "CARRY_FORWARD_PLAYER_BET_PER_HOLE": carryForwardPlayerBetPerHole,
    [CARRY_FORWARD_PLAYER_BET_PER_HOLE_GROSS]: carryForwardPlayerBetPerHoleGross,
    [CARRY_FORWARD_PLAYER_BET_PER_HOLE_NET]: carryForwardPlayerBetPerHoleNet,
    "POT_PLAYER_BET": potPlayerBet,
    [POT_PLAYER_BET_GROSS]: potPlayerBetGross,
    [POT_PLAYER_BET_NET]: potPlayerBetNet,
    [HAS_BUNKER_BONUS_POINT]: hasBunkerBonusPoint,
    [OOSLER_STEAL_PLAYER_INDEX]: ooslerStealPlayerIndex,
    [OOSLER_PLAYER_INDEX]: ooslerPlayerIndex,
    [IS_NASSAU]: isNassau,
    [FRONT_NINE_BET]: frontNineBet,
    [BACK_NINE_BET]: backNineBet,
    [OVERALL_BET]: overallBet,
    [PUTTS]: putts
    // "PLAYER_HANDICAP": playerHandicap
}

export const getField = fieldKey => {
    return FieldsEnum[fieldKey] ? FieldsEnum[fieldKey] : null;
}