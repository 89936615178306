import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function IsNassau({ name, value, onValueChange }) {

    const handleInputChange = evt => {
        if (evt.target.checked) {
            onValueChange(true);
        }
        else {
            onValueChange(false);
        }
    };

    return (
        <React.Fragment>
            <FormControlLabel
                control={<Checkbox checked={!!value} size="small" onChange={handleInputChange} />}
                label={name}
            />
        </React.Fragment>
    );
}

export default IsNassau;
