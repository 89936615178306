import React from "react";
import NavBar from "./NavBar";
import { makeStyles } from "@material-ui/core";
import MainRouter from "components/MainRouter";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Notifier from "./Notifier";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        // display: 'flex',
        // flexDirection: 'column',
        overflow: "hidden",
        height: "100vh",
        width: "100vw"
        // color: 'white',
    },
    snackbarStyles: {
        success: { backgroundColor: theme.palette.primary },
        error: { backgroundColor: theme.palette.error },
        info: { backgroundColor: "blue" }
    },
    viewContainer: {
        overflow: "auto",
        // flexGrow: 1,
        height: "calc(100% - 56px)",
        width: "100%"
    }
}));

function Layout(props) {
    const styleClasses = useStyles();

    // return our app layout if authentication checked or otherwise spinner
    if (props.authCheckedOnStart > 0) {
        return (
            <React.Fragment>
                {/* <SnackbarProvider
                    autoHideDuration={3000}
                    maxSnack={3}
                    dense
                    classes={{
                        variantSuccess: styleClasses.snackbarStyles.success,
                        variantError: styleClasses.snackbarStyles.error,
                        variantInfo: styleClasses.snackbarStyles.info
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                > */}
                <BrowserRouter>
                    {/* main box make full width and height */}
                    <div className={styleClasses.mainContainer}>
                        {props.user && <NavBar></NavBar>}
                        <main className={styleClasses.viewContainer}>
                            <MainRouter />
                        </main>
                    </div>
                </BrowserRouter>
                <Notifier />
                {/* </SnackbarProvider> */}
            </React.Fragment>
        );
    }
    return (
        <div>
            <CircularProgress variant="determinate" />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authCheckedOnStart: state.auth.timesChecked
    };
};

export default connect(mapStateToProps)(React.memo(Layout));
