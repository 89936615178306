import { roundsCollectionRef } from "../firebase/collectionRefs";
import {
    FETCH_ACTIVE_ROUND_SUCCESS,
    FETCH_ACTIVE_ROUND_BEGIN,
    SET_ACTIVE_ROUND_CURRENT_HOLE,
    UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD,
    SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
    RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED,
    UPDATE_ACTIVE_ROUND_PLAYERS,
    UPDATE_ACTIVE_ROUND_HOLE_SETTINGS,
    UPDATE_SCORES_FOR_HOLE
} from "actions/actionTypes";
import { combineReducers } from "redux";
import { createReducer } from "utils/reduxHelpers";


/*
   just a note for Ravi to remember, do this - {
        payload: {take out the players from here as they have separate state},
        isLoading,
        players: {
            payload,
            isloading
        }
    }
    or keep this simple, if a request to update etc fails we can revert the state. we don't need we can just show an error and done
    {
        payload,
        isLoading,
    }
*/
const payloadReducer = createReducer({
    id: null,
    golfCourse: {},
    currentHole: 0,
    scores: {},
    gameIds: [],
    players: [],
    gameInstances: [],
    holesSettings: {},
    gamesSettings: {},
}, {
    [FETCH_ACTIVE_ROUND_SUCCESS]: (state, action) => ({ ...state, ...action.payload }),

    [UPDATE_SCORES_FOR_HOLE]: (state, action) => {
        let scores = state.scores;

            let [roundId, holeNumber, holeScores] = action.payload;
           
            let newObj = {
                ...scores,
                [holeNumber]: {
                    ...holeScores
                },
            };

            // check if timeout token there clear it
            if (updatePlayerScoreTimerToken) clearTimeout(updatePlayerScoreTimerToken);

            // set another timeout with timer of 4 secs
            updatePlayerScoreTimerToken = setTimeout(() => {
                // update the scores in the database
                updatePlayerScoreDb(roundId, newObj);
            }, 4000);
            return { ...state, scores: newObj };
    },

    [UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD]: (state, action) => {
        let scores = state.scores;

            let [holeNumber, playerIndex, scoreInputKey, score, roundId] = action.payload;
            if (!scores[holeNumber]) {
                scores[holeNumber] = {};
            }
            if (!scores[holeNumber][playerIndex]) {
                scores[holeNumber][playerIndex] = {};
            }
            if (scores[holeNumber][playerIndex][scoreInputKey] === undefined) {
                scores[holeNumber][playerIndex][scoreInputKey] = null;
            }
            let newObj = {
                ...scores,
                [holeNumber]: {
                    ...scores[holeNumber],
                    [playerIndex]: { ...scores[holeNumber][playerIndex], [scoreInputKey]: score },
                },
            };

            // check if timeout token there clear it
            if (updatePlayerScoreTimerToken) clearTimeout(updatePlayerScoreTimerToken);

            // set another timeout with timer of 4 secs
            updatePlayerScoreTimerToken = setTimeout(() => {
                // update the scores in the database
                updatePlayerScoreDb(roundId, newObj);
            }, 4000);
            return { ...state, scores: newObj };
    },

    [UPDATE_ACTIVE_ROUND_HOLE_SETTINGS]: (state, action) => ({...state, holesSettings: {...action.payload}}),

    [UPDATE_ACTIVE_ROUND_PLAYERS]: (state, action) => ({...state, players: [...action.payload]}),

    [SET_ACTIVE_ROUND_CURRENT_HOLE]: (state, action) => ({ ...state, currentHole: action.payload })

});

const isLoadingReducer = createReducer(false, {
    [FETCH_ACTIVE_ROUND_SUCCESS]: (state, action) => true,
    [FETCH_ACTIVE_ROUND_BEGIN]: (state, action) => false
});

const playerBeingEditedIndex = createReducer(null, {
    [SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED]:(state, action) => action.payload,
    [RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED]:(state, action) => null
})

const activeRoundReducer = combineReducers({
    payload: payloadReducer,
    isLoading: isLoadingReducer,
    playerBeingEditedIndex: playerBeingEditedIndex
});

let updatePlayerScoreTimerToken = null;
const updatePlayerScoreDb = (roundId, newScores) => {
    if (roundId && newScores) {
        roundsCollectionRef
            .doc(roundId)
            .set(
                {
                    scores: newScores,
                },
                { merge: true }
            )
            .then((roundRef) => {
                console.log("scores synced succesfully");
            })
            .catch((err) => {
                console.log("Unable to update scores for current round", err);
            });
    }
};

/*
function addScore(obj, args) {
    // if last argument then return it
    if (args.length == 1) return args[0];

    // take out the first argument
    let firstArg = args[0];
    // remove it from the array
    args.shift();

    if (obj.hasOwnProperty(firstArg)) {
        // check if property exist, then recurse through it adding value or property
        obj[firstArg] = addScore(obj[firstArg], args);
        return obj;
    } else {
        // empty object if property doesn't exist
        obj[firstArg] = addScore({}, args);
        return obj;
    }
}
*/

export default activeRoundReducer;
