import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemIcon,
    IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { updateFavouritePlayers, fetchFavouritePlayers } from "actions/favouritePlayersActions";
import { BottomRightFab } from "../utils/StyledMaterialComponents";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AddEditPlayerDialog from "./AddEditPlayerDialog";
import AddEditPlayerForm from "./AddEditFavouritePlayerForm";
import CenteredCircularProgress from "components/utils/CenteredCircularProgress";

const useStyles = makeStyles((theme) => ({
    tablePaper: {
        backgroundColor: theme.palette.background.paper,
        maxHeight: `calc(100% - ${theme.spacing(5) * 2}px)`,
        margin: theme.spacing(5, 2),
        overflow: "scroll",
    },
    mainGrid: {
        padding: theme.spacing(2),
    },
}));

function FavouritePlayers({
    favouritePlayers,
    updateFavouritePlayers,
    currentUser,
    fetchFavouritePlayers,
    playersIsLoading,
}) {
    console.log("FavouritePlayers is rendering");
    const styleClasses = useStyles();

    // const [localFavouritePlayers, setLocalFavouritePlayers] = useState([]);

    const [openAddEditDialog, setOpenAddEditDialog] = useState(false);

    const [editPlayer, setEditPlayer] = useState(null);

    useEffect(() => {
        fetchFavouritePlayers(currentUser);
    }, [currentUser, fetchFavouritePlayers]);

    const handleAddIconOnClick = () => {
        setEditPlayer(null);
        setOpenAddEditDialog(true);
    };

    const playersChanged = (newPlayersArray) => {
        // setLocalFavouritePlayers(newPlayersArray);
        updateFavouritePlayers(newPlayersArray);
    };

    const handleDialogClose = (newPlayerDetails) => {
        if (newPlayerDetails) {
            if (editPlayer != null) {
                let changedPlayers = favouritePlayers;
                changedPlayers[editPlayer] = newPlayerDetails;
                playersChanged(changedPlayers);
            } else {
                playersChanged([...favouritePlayers, newPlayerDetails]);
            }
        }
        setEditPlayer(null);
        setOpenAddEditDialog(false);
    };

    const handleEditClick = (playerKey) => {
        setEditPlayer(playerKey);
        setOpenAddEditDialog(true);
    };

    const handleDeleteClick = (playerKey) => {
        let newPlayersArray = [...favouritePlayers.slice(0, playerKey), ...favouritePlayers.slice(++playerKey)];
        playersChanged(newPlayersArray);
    };

    // const handleClearChangesClick = () => {
    //     setLocalFavouritePlayers(props.favouritePlayers);
    // };

    // useEffect(() => {
    //     // if (props.favouritePlayers.length > 0)
    //     setLocalFavouritePlayers(props.favouritePlayers);
    // }, [props.favouritePlayers]);

    return (
        <React.Fragment>
            {/* <Grid container direction="column" className={styleClasses.mainGrid} spacing={2}>
                <Grid item xs={12}> */}
            {/* <ButtonGroup variant="contained" size="small" color="primary"> */}
            {/* <Button
                            to="/dashboard"
                            component={Link}
                            onClick={() => {
                                props.saveFavouritePlayers(props.currentUser, localFavouritePlayers);
                            }}
                        >
                            <SaveIcon />
                            Save Changes
                        </Button> */}
            {/* <Button onClick={handleClearChangesClick}>
                            <ClearIcon />
                            Clear Changes
                        </Button> */}
            {/* </ButtonGroup> */}
            {/* </Grid> */}
            {/* <Grid item xs={12}> */}
            <Paper className={styleClasses.tablePaper}>
                {playersIsLoading ? (
                    <CenteredCircularProgress />
                ) : (
                    <List>
                        {favouritePlayers.map((row, key) => (
                            // https://material-ui.com/components/lists/#align-list-items
                            <ListItem key={key} dense alignItems="flex-start">
                                <ListItemIcon>
                                    <IconButton edge="start" onClick={() => handleEditClick(key)}>
                                        <EditIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography noWrap>{row.name}</Typography>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                display="block"
                                                component="span"
                                                noWrap
                                                variant="body2"
                                                color="textPrimary"
                                            >
                                                {row.email}
                                            </Typography>
                                            {"Handicap Index: " + row.handicapIndex}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" onClick={() => handleDeleteClick(key)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        {favouritePlayers.length === 0 && (
                            <ListItem>
                                <ListItemText>No players found</ListItemText>
                            </ListItem>
                        )}
                    </List>
                )}

                <AddEditPlayerDialog
                    editPlayer={editPlayer != null ? favouritePlayers[editPlayer] : null}
                    formRender={AddEditPlayerForm}
                    open={openAddEditDialog}
                    onClose={handleDialogClose}
                />
            </Paper>
            {/* </Grid> */}
            {/* </Grid> */}

            <BottomRightFab color="secondary">
                <AddIcon onClick={handleAddIconOnClick} />
            </BottomRightFab>
        </React.Fragment>
    );
}

// redux here
const mapStateToProps = (state) => {
    return {
        favouritePlayers: state.favouritePlayers.payload,
        playersIsLoading: state.favouritePlayers.isLoading,
        currentUser: state.auth.user,
    };
};

const mapDispatchToProps = {
    updateFavouritePlayers,
    fetchFavouritePlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritePlayers);
