import React from "react";
import { connect } from "react-redux";
import { TableRow, makeStyles } from "@material-ui/core";
import { StyledTableCell, FixedOrNormalTableCell } from "./RoundScorecardTable";
import NameInitialsAvatar from "components/NameInitialsAvatar";
import GLooperObjectsDataExtractor from "GLooperObjectsDataExtractor";
import { STROKES } from "Games/FieldTypes";

const useStyles = makeStyles((theme) => ({
    activeAvatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        backgroundColor: theme.palette.type === "light" ? theme.palette.secondary.light : theme.palette.secondary.dark,
    },
    nameTableCell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    teeSetNameCell: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    }
}));

const RoundScorecardTableRow = ({ playerIndex, player, scores, golfCourse, isParRow, isNonFixedTableCellsWidthBreakpoint }) => {
    console.log("RoundTableScorecardPlayerRow component rendering");

    const numberOfHoles = golfCourse.holes;
    const classes = useStyles();

    const getPlayerScoresTableCells = () => {
        let renderArray = [];
        let outScores = 0;
        let inScores = 0;
        for (let i = 1; i <= numberOfHoles; i++) {
            if (scores[i] && scores[i][playerIndex] && scores[i][playerIndex][STROKES]) {
                if (i <= 9) {
                    outScores += parseInt(scores[i][playerIndex][STROKES]);
                } else if (i <= 18) {
                    inScores += parseInt(scores[i][playerIndex][STROKES]);
                }
                renderArray.push(<StyledTableCell key={i}>{scores[i][playerIndex][STROKES]}</StyledTableCell>);
            } else renderArray.push(<StyledTableCell key={i}>-</StyledTableCell>);
        }
        // // after ninth hole add 'OUT' cell, check if there are nine holes
        // if (numberOfHoles >= 9)
        //     renderArray.splice(
        //         9,
        //         0,
        //         <StyledTableCell key="OUT">
        //             <b>{outScores}</b>
        //         </StyledTableCell>
        //     );
        // // after 18th hole add 'IN' cell, check if there are 18 holes
        // if (numberOfHoles == 18)
        //     renderArray.splice(
        //         19,
        //         0,
        //         <StyledTableCell key="IN">
        //             <b>{inScores}</b>
        //         </StyledTableCell>
        //     );

        renderArray.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} cellWidth={5} positionFromRight={5} key="OUT">
                <b>{outScores}</b>
            </FixedOrNormalTableCell>
        );

        renderArray.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="IN" cellWidth={5} positionFromRight={4}>
                <b>{inScores}</b>
            </FixedOrNormalTableCell>
        );

        const totalScores = outScores + inScores;
        // total column
        renderArray.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="TOT" cellWidth={5} positionFromRight={3}>
                <b>{totalScores}</b>
            </FixedOrNormalTableCell>
        );
        // player handicap
        renderArray.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="HCP" cellWidth={5} positionFromRight={2}>
                <b>{player.courseHandicap}</b>
            </FixedOrNormalTableCell>
        );
        renderArray.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="NET" cellWidth={5} positionFromRight={1}>
                <b>{totalScores - player.courseHandicap}</b>
            </FixedOrNormalTableCell>
        );
        return renderArray;
    };

    const golfCourseExtractor = new GLooperObjectsDataExtractor({ golfCourse: golfCourse });

    // return template
    if (isParRow) {
        const getParTableCells = () => {
            let renderArray = [];
            let outPar = 0;
            let inPar = 0;
            for (let i = 1; i <= numberOfHoles; i++) {
                let cellToAdd = <StyledTableCell key={i}>-</StyledTableCell>;
                const par = golfCourseExtractor.getParFromGolfCourse(i);
                if (par) {
                    cellToAdd = <StyledTableCell key={i}>{par}</StyledTableCell>;
                    if (i <= 9) {
                        outPar += parseInt(par);
                    }
                    else if (i <= 18) {
                        inPar += parseInt(par);
                    }
                }
                renderArray.push(cellToAdd);
            }
            // // after ninth hole add 'OUT' cell, check if there are nine holes
            // if (numberOfHoles >= 9)
            //     renderArray.splice(
            //         9,
            //         0,
            //         <StyledTableCell key="OUT">
            //             <b>{outPar}</b>
            //         </StyledTableCell>
            //     );
            // // after 18th hole add 'IN' cell, check if there are 18 holes
            // if (numberOfHoles == 18)
            //     renderArray.splice(
            //         19,
            //         0,
            //         <StyledTableCell key="IN">
            //             <b>{inPar}</b>
            //         </StyledTableCell>
            //     );

            renderArray.push(
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} cellWidth={5} positionFromRight={5} key="OUT">
                    <b>{outPar}</b>
                </FixedOrNormalTableCell>
            );

            renderArray.push(
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="IN" cellWidth={5} positionFromRight={4}>
                    <b>{inPar}</b>
                </FixedOrNormalTableCell>
            );

            const totalScores = outPar + inPar;
            // total column
            renderArray.push(
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="TOT" cellWidth={5} positionFromRight={3}>
                    <b>{totalScores}</b>
                </FixedOrNormalTableCell>
            );
            // player handicap
            renderArray.push(
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="HCP" cellWidth={5} positionFromRight={2}>
                    -
            </FixedOrNormalTableCell>
            );
            renderArray.push(
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} key="NET" cellWidth={5} positionFromRight={1}>
                    -
            </FixedOrNormalTableCell>
            );
            return renderArray;
        };
        return (
            <TableRow>
                <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} cellWidth={7} positionFromLeft={1} component="th" scope="row">PAR</FixedOrNormalTableCell>
                {getParTableCells()}
            </TableRow>
        );
    }

    const teeSets = golfCourseExtractor.getTeeSetsFromGolfCourse();
    let teeSetName = "";
    if (player.selectedTeeSetIndex != null && teeSets[player.selectedTeeSetIndex] && teeSets[player.selectedTeeSetIndex].name) {
        teeSetName = teeSets[player.selectedTeeSetIndex].name;
    }
    return (
        <TableRow>
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} cellWidth={7} positionFromLeft={1} component="th" scope="row">
                <div className={classes.nameTableCell}>
                    <span>
                        {isNonFixedTableCellsWidthBreakpoint ?
                            (
                                player.name
                            ) :
                            (
                                <NameInitialsAvatar avatarOverrideStyleClass={classes.activeAvatar} name={player.name} />
                            )}
                    </span>
                    <span className={classes.teeSetNameCell}>{teeSetName}</span>
                </div>
            </FixedOrNormalTableCell>
            {getPlayerScoresTableCells()}
        </TableRow>
    );
};

// redux here
const mapStateToProps = (state) => {
    return {
        scores: state.activeRound.payload.scores,
        golfCourse: state.activeRound.payload.golfCourse
    };
};

export default connect(mapStateToProps)(RoundScorecardTableRow);