import React from 'react';
import { makeStyles, useTheme, TableCell } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    fixedTableCell: props => ({
        // width needs to be fixed to make the sticky columns work
        width: theme.spacing(props.cellWidth),
        minWidth: theme.spacing(props.cellWidth),
        maxWidth: theme.spacing(props.cellWidth),
        position: '-webkit-sticky',
        fallbacks: {
            position: 'sticky',
        },
        zIndex: 2,
        backgroundColor: theme.palette.background.paper
    })
}));

function FixedTableCell({ positionFromLeft, positionFromRight, children, cellWidth, style, ...props }) {
    const classes = useStyles({ cellWidth: cellWidth });
    const theme = useTheme();
    if (positionFromLeft) {
        const left = theme.spacing(cellWidth) * (positionFromLeft - 1);
        return <TableCell
            className={classes.fixedTableCell} {...props}
            style={{ left: left, ...style }}>{children}</TableCell>
    }
    else if (positionFromRight) {
        const right = theme.spacing(cellWidth) * (positionFromRight - 1);

        return <TableCell
            className={classes.fixedTableCell} {...props}
            style={{ right: right, ...style }}>{children}</TableCell>
    }
    return null;
}

export default FixedTableCell;