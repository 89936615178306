import React, { Component, Fragment } from "react";
import { Snackbar, IconButton, withStyles, SnackbarContent, Slide } from "@material-ui/core";
import { green, amber } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import AppNotifier from "../../AppNotifier";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";

const styles = theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    message: {
        display: "flex",
        alignItems: "center"
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    icon: {
        fontSize: 20
    }
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const SlideFromRight = (props) =>  {
    return <Slide {...props} direction="left" />
};

class Notifier extends Component {
    constructor(props) {
        super(props);
        this.queue = [];
        this.state = {
            open: false,
            notification: null
        };
        AppNotifier.addListener(this.notificationListener);
    }

    processQueue = () => {
        if (this.queue.length > 0) {
            this.setState({
                notification: this.queue.shift(),
                open: true
            });
        }
    };

    notificationListener = (message, options) => {
        const messageObj = { ...options };
        messageObj.message = message;
        if (!options || !options.key) {
            messageObj.key = new Date().getTime() + Math.random();
        }
        this.queue.push(messageObj);

        if (this.state.open) {
            // this will trigger the close but the snackbar exited it will trigger handleExited
            this.setState({ open: false });
        } else {
            this.processQueue();
        }
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({
            open: false
        });
    };

    handleExited = () => {
        this.processQueue();
    };

    render() {
        console.log("Notifier component rendered");
        let snackbarClass = null;
        let Icon = null;
        if (
            this.state.notification &&
            this.state.notification.variant &&
            this.props.classes[this.state.notification.variant]
        ) {
            snackbarClass = this.props.classes[this.state.notification.variant];
            Icon = variantIcon[this.state.notification.variant];
        }

        // function TransitionRight(props) {
        //     return <Slide {...props} direction="right" />;
        //   }

        return (
            <>
                {this.state.notification && (
                    <Snackbar
                        key={this.state.notification.key}
                        TransitionComponent={SlideFromRight}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        open={this.state.open}
                        autoHideDuration={3000}
                        onClose={this.handleClose}
                        onExited={this.handleExited}
                    >
                        <SnackbarContent
                            className={snackbarClass}
                            aria-describedby="client-snackbar"
                            message={
                                <span className={this.props.classes.message}>
                                    {Icon && (
                                        <Icon
                                            className={clsx(this.props.classes.icon, this.props.classes.iconVariant)}
                                        />
                                    )}
                                    {this.state.notification.message}
                                </span>
                            }
                            action={[
                                <Fragment key="action">
                                    {this.state.notification.action && this.state.notification.action}
                                </Fragment>,
                                <IconButton key="close" color="inherit" onClick={this.handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />
                    </Snackbar>
                )}
            </>
        );
    }
}

export default withStyles(styles)(Notifier);
