import React from "react";
import { connect } from "react-redux";
import { Table, TableHead, TableBody, TableContainer, withStyles, TableCell, TableRow, useTheme, useMediaQuery } from "@material-ui/core";
import FixedTableCell from 'components/FixedTableCell';
import blueColor from "@material-ui/core/colors/blue";
import RoundTableScorecardPlayerRow from "./RoundScorecardTableRow";

const tableCellStyles = theme => ({
    root: {
        textAlign: 'center',
        verticalAlign: 'text-top',
    },
    sizeSmall: {
        paddingLeft: theme.spacing(0.75),
        paddingRight: theme.spacing(0.75)
    }
});

export const StyledTableCell = withStyles(tableCellStyles)(TableCell);

export const StyledFixedTableCell = withStyles(tableCellStyles)(FixedTableCell);

export const FixedOrNormalTableCell = ({isNonFixedTableCellsWidthBreakpoint, ...props}) => {
    if (isNonFixedTableCellsWidthBreakpoint) {
        return <StyledTableCell {...props} />
    }
    else {
        return <StyledFixedTableCell {...props} />
    }
}

// const HeadingFixedTableCell = props => {
//     const theme = useTheme();
//     const blueThemeColor = theme.palette.augmentColor(blueColor);
//     const backgroundColor = theme.palette.type === "dark" ? blueThemeColor.dark : blueThemeColor.light;
//     return (<StyledFixedTableCell style={{backgroundColor: backgroundColor}} {...props} /> );
// }

function RoundScorecardTable({ classes, players, numberOfHoles }) {
    console.log("RoundScorecardTable is rendering")
    // props

    // state
    const theme = useTheme();
    const blueThemeColor = theme.palette.augmentColor(blueColor);
    const backgroundColor = theme.palette.type === "dark" ? blueThemeColor.dark : blueThemeColor.light;
    const lessSpaceWidthBreakpoint = theme.spacing(88);
    const isNonFixedTableCellsWidthBreakpoint = useMediaQuery(`(min-width: ${lessSpaceWidthBreakpoint}px)`);

    // side-effects

    // functions
    const getHeadingTemplate = () => {
        const headingTemplate = [];
        for (let i = 1; i <= numberOfHoles; i++) {
            headingTemplate.push(<StyledTableCell style={{ backgroundColor: backgroundColor }} key={i}>{i}</StyledTableCell>);
        }
        // after ninth hole add 'OUT' cell, check if there are nine holes
        // if (numberOfHoles >= 9)
        //     headingTemplate.splice(
        //         9,
        //         0,
        //         <StyledTableCell key="OUT">
        //             <b>OUT</b>
        //         </StyledTableCell>
        //     );
        // // after 18th hole add 'IN' cell, check if there are 18 holes
        // if (numberOfHoles == 18)
        //     headingTemplate.splice(
        //         19,
        //         0,
        //         <StyledTableCell key="IN">
        //             <b>IN</b>
        //         </StyledTableCell>
        //     );


        headingTemplate.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} cellWidth={5} positionFromRight={5} key="OUT">
                <b>OUT</b>
            </FixedOrNormalTableCell>
        );

        headingTemplate.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} key="IN" cellWidth={5} positionFromRight={4}>
                <b>IN</b>
            </FixedOrNormalTableCell>
        );

        headingTemplate.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} key="TOT" cellWidth={5} positionFromRight={3}>
                <b>TOT</b>
            </FixedOrNormalTableCell>
        );
        // player handicap
        headingTemplate.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} key="HCP" cellWidth={5} positionFromRight={2}>
                <b>HCP</b>
            </FixedOrNormalTableCell>
        );
        headingTemplate.push(
            <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} key="NET" cellWidth={5} positionFromRight={1}>
                <b>NET</b>
            </FixedOrNormalTableCell>
        );
        return headingTemplate;
    };

    // return template
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <FixedOrNormalTableCell isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} style={{ backgroundColor: backgroundColor }} cellWidth={7} positionFromLeft={1}></FixedOrNormalTableCell>
                        {getHeadingTemplate()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <RoundTableScorecardPlayerRow isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} isParRow />
                    {players.map((player, index) => (
                        <RoundTableScorecardPlayerRow key={index} isNonFixedTableCellsWidthBreakpoint={isNonFixedTableCellsWidthBreakpoint} playerIndex={index} player={player} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// redux here
const mapStateToProps = (state) => {
    return {
        players: state.activeRound.payload.players,
        numberOfHoles: state.activeRound.payload.golfCourse.holes,
    };
};

export default connect(mapStateToProps)(RoundScorecardTable);
