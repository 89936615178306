class AppNotifier {
    constructor() {
        this.listeners = [];
        this.currentNotification = null;
    }

    // message is string messsage and options is object
    notify(message = "", options = {}) {
        for (let listener of this.listeners) {
            listener(message, options);
        }
    }

    addListener(listener) {
        this.listeners.push(listener);
    }

    removeListener(listener) {
        const indexInArray = this.listeners.indexOf(listener);
        if (indexInArray !== -1) {
            this.listeners.splice(indexInArray, 1);
        }
    }
}

export default new AppNotifier();
