import React from "react";
import { cloneDeep } from "lodash";
import { getField } from "./FieldTypes";

const defaults = {
    requiredFields: [],
    scores: {},
    holesSettings: {},
    players: [],
    holePlayerInfoComponent: null,
    infoAttrs: {
        player: null,
        hole: null
    },
    leaderboard: [],
    beforeStartRequiredFields: [],
    gamesSettings: {},
    golfCourse: null
};

class Game {
    constructor(options = {}) {
        if (!options.name || !options.description || !options.resultComponent) {
            throw new Error("Game constructor requires name, description and resultComponent");
        }
        this.name = options.name;
        this.description = options.description;
        // this.requiredFields = options.requiredFields || defaults.requiredFields;
        this.resultComponent = options.resultComponent;
        this.infoAttrs = { ...defaults.infoAttrs, ...options.infoAttrs };
        this.holePlayerInfoComponent = options.holePlayerInfoComponent || defaults.holePlayerInfoComponent;
        this.beforeStartRequiredFields = options.beforeStartRequiredFields || defaults.beforeStartRequiredFields;
        this.golfCourse = options.golfCourse || defaults.golfCourse;

        // scores is same object as in redux state,
        /* scores: {
            1(hole_number): {
                0(player_index in players array): {
                    "STROKES"(input field): 3(value)
                }
            }
        }
        */
        this.scores = options.scores || defaults.scores;
        this.gamesSettings = options.gamesSettings || defaults.gamesSettings;

        //eliminate mutablity, players coming from redux state
        this.players = cloneDeep(options.players) || defaults.players;
        this.leaderboard = defaults.leaderboard;
        this.holesSettings = options.holesSettings || defaults.holesSettings;
    }

    // general entry point to run the game logic and get results
    runGame() {
        console.error("Implementation required in child game class");
    }

    miscFieldsOnHole(holeNumber, holeScores)
    {
        return null;
    }

    setScores(newScores) {
        this.scores = newScores;
    }

    getHoleFromHolesData(holeNumber) {
        let selectedHole = null;
        if (!this.golfCourse.holes_data) {
            throw new Error('Game instance is missing golf course data');
        }
        for (let hole of this.golfCourse.holes_data) {
            if (hole.hole_number == holeNumber) {
                selectedHole = hole;
            }
        }
        return selectedHole;
    };

    getPlayerNetStrokes(holeNumber, playerStrokes, playerCourseHandicap) {
        return playerStrokes - this.getExtraStrokesAvailableOnHole(playerCourseHandicap, holeNumber);
    }
    
    getAdjustedPar(holeNumber, player)
    {
        const hole = this.getHoleFromHolesData(holeNumber);
        if (!hole || !hole.info || !hole.info.par) {
            throw new Error("Hole/Par info missing");
        }

        let adjustedPar = hole.info.par;
        adjustedPar += this.getExtraStrokesAvailableOnHole(player.courseHandicap, holeNumber)
        return adjustedPar;
    };

    getExtraStrokesAvailableOnHole(playerCourseHandicap, holeNumber) {
        const totalHoles = this.golfCourse.holes_data.length;
        const holeHandicap = this.getHoleHandicap(holeNumber);
        let extraStrokesAvailable = Math.floor(playerCourseHandicap / totalHoles);
        if (holeHandicap >= 1 && holeHandicap <= playerCourseHandicap % totalHoles) {
            ++extraStrokesAvailable;
        }
        return extraStrokesAvailable;
    }

    getHoleHandicap(holeNumber) {
        const hole = this.getHoleFromHolesData(holeNumber);
        let holeHandicap = 0;
        if (hole.hole_number) {
            // default value of holehandicap in case hole handicap data is not available
            holeHandicap = hole.hole_number;
        }
        if (hole.info.handicap) {
            holeHandicap = hole.info.handicap;
        }
        return holeHandicap;
    }

    reset() {
        this.scores = defaults.scores;
        this.players = defaults.players;
    }

    getResultRender() {
        return <this.resultComponent gameInstance={this} />;
    }

    renderInfoComponent() {
        if (!this.holePlayerInfoComponent) return null;
        return <this.holePlayerInfoComponent gameInstance={this} />;
    }

    getGameSetting(settingKey) {
        const currentGameId = this.constructor.getId();
        if (this.gamesSettings[currentGameId]) {
            if (this.gamesSettings[currentGameId][settingKey] != null) {
                return this.gamesSettings[currentGameId][settingKey];
            }
        }
        // if the above doesn't work, settings might be saved the old way which is directly inside the gamesSettings object
        if (this.gamesSettings[settingKey]) {
            return this.gamesSettings[settingKey];
        }

        console.log(`no game setting found for Game: ${currentGameId} and setting key: ${settingKey}. Going to use default value`);

        // if not lets try to get the default value
        const field = getField(settingKey);
        if (field && field.defaultValue) {
            return field.defaultValue;
        }

        // give up
        return null;
    }

    // static methods
    static getGameSettingsFields() {
        return [];
    }

    // please overwrite this in child games
    static getId() {
        return "UNKNOWN";
    }

    static info() {
        return [];
    }

    static nonCompatibleGames() {
        return [];
    }

    static isGameValidOnHole(golfCourse, holeNumber) {
        return true;
    }

    static numberOfPlayersRequired() {
        return null;
    }

    static requiredFields() {
        return null;
    }
}

export default Game;
