import { usersCollectionRef } from "../firebase/collectionRefs";
import AppNotifier from "../AppNotifier";
import {
    ADD_NEW_ROUND_GAME_SETTING,
    FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS,
    SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS,
    UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN,
    UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
    SET_NEW_ROUND_SELECTED_PLAYERS,
    NEXT_SETUP_VIEW_NUMBER,
    PREVIOUS_SETUP_VIEW_NUMBER,
    FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN,
    FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS
} from "actions/actionTypes";
import axios from "axios";

export const nextSetupView = () => dispatch => {
    dispatch({
        type: NEXT_SETUP_VIEW_NUMBER
    });
};

export const previousSetupView = () => dispatch => {
    dispatch({
        type: PREVIOUS_SETUP_VIEW_NUMBER
    });
};

export const addNewRoundGameSetting = (gameId, settingName, settingValue) => dispatch => {
    if (settingName != null && settingValue != null) {
        dispatch({
            type: ADD_NEW_ROUND_GAME_SETTING,
            payload: { gameId: gameId, fieldKey: settingName, value: settingValue }
        }); 
    }
};

// export const addNewRoundGameSetting = (settingName, settingValue) => dispatch => {
//     if (settingName != null && settingValue != null) {
//         dispatch({
//             type: ADD_NEW_ROUND_GAME_SETTING,
//             payload: { key: settingName, value: settingValue }
//         });
//     }
// };

export const updateNewRoundSelectedGameIds = (user, ids) => dispatch => {
    if (user) {
        // dispatch that state is loading
        dispatch({
            type: UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN
        });
        if (Array.isArray(ids)) {
            usersCollectionRef
                .doc(user.uid)
                .set({ currentGameIds: ids }, { merge: true })
                .then(() => {
                    // dispatch the new ids and this will also change the loading status
                    dispatch({
                        type: UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS,
                        payload: ids
                    });
                })
                .catch(err => {
                    AppNotifier.notify("Failed to update data", { variant: "error" });
                    console.error("Failed to update the current games for the user. Error: ", err);
                });
        } else {
            console.log("Update for current games requires array of ids");
        }
    }
};

export const setNewRoundSelectedPlayers = players => dispatch => {
    if (Array.isArray(players)) {
        dispatch({
            type: SET_NEW_ROUND_SELECTED_PLAYERS,
            payload: players
        });
    }
};

export const updateCurrentGolfCourse = selectedCourse => dispatch => {
    if (selectedCourse) {
        // usersCollectionRef
        //     .doc(user.uid)
        //     .set({ currentCourse: newCourse }, { merge: true })
        //     .then(() => {
        dispatch({
            type: FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS,
            payload: selectedCourse
        });
        // })
        // .catch(err => {
        //     AppNotifier.notify("Failed to update data", { variant: "error" });
        //     console.error("Failed to update the current golf course. Error: ", err);
        // });
    }
};

export const fetchNewRoundGolfCourseDetails = golfCourseId => dispatch => {
    dispatch({
        type: FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN
    });
    axios
        .get(process.env.REACT_APP_FUNCTIONS_SERVER_URL + "app/getGolfCourseDetails?id=" + golfCourseId, {
            headers: {
                Accept: "application/json"
            }
        })
        .then(response => {
            if (response.data.courseInfo) {
                dispatch({
                    type: FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS,
                    payload: cleanupAndOrganizeCourseDetails(response.data.courseInfo)
                });
            }
        })
        .catch(err => {
            AppNotifier.notify("Unable to get golf course information", { variant: "error" });
            console.log(err);
        });
};

export const setNewRoundSelectedGolfCourseDetails = golfCourseDetails => dispatch => {
    golfCourseDetails = cleanupAndOrganizeCourseDetails(golfCourseDetails);

    dispatch({
        type: SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS,
        payload: golfCourseDetails
    });
};

// lets do some cleanup on the course details we got from the api
const cleanupAndOrganizeCourseDetails = golfCourse => {
    let newGolfCourse = {};

    // check if an object
    if (typeof golfCourse === "object") {
        let newHolesData = [];

        // get the holes data into new array
        if (golfCourse.holes_data) {
            newHolesData = cleanBadHolesData(golfCourse.holes_data);
        }

        let hardestTeeBoxHolesData = [];
        if (golfCourse["tee_sets"] && golfCourse["tee_sets"].length > 0) {
            // below loop will get the tee set with longest yardage
            let longestYardageTeeSet = golfCourse["tee_sets"].shift();
            for (let teeSet of golfCourse["tee_sets"]) {
                if (longestYardageTeeSet.yardage) {
                    if (teeSet.yardage > longestYardageTeeSet.yardage) longestYardageTeeSet = teeSet;
                }
            }

            // get the tee/holes data from the hardest teebox data
            if (longestYardageTeeSet.hole_tees) hardestTeeBoxHolesData = longestYardageTeeSet.hole_tees;
        }

        // loop through our holes array and put the info we got from the hardest tee set
        for (let i = 0; i < newHolesData.length; i++) {
            newHolesData[i].info = {};
            if (hardestTeeBoxHolesData[i]) {
                newHolesData[i].info = hardestTeeBoxHolesData[i];
            }
        }

        golfCourse.holes_data = newHolesData;

        newGolfCourse = golfCourse;
    }
    return newGolfCourse;
};

// there are some bad empty values we received for holes_data array which affects the length of array etc.
// we receive these bad values from the group looper database
// this function will filter those values
function cleanBadHolesData(holesData) {
    return holesData.filter(hole => hole != null);
}
