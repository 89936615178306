//TODO CONSTANTS

// service worker updates etc.
export const SW_UPDATE = 'SW_UPDATE';

// app action types
export const CHANGE_APP_BAR_TEMP_TITLE = "CHANGE_APP_BAR_TEMP_TITLE";
export const REMOVE_APP_BAR_TEMP_TITLE = "REMOVE_APP_BAR_TEMP_TITLE";
export const SET_DARK_MODE = "SET_DARK_MODE";

// auth action types
export const SET_SIGNING_OUT_USER_TRUE = "SET_SIGNING_OUT_USER_TRUE";
export const SET_SIGNING_OUT_USER_FALSE = "SET_SIGNING_OUT_USER_FALSE";
export const FETCH_USER = "FETCH_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";

// favourite players action types
export const FETCH_FAVOURITE_PLAYERS_BEGIN = "FETCH_FAVOURITE_PLAYERS_BEGIN";
export const FETCH_FAVOURITE_PLAYERS_SUCCESS = "FETCH_FAVOURITE_PLAYERS_SUCCESS";
export const FETCH_FAVOURITE_PLAYERS_FAILURE = "FETCH_FAVOURITE_PLAYERS_FAILURE";
export const SET_FAVOURITE_PLAYERS = "SET_FAVOURITE_PLAYERS";

// new round action types
export const FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS = "FETCH_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS";
export const FETCH_NEW_ROUND_SELECTED_GAME_IDS_BEGIN = "FETCH_NEW_ROUND_SELECTED_GAME_IDS_BEGIN";
export const FETCH_NEW_ROUND_SELECTED_GAME_IDS_FAILURE = "FETCH_NEW_ROUND_SELECTED_GAME_IDS_FAILURE";
export const UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN = "UPDATE_NEW_ROUND_SELECTED_GAME_IDS_BEGIN";
export const UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS = "UPDATE_NEW_ROUND_SELECTED_GAME_IDS_SUCCESS";
export const SET_NEW_ROUND_SELECTED_PLAYERS = "SET_NEW_ROUND_SELECTED_PLAYERS";
export const FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS = "FETCH_SELECTED_GOLF_COURSE_INFO_SUCCESS";
export const FETCH_SELECTED_GOLF_COURSE_INFO_BEGIN = "FETCH_SELECTED_GOLF_COURSE_INFO_BEGIN";
export const FETCH_SELECTED_GOLF_COURSE_INFO_FAILURE = "FETCH_SELECTED_GOLF_COURSE_INFO_FAILURE";
export const SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS = "SET_NEW_ROUND_SELECTED_GOLF_COURSE_DETAILS";
export const FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN = "FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_BEGIN";
export const FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS = "FETCH_NEW_ROUND_GOLF_COURSE_DETAILS_SUCCESS";
export const ADD_NEW_ROUND_GAME_SETTING = "ADD_NEW_ROUND_GAME_SETTING";
export const NEXT_SETUP_VIEW_NUMBER = "NEXT_SETUP_VIEW_NUMBER";
export const PREVIOUS_SETUP_VIEW_NUMBER = "PREVIOUS_SETUP_VIEW_NUMBER";
export const RESET_SETUP_VIEW_NUMBER = "RESET_SETUP_VIEW_NUMBER";


// recent golf courses action types
export const FETCH_RECENT_GOLF_COURSES_BEGIN = "FETCH_RECENT_GOLF_COURSES_BEGIN";
export const FETCH_RECENT_GOLF_COURSES_SUCCESS = "FETCH_RECENT_GOLF_COURSES_SUCCESS";
export const ADD_NEW_GOLF_COURSE_TO_RECENT_COURSES = "ADD_NEW_GOLF_COURSE_TO_RECENT_COURSES";
export const UPDATE_RECENT_GOLF_COURSES_BEGIN = "UPDATE_RECENT_GOLF_COURSES_BEGIN";
export const UPDATE_RECENT_GOLF_COURSES_SUCCESS = "UPDATE_RECENT_GOLF_COURSES_SUCCESS";

// rounds action types
export const DELETE_ROUND_SUCCESS = "DELETE_ROUND_SUCCESS";
export const ROUNDS_CHANGE_BEGIN = "ROUNDS_CHANGE_BEGIN";
export const FETCH_ROUNDS_SUCCESS = "FETCH_ROUNDS_SUCCESS";
export const FETCH_ROUNDS_BEGIN = "FETCH_ROUNDS_BEGIN";

// active round action types
export const FETCH_ACTIVE_ROUND_SUCCESS = "FETCH_ACTIVE_ROUND_SUCCESS";
export const FETCH_ACTIVE_ROUND_BEGIN = "FETCH_ACTIVE_ROUND_BEGIN";
export const FETCH_ACTIVE_ROUND_FAILURE = "FETCH_ACTIVE_ROUND_FAILURE";
export const SET_ACTIVE_ROUND_CURRENT_HOLE = "SET_ACTIVE_ROUND_CURRENT_HOLE";
export const UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD = "UPDATE_SCORE_FOR_HOLE_PLAYER_FIELD";
export const SET_ACTIVE_ROUND_GAME_INSTANCES = "SET_ACTIVE_ROUND_GAME_INSTANCES";
export const SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED = "SET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED";
export const RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED = "RESET_ACTIVE_ROUND_PLAYER_INDEX_BEING_EDITED";
export const  UPDATE_ACTIVE_ROUND_PLAYERS = "UPDATE_ACTIVE_ROUND_PLAYERS";
export const UPDATE_SCORES_FOR_HOLE = "UPDATE_SCORES_FOR_HOLE";
export const UPDATE_ACTIVE_ROUND_HOLE_SETTINGS = "UPDATE_ACTIVE_ROUND_HOLE_SETTINGS";