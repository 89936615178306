import React, { PureComponent } from "react";
import { Table, TableHead, TableRow, Paper, TableBody } from "@material-ui/core";
import { ExtraSmallTableCell as TableCell } from 'components/utils/StyledMaterialComponents';

class LeaderboardTable extends PureComponent {

    render() {
        console.log("LeaderboardTable rendering");
        let rowsWithColumns = this.props.rowsWithColumns;
        if (rowsWithColumns.length < 1) return null;
        const firstRow = rowsWithColumns.shift();

        const table = (
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{firstRow.shift()}</TableCell>
                        {firstRow.map((value, key) => {
                            return (
                                <TableCell key={key} align="right">
                                    {value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsWithColumns.map((value, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {value.shift()}
                                </TableCell>
                                {value.map((value, key) => {
                                    return (
                                        <TableCell align="right" key={key}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );

        if (this.props.noPaper) {
            return table;
        }
        return <Paper>{table}</Paper>;
    }
}

export default LeaderboardTable;
