import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorkerRegistration";
import configureStore from "./reduxStore";
import { Provider } from "react-redux";
import AppNotifier from "./AppNotifier";
import networkDetector from "./NetworkDetector";
import axios from "axios";
import { firebaseApp } from "firebase/FirebaseApp";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { SW_UPDATE } from "actions/actionTypes";

// // cache busting
// const urlParams = new URLSearchParams(window.location.search);
// const param = urlParams.get("v");
// if (!param) {
//     window.location.href += "?v=" + new Date().getTime();
// }

// init redux store
export const store = configureStore();

// lets add interceptor to axios to include firebase token on the requests
axios.interceptors.request.use(
    async (config) => {
        config.headers.Authorization = "Bearer " + (await firebaseApp.auth().currentUser.getIdToken());
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ["production"],
    plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// no service worker logic, means no caching and stuff, application is running from the web
serviceWorker.register({
    onUpdate: (registration) => { store.dispatch({ type: SW_UPDATE, payload: registration })}
});

networkDetector.enable();
