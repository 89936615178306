import React from "react";
import { Grid, Switch, Typography } from "@material-ui/core";

function SkinsFormatSwitch({ name, value, onValueChange }) {
    console.log("SkinsFormatSwitch component rendering");

    const handleInputChange = evt => {
        if (evt.target.checked) {
            onValueChange('CARRY_FORWARD');
        }
        else {
            onValueChange('POT');
        }
    };

    return (
        <React.Fragment>
            <Typography>{name}</Typography>
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Pot</Grid>
                <Grid item>
                    <Switch checked={value === 'CARRY_FORWARD'} onChange={handleInputChange} />
                </Grid>
                <Grid item>Carry Forward</Grid>
            </Grid>
        </React.Fragment>
    );
}

export default SkinsFormatSwitch;
