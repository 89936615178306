import React from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Table,
    TableBody,
    TableHead,
    TableCell as MuiTableCell,
    TableRow,
    Paper,
} from "@material-ui/core";
import NameInitialsAvatar from "components/NameInitialsAvatar";
import GLooperObjectsDataExtractor from "GLooperObjectsDataExtractor";
import { getField, PUTTS, STROKES } from "Games/FieldTypes";
import { updatePlayerScore, setActiveRoundPlayerBeingEditedIndex, updateHoleScores } from "actions/activeRoundActions";
import GamesManager, { HI_LO } from "Games/GamesManager";

// reduce the table cell padding to fit 4 players on small screens
const TableCell = withStyles(theme => ({
    sizeSmall: {
        '&:last-child': {
            paddingRight: theme.spacing(1)
        },
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1.5)
    }
}))(MuiTableCell);

const classes = (theme) => ({
    root: {
        overflow: "auto",
    },
});

class HoleInputTable extends React.Component {
    renderTableHeadCellsFromPlayers = () => {
        return this.props.players.map((player, index) => {
            if (player.name) {
                const setOnClick = this.props.setActiveRoundPlayerBeingEditedIndex;
                return (
                    <TableCell key={index}>
                        <NameInitialsAvatar name={player.name} onClick={() => setOnClick(index)} />
                    </TableCell>
                );
            }
            return null;
        });
    };

    renderTeesTableRow = () => {
        const teeSets = new GLooperObjectsDataExtractor({
            golfCourse: this.props.golfCourse,
        }).getTeeSetsFromGolfCourse();
        return this.props.players.map((player, index) => {
            if (player.selectedTeeSetIndex != null && teeSets[player.selectedTeeSetIndex]) {
                return <TableCell style={{ wordBreak: 'break-all' }} key={index}>{teeSets[player.selectedTeeSetIndex].name}</TableCell>;
            }
            return <TableCell key={index}>NA</TableCell>;
        });
    };

    renderYardageTableRow = () => {
        const { players, holeIndex } = this.props;
        const golfCourseDataExtractor = new GLooperObjectsDataExtractor({ golfCourse: this.props.golfCourse });
        return players.map((player, index) => {
            if (player.selectedTeeSetIndex != null && holeIndex != null) {
                const yardage = golfCourseDataExtractor.getYardageFromGolfCourse(player.selectedTeeSetIndex, holeIndex);
                if (yardage) {
                    return <TableCell key={index}>{yardage}</TableCell>;
                }
            }
            return <TableCell key={index}>NA</TableCell>;
        });
    };

    handleInputComponentValueChange = (playerIndex, fieldKey, isTherePutts) => (newValue) => {
        // if strokes changed make sure to clear putts if putts are more than strokes 
        if (fieldKey === STROKES && isTherePutts && this.getInputValueFromScores(playerIndex, PUTTS) >= newValue) {
            this.props.updatePlayerScore(
                this.props.roundId,
                this.props.holeData.hole_number,
                playerIndex,
                PUTTS,
                null
            );
        }

        this.props.updatePlayerScore(
            this.props.roundId,
            this.props.holeData.hole_number,
            playerIndex,
            fieldKey,
            newValue
        );
    };

    getInputValueFromScores = (playerIndex, fieldKey) => {
        const holeScores = this.props.holeScores;
        if (holeScores && holeScores[playerIndex] && holeScores[playerIndex][fieldKey]) {
            return holeScores[playerIndex][fieldKey];
        }
        return null;
    };

    render() {
        console.log("HoleInputTable rendered");

        let { classes, players, gameIds, golfCourse, holeData } = this.props;

        // only valid games for this hole
        gameIds = gameIds.filter(gameId => {
            const gameObj = GamesManager.getGame(gameId);
            if (gameObj) {
                return gameObj.isGameValidOnHole(golfCourse, holeData.hole_number);
            }
            return false;
        })

        const requiredGolfInputFields = GamesManager.getCommonInputFieldsFromGameIds(gameIds);

        const isThereStrokes = requiredGolfInputFields.includes(STROKES);

        const isTherePutts = requiredGolfInputFields.includes(PUTTS);

        return (
            <>
                <Paper className={classes.root} square> 
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {this.renderTableHeadCellsFromPlayers()}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.gameIds.indexOf(HI_LO) !== -1 &&
                                <TableRow>
                                    <TableCell>Team</TableCell>
                                    {this.props.players.map((player, index) => (<TableCell key={index}>Team {player.hiLoTeam}</TableCell>))}
                                </TableRow>
                            }
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Tees
                            </TableCell>
                                {this.renderTeesTableRow()}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    YDG
                            </TableCell>
                                {this.renderYardageTableRow()}
                            </TableRow>
                            {requiredGolfInputFields.map((fieldKey) => {
                                const field = getField(fieldKey);
                                const InputComponent = field.componentRender;
                                // lets check if the key is putts, then we will send strokes too if available
                                if (fieldKey === PUTTS) {
                                    return (
                                        <TableRow key={fieldKey}>
                                            <TableCell component="th" scope="row">
                                                {field.name}
                                            </TableCell>
                                            {players.map((player, index) => {
                                                return <TableCell key={index}>
                                                    <InputComponent
                                                        isThereStrokes={isThereStrokes}
                                                        strokesValue={this.getInputValueFromScores(index, STROKES)}
                                                        initialValue={this.getInputValueFromScores(index, fieldKey)}
                                                        onValueChange={this.handleInputComponentValueChange(
                                                            index,
                                                            fieldKey,
                                                            isTherePutts
                                                        )}
                                                    />
                                                </TableCell>
                                            })}
                                        </TableRow>
                                    );
                                }
                                return (
                                    <TableRow key={fieldKey}>
                                        <TableCell component="th" scope="row">
                                            {field.name}
                                        </TableCell>
                                        {players.map((player, index) => (
                                            <TableCell key={index}>
                                                <InputComponent
                                                    initialValue={this.getInputValueFromScores(index, fieldKey)}
                                                    onValueChange={this.handleInputComponentValueChange(
                                                        index,
                                                        fieldKey,
                                                        isTherePutts
                                                    )}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </>
        );
    }
}

// redux here
const mapStateToProps = (state, ownProps) => {
    let holeScores = null;
    if (ownProps.holeData.hole_number && state.activeRound.payload.scores[ownProps.holeData.hole_number]) {
        holeScores = state.activeRound.payload.scores[ownProps.holeData.hole_number];
    }
    return {
        //holes_data: Array(18)
        // 0: {hole_name: "", hole_number: 1, pro_tip: ""}
        // 1: {hole_name: "", hole_number: 2, pro_tip: ""}
        players: state.activeRound.payload.players,
        gameIds: state.activeRound.payload.gameIds,
        holeScores: holeScores,
        golfCourse: state.activeRound.payload.golfCourse,
        roundId: state.activeRound.payload.id,
    };
};

const mapDispatchToProps = {
    updatePlayerScore,
    setActiveRoundPlayerBeingEditedIndex,
    updateHoleScores
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(HoleInputTable));
