import React from "react";
import { TextField, Button, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formGrid: {
        padding: theme.spacing(2)
    }
}));

const AuthProviderForm = props => {
    const classes = useStyles();
    const { values, touched, errors, dirty, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset } = props;
    return (
        <form className={classes.formGrid} autoComplete="off" onSubmit={handleSubmit} onReset={handleReset}>
            <TextField
                fullWidth
                error={touched.name && Boolean(errors.name)}
                id="name"
                name="name"
                label="Name"
                margin="normal"
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.name && touched.name && errors.name}
            />
            <TextField
                fullWidth
                disabled
                id="email"
                name="email"
                label="Email Address"
                margin="normal"
                variant="outlined"
                value={values.email}
            />
            <TextField
                fullWidth
                id="authProvider"
                name="authProvider"
                disabled
                label="Auth Provider"
                margin="normal"
                variant="outlined"
                value={values.authProvider}
            />
            <Box display="flex" justifyContent="space-between">
                <Button type="reset" variant="contained" color="secondary" disabled={!dirty || isSubmitting}>
                    Reset
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Update
                </Button>
            </Box>
        </form>
    );
};

export default AuthProviderForm;
