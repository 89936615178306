import {Switch,Route} from 'react-router-dom';
import React from 'react';
import Auth from './middlewares/Auth';

// pages import
import Login from './Login';
import DashboardPage from 'containers/DashboardPage';
import Profile from './Profile';
import CourseSearch from './CourseSearch';
import FavouritePlayers from './FavouritePlayers';
import SetupGame from '../containers/SetupRound/SetupRound';
import SignOut from './Auth/SignOut';
// import Test from "./Test";
import NotFound from './Errors/NotFound';
import GameModes from './GameModes';
import PreviousRoundsPage from 'containers/rounds/PreviousRoundsPage';
import RoundPage from 'containers/rounds/RoundPage';

export default function MainRouter() {
    return (
        
            <Switch>
                   {/* Routing according to the path entered */}
                   <Route exact path='/' component={Login} />
                   {/* <Route exact path='/register' component={Register} />
                   <Route exact path='/login' component={Login} />*/}
                   <Route exact path='/dashboard' component={Auth(DashboardPage)} /> 
                   <Route exact path="/profile" component={Auth(Profile)} />
                   <Route exact path="/search-courses" component={Auth(CourseSearch)} />
                   <Route exact path="/favourite-players" component={Auth(FavouritePlayers)} />
                   <Route exact path="/setup-game" component={Auth(SetupGame)} />
                   <Route exact path="/sign-out" component={SignOut} />
                   <Route path="/play-round/:id" component={Auth(RoundPage)} />
                   <Route exact path="/game-modes" component={Auth(GameModes)} />
                   <Route exact path="/previous-rounds" component={Auth(PreviousRoundsPage)} />
                   {/* <Route exact path="/test" component={Test} /> */}

                   {/* Not found */}
                   <Route component={NotFound} />
               </Switch>
    );
}