import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { signOutUser } from "../../actions/authActions";
import { ListItemIcon } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import RefreshIcon from "@material-ui/icons/Refresh";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: "100%"
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: "0 8px",
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    }
}));

function NavBar(props) {
    console.log("NavBar component rendered");

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <AppBar
                position="static"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
                color="default"
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
                        {props.titleObj.temp ? props.titleObj.temp : props.titleObj.default}
                    </Typography>
                    <IconButton
                        edge="end"
                        onClick={() => {
                            window.location.reload();
                        }}
                        className={clsx(open && classes.hide)}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="temporary"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{ onBackdropClick: handleDrawerClose }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List component="nav">
                    <ListItem onClick={handleDrawerClose} button to="/dashboard" component={Link}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem onClick={handleDrawerClose} button to="/previous-rounds" component={Link}>
                        <ListItemIcon>
                            <TrackChangesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Rounds" />
                    </ListItem>
                    <ListItem onClick={handleDrawerClose} button to="/favourite-players" component={Link}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Favourite Players" />
                    </ListItem>
                    <ListItem onClick={handleDrawerClose} button to="/profile" component={Link}>
                        <ListItemIcon>
                            <AccountBoxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItem>
                    <ListItem
                        button
                        to="/sign-out"
                        component={Link}
                        onClick={() => props.signOutLinkClicked(props.history)}
                    >
                        <ListItemText primary="Sign out" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.user,
        titleObj: state.app.appBar.title
    };
};

const mapDispatchToProps = dispatch => {
    return {
        signOutLinkClicked: browserhistory => dispatch(signOutUser(browserhistory))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NavBar)
);
