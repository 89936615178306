import { MenuItem, Select } from '@material-ui/core';
import { updateHoleSettings } from 'actions/activeRoundActions';
import { OOSLER_STEAL_PLAYER_INDEX } from 'Games/FieldTypes';
import GamesManager, { OOSLER_WOOSLER } from 'Games/GamesManager';
import React from 'react';
import { connect } from "react-redux";

const OoslerStealPlayerSelect = ({ players, roundId, holeNumber, holeScores, golfCourse, holesSettings, updateHoleSettings }) => {
    const possibleStealingPlayersIndexes = GamesManager.initGame(OOSLER_WOOSLER, { players: players, golfCourse: golfCourse, holesSettings: holesSettings }).getPossibleStealPlayersForHole(holeNumber, holeScores);
    let value = "";
    if (holesSettings[holeNumber] && holesSettings[holeNumber][OOSLER_STEAL_PLAYER_INDEX] != null) {
        value = holesSettings[holeNumber][OOSLER_STEAL_PLAYER_INDEX];
    }

    const handleChange = event => {
        let value = event.target.value;
        if (value === "") {
            value = null;
        }
        updateHoleSettings(roundId, holeNumber, holesSettings, OOSLER_STEAL_PLAYER_INDEX, value);
    }

    return <Select value={value} onChange={handleChange} displayEmpty>
        <MenuItem value="">Select Player</MenuItem>
        {possibleStealingPlayersIndexes.map(playerIndex => <MenuItem key={playerIndex} value={playerIndex}>{players[playerIndex].name}</MenuItem>)}
    </Select>
}

// redux here
const mapStateToProps = state => {
    return {
        players: state.activeRound.payload.players,
        golfCourse: state.activeRound.payload.golfCourse,
        roundId: state.activeRound.payload.id,
        holesSettings: state.activeRound.payload.holesSettings
    };
};

const mapDispatchToProps = {
    updateHoleSettings: (roundId, holeNumber, currentHoleSettings, fieldKey, value) => updateHoleSettings(roundId, holeNumber, currentHoleSettings, fieldKey, value)
};

export default connect(mapStateToProps, mapDispatchToProps)(OoslerStealPlayerSelect);